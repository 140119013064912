import { ICurrentCalendarParams } from '../timesheets/timesheetsTypes';

export interface TimeEntriesSliceInitialState {
  events: IEventData[];
  totalTimeLogged: string;
  selectedEventId: string;
  initialCalendarView: keyof typeof CalendarViews;
  isLoading: boolean;
  errorMessage: string | unknown;
}

export enum CalendarViews {
  timeGridDay = 'timeGridDay',
  timeGridWeek = 'timeGridWeek',
  dayGridMonth = 'dayGridMonth',
}

export enum EventTypes {
  Policy = 'Policy',
  Holiday = 'Holiday',
  Project = 'Project',
}

export interface IEventShortInfo {
  id: number;
  name: string;
}

export interface IEventData {
  id: number;
  type: keyof typeof EventTypes;
  start: Date;
  end: Date;
  isBillable: boolean;
  event: IEventShortInfo;
  note: string;
  allDay: boolean;
  timeRequested?: number;
}

export type IGetMemberEventsData = Pick<
  ICurrentCalendarParams,
  'id' | 'start' | 'end' | 'timeZoneName'
>;

export interface IAddTimeEntryData {
  memberId: number;
  projectId: number;
  start: string;
  end: string;
  isBillable: boolean;
  note: string;
}

export type IEditTimeEntryData = Omit<IAddTimeEntryData, 'memberId'> & {
  timeEntryId: number;
  memberId: number;
  timeZone: string;
};
