import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import EditProjectGeneralForm from 'components/projects/EditProjectGeneralForm';
import Preloader from 'components/shared/Preloader';
import { clientsFilterListSelector } from 'features/clientsFilterList/clientsFilterListSlice';
import { IProjectInfoUpdatingData } from 'features/projectInfo/projectInfoTypes';
import { changeProjectInfo } from 'features/projectInfo/projectInfoActions';
import {
  isLoadingProjectInfoSelector,
  projectInfoSelector,
} from 'features/projectInfo/projectInfoSlice';

const EditProjectGeneral = () => {
  const isLoading = useSelector(isLoadingProjectInfoSelector);
  const currentProject = useSelector(projectInfoSelector);
  const clients = useSelector(clientsFilterListSelector);
  const dispatch = useAppDispatch();

  const handleSubmit = (data: IProjectInfoUpdatingData) => {
    dispatch(changeProjectInfo(data));
  };

  return isLoading ? (
    <Preloader />
  ) : (
    <EditProjectGeneralForm
      projectId={currentProject.id}
      projectName={currentProject.name}
      projectClient={currentProject.client}
      organizationClients={clients}
      onSubmit={handleSubmit}
    />
  );
};

export default EditProjectGeneral;
