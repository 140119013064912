import React, { FC } from 'react';
import * as ReactDOM from 'react-dom';
import { CloseIcon } from 'assets/icons/CloseIcon';
import Button from 'components/shared/Button';
import styles from './ConfirmDialog.module.scss';

interface ConfirmDialogProps {
  open: boolean;
  title: string;
  description?: string;
  acceptBtnText: string;
  declineBtnText: string;
  onClose: () => void;
  onAccept: () => void;
  transparentOverlay?: boolean;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open,
  title,
  description,
  acceptBtnText,
  declineBtnText,
  onClose,
  onAccept,
  transparentOverlay,
}) => {
  const handleAccept = () => {
    onAccept();
    onClose();
  };

  if (!open) return null;

  return ReactDOM.createPortal(
    <>
      <div
        className={`${styles.overlay} ${
          transparentOverlay ? styles.transparentOverlay : styles.shadowOverlay
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
      <div className={styles.wrapper}>
        <div className={styles.controls}>
          {title && <h2 className={styles.title}>{title}</h2>}
          <button
            className={styles.closeBtn}
            onClick={onClose}
            aria-label="Close dialog."
          >
            <CloseIcon />
          </button>
        </div>
        <div className={styles.dialogContainer}>
          <div className={styles.description}>{description}</div>
          <div className={styles.controller}>
            <Button onClick={onClose}>{declineBtnText}</Button>
            <Button type="button" variant="primary" onClick={handleAccept}>
              {acceptBtnText}
            </Button>
          </div>
        </div>
      </div>
    </>,
    document.getElementById('confirmation') as HTMLElement
  );
};

export default ConfirmDialog;
