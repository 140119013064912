import React, { FC, MouseEvent, useRef } from 'react';
import { MagnifyIcon } from 'assets/icons/MagnifyIcon';
import { CloseIcon } from 'assets/icons/CloseIcon';
import styles from './SearchInput.module.scss';

interface SearchInputProps
  extends React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    React.AriaAttributes {
  value: string;
  clearInput: () => void;
  outlined?: boolean;
}

const SearchInput: FC<SearchInputProps> = ({
  value,
  clearInput,
  outlined,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleClear = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    clearInput();
    inputRef.current?.focus();
  };

  return (
    <div className={`${styles.root} ${outlined ? styles.outlinedInput : ''}`}>
      <div className={styles.searchIconContainer}>
        <MagnifyIcon />
      </div>
      <input ref={inputRef} value={value} {...props} />
      {!!value.length && (
        <div className={styles.closeIconContainer} onClick={handleClear}>
          <CloseIcon />
        </div>
      )}
    </div>
  );
};

export default SearchInput;
