import React, { useEffect, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MultiValue } from 'react-select';
import { useAppDispatch } from 'app/store';
import RequestTimeOffForm from 'components/timeOff/RequestTimeOffForm';
import { ISelectOption } from 'components/shared/forms/DropdownSelect/DropdownSelect';
import DropdownSelect from 'components/shared/forms/DropdownSelect';
import PageTabs from 'components/shared/PageTabs';
import Button from 'components/shared/Button';
import Modal from 'components/shared/Modal';
import { membersFilterListSelector } from 'features/membersFilterList/membersFilterListSlice';
import { selectInfoUserSelector } from 'features/user/userSlice';
import { IRequestingTimeOffData } from 'features/timeOffRequestsList/timeOffRequestsListTypes';
import { getMembersFilterList } from 'features/membersFilterList/membersFilterListActions';
import { USER_ROLE } from 'features/user/userTypes';
import {
  getTimeOffRequestsList,
  requestTimeOff,
} from 'features/timeOffRequestsList/timeOffRequestsListActions';
import {
  filterTimeOffRequestsSelector,
  setSelectedMembersIds,
  timeOffRequestsCountSelector,
} from 'features/timeOffRequestsList/timeOffRequestsListSlice';
import styles from './TimeOffRequests.module.scss';

const TimeOffRequests = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const members = useSelector(membersFilterListSelector);
  const userInfo = useSelector(selectInfoUserSelector);
  const timeOffRequestsPageFilter = useSelector(filterTimeOffRequestsSelector);
  const tabsPathFilter = pathname.replace('/time-off/requests', '');
  const { submitted, approved, denied, all } = useSelector(
    timeOffRequestsCountSelector
  );

  const [requestFormOpen, setRequestFormOpen] = useState(false);
  const memberFilterOptions = members.map((member) => ({
    label: member.name,
    value: String(member.id),
  }));
  const onMemberFilterValueChange = (options: MultiValue<ISelectOption>) => {
    dispatch(setSelectedMembersIds(options));
  };
  const handleRequestTimeOff = (data: IRequestingTimeOffData) => {
    dispatch(
      requestTimeOff({
        data,
        filter: {
          ...timeOffRequestsPageFilter,
          path: tabsPathFilter,
          members: timeOffRequestsPageFilter.members,
          skip: 0,
          take: 20,
        },
      })
    );
  };
  const tabsData = [
    {
      path: `/time-off/requests`,
      name: `Submitted (${submitted})`,
    },
    {
      path: `/time-off/requests/approved`,
      name: `Approved (${approved})`,
    },
    {
      path: `/time-off/requests/denied`,
      name: `Denied (${denied})`,
    },
    {
      path: `/time-off/requests/all`,
      name: `All(${all})`,
    },
  ];

  const isMembersFilterAvailable =
    userInfo &&
    (userInfo.role === USER_ROLE.Admin ||
      userInfo.role === USER_ROLE.Manager ||
      userInfo.role === USER_ROLE.Viewer);
  const isSubmitRequestAvailable =
    userInfo &&
    (userInfo.role === USER_ROLE.Admin ||
      userInfo.role === USER_ROLE.Manager ||
      userInfo.role === USER_ROLE.User);

  useEffect(() => {
    dispatch(getMembersFilterList());
    dispatch(
      getTimeOffRequestsList({
        ...timeOffRequestsPageFilter,
        path: tabsPathFilter,
        members: timeOffRequestsPageFilter.members,
        skip: 0,
        take: 20,
      })
    );
  }, [timeOffRequestsPageFilter.members, pathname]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.controls}>
        {isMembersFilterAvailable && (
          <div className={styles.membersFilterWrapper}>
            <DropdownSelect
              label="Members"
              value={timeOffRequestsPageFilter.members}
              options={memberFilterOptions}
              onChange={onMemberFilterValueChange}
              isSearchable={true}
              blurInputOnSelect
              placeholder={'Select members...'}
              isMulti
            />
          </div>
        )}
        {isSubmitRequestAvailable && (
          <div
            className={styles.requestBtnWrapper}
            style={
              isMembersFilterAvailable
                ? {}
                : { marginTop: '20px', marginLeft: 'auto' }
            }
          >
            <Button
              variant="primary"
              onClick={() => setRequestFormOpen(true)}
              aria-label="Open request time off form."
            >
              Request time off
            </Button>
          </div>
        )}
      </div>
      <div className={styles.tabsWrapper}>
        <PageTabs tabsData={tabsData} />
      </div>
      <Modal
        open={requestFormOpen}
        onClose={() => setRequestFormOpen(false)}
        title="Time off request"
      >
        {userInfo && (
          <RequestTimeOffForm
            handleCLose={() => setRequestFormOpen(false)}
            handleSubmit={handleRequestTimeOff}
            userInfo={userInfo}
          />
        )}
      </Modal>
      <Outlet />
    </div>
  );
};

export default TimeOffRequests;
