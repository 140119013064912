import React, { FC } from 'react';
import styles from './ToggleButton.module.scss';

interface ToggleButtonProps {
  label: string;
  selectedValue: string;
  value1: string;
  value2: string;
  onValue1Click: () => void;
  onValue2Click: () => void;
}

const ToggleButton: FC<ToggleButtonProps> = ({
  label,
  selectedValue,
  value1,
  value2,
  onValue1Click,
  onValue2Click,
}) => {
  return (
    <div className={styles.root}>
      <label className="input-label">{label}</label>
      <div className={styles.toggleWrapper}>
        <button
          className={value1 === selectedValue ? styles.activeBtn : ''}
          type="button"
          value={value1}
          onClick={onValue1Click}
        >
          {value1}
        </button>
        <button
          className={value2 === selectedValue ? styles.activeBtn : ''}
          type="button"
          value={value2}
          onClick={onValue2Click}
        >
          {value2}
        </button>
      </div>
    </div>
  );
};

export default ToggleButton;
