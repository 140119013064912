import React, { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useAppDispatch } from 'app/store';
import Button from 'components/shared/Button';
import { isLoadingTimeOffRequestInfoSelector } from 'features/timeOffRequestInfo/timeOffRequeststInfoSlice';
import { filterTimeOffRequestsSelector } from 'features/timeOffRequestsList/timeOffRequestsListSlice';
import { permissionsUserSelector } from 'features/user/userSlice';
import { ITimeOffRequestInfo } from 'features/timeOffRequestInfo/timeOffRequestInfoTypes';
import { getMemberInfo } from 'features/memberInfo/memberInfoActions';
import {
  approveTimeOffRequest,
  denyTimeOffRequest,
} from 'features/timeOffRequestsList/timeOffRequestsListActions';
import {
  ITimeOffRequestsActionsParams,
  TimeOffRequestStatuses,
} from 'features/timeOffRequestsList/timeOffRequestsListTypes';
import { dateToTimeZone } from 'helpers/dateToTimeZone';
import styles from './ViewTimeOffRequest.module.scss';

interface ViewTimeOffRequestProps {
  closeModal: () => void;
  request: ITimeOffRequestInfo;
  requestId: number;
}

const ViewTimeOffRequest: FC<ViewTimeOffRequestProps> = ({
  closeModal,
  request,
  requestId,
}) => {
  const timeOffRequestsPageFilter = useSelector(filterTimeOffRequestsSelector);
  const isLoading = useSelector(isLoadingTimeOffRequestInfoSelector);
  const permissions = useSelector(permissionsUserSelector);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const tabsPathFilter = pathname.replace('/time-off/requests', '');
  const actionsParam: ITimeOffRequestsActionsParams = {
    requestId,
    filter: {
      ...timeOffRequestsPageFilter,
      path: tabsPathFilter,
      skip: 0,
      take: 20,
    },
  };

  const isEditActionAvailable = !!permissions?.some(
    (el) => el.name === 'Update TimeOffRequest'
  );
  const isApproveActionAvailable = !!permissions?.some(
    (el) => el.name === 'Approve TimeOffRequest'
  );
  const isDenyActionAvailable = !!permissions?.some(
    (el) => el.name === 'Deny TimeOffRequest'
  );

  const handleApproveRequest = () => {
    dispatch(approveTimeOffRequest(actionsParam));
    closeModal();
  };
  const handleDenyRequest = () => {
    dispatch(denyTimeOffRequest(actionsParam));
    closeModal();
  };

  const formattedToMemberTimeZoneTime = (time: string) => {
    return format(
      dateToTimeZone(time, request.timeZone.name),
      request.allDay ? 'EEE, MMM dd yyyy' : 'EEE, MMM dd yyyy, hh:mm aaa'
    );
  };

  useEffect(() => {
    dispatch(getMemberInfo(request.member.id));
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <span className="input-label">Member</span>
        <div>{request.member ? request.member.name : ''}</div>
        <span className="input-label">Policy</span>
        <div>{request.policy ? request.policy.name : ''}</div>
        <span className="input-label">Total time span</span>
        <div>
          {formattedToMemberTimeZoneTime(request.startDate)} -{' '}
          {formattedToMemberTimeZoneTime(request.endDate)}
          <br />
          <span className="input-label">{request.timeZone.name}</span>
        </div>
        <span className="input-label">Time requested</span>
        <div>{request.timeRequested}</div>
        {request.reason && (
          <>
            <span className="input-label">Comments</span>
            <div className={styles.commentsWrapper}>{request.reason}</div>
          </>
        )}
        <span className="input-label">Status</span>
        <div>{request.status}</div>
      </div>
      <div className={styles.controls}>
        {(request.status === TimeOffRequestStatuses.Submitted ||
          request.status === TimeOffRequestStatuses.Denied) &&
          isEditActionAvailable && (
            <div>
              <Button
                onClick={() => {
                  navigate(`/time-off/requests/${request.id}/edit`);
                }}
              >
                Edit
              </Button>
            </div>
          )}

        {(request.status === TimeOffRequestStatuses.Approved ||
          request.status === TimeOffRequestStatuses.Submitted) &&
          isDenyActionAvailable && (
            <div>
              <Button
                variant="delete"
                onClick={handleDenyRequest}
                disabled={isLoading}
              >
                Deny
              </Button>
            </div>
          )}

        {(request.status === TimeOffRequestStatuses.Submitted ||
          request.status === TimeOffRequestStatuses.Denied) &&
          isApproveActionAvailable && (
            <div>
              <Button
                variant="primary"
                onClick={handleApproveRequest}
                disabled={isLoading}
              >
                Approve
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};

export default ViewTimeOffRequest;
