import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import ChapterNavigationCard from 'components/reports/ChapterNavigationCard';
import { permissionsUserSelector } from 'features/user/userSlice';
import styles from './AllReports.module.scss';

const AllReports: FC = () => {
  const permissions = useSelector(permissionsUserSelector);

  const isMyAmountsOwedReportAvailable = permissions?.some(
    (el) => el.name === 'View My Amounts owed'
  );
  const isMyTimeAndActivityReportAvailable = permissions?.some(
    (el) => el.name === 'View My Time and activity'
  );

  const cardsData = [
    {
      title: 'Time & activity',
      description:
        "See team members' time worked and amounts earned per project",
      link: isMyTimeAndActivityReportAvailable
        ? '/reports/time-and-activity/my'
        : '/reports/time-and-activity',
    },
    {
      title: 'Amounts Owed',
      description: 'See how team members are currently owed',
      link: isMyAmountsOwedReportAvailable
        ? '/reports/amounts-owed/my'
        : '/reports/amounts-owed',
    },
  ];

  return (
    <div className={styles.container}>
      {cardsData.map((card, idx) => (
        <ChapterNavigationCard
          key={idx}
          title={card.title}
          description={card.description}
          link={card.link}
        />
      ))}
    </div>
  );
};

export default AllReports;
