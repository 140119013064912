import React, { FC } from 'react';
import { format } from 'date-fns';
import InfoBanner from 'components/shared/InfoBanner';
import { ITimeZone } from 'features/user/userTypes';
import { dateToTimeZone } from 'helpers/dateToTimeZone';

interface TimeZoneDifferenceBannerProps {
  startTime: Date;
  endTime: Date;
  memberTimeZone: ITimeZone;
  selectedInTimeZoneFilterTimeZone: string;
}

const TimeZoneDifferenceBanner: FC<TimeZoneDifferenceBannerProps> = ({
  startTime,
  endTime,
  memberTimeZone,
  selectedInTimeZoneFilterTimeZone,
}) => {
  if (selectedInTimeZoneFilterTimeZone === memberTimeZone.name) {
    return null;
  }
  const convertedMemberStart = dateToTimeZone(startTime, memberTimeZone.name);
  const convertedMemberEnd = dateToTimeZone(endTime, memberTimeZone.name);
  const formattedMemberStart = format(
    convertedMemberStart,
    'hh:mm aaa MMM, dd'
  );
  const formattedMemberEnd = format(convertedMemberEnd, 'hh:mm aaa MMM, dd');

  return (
    <InfoBanner
      variant="info"
      message={`It will be ${formattedMemberStart} - ${formattedMemberEnd} in ${memberTimeZone.name} time zone.`}
    />
  );
};

export default TimeZoneDifferenceBanner;
