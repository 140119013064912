import React, { FC } from 'react';
import { FieldHookConfig, useField } from 'formik';
import DatePicker from 'react-datepicker';
import CustomDatePickerInput from '../CustomDatePickerInput';
import styles from '../CustomDatePicker.module.scss';
import { useSelector } from 'react-redux';
import { selectInfoUserSelector } from '../../../../../features/user/userSlice';

interface CustomDatePickerProps {
  label?: string;
  minDate?: Date;
  onCalendarClose?: () => void;
}

const CustomFormikDatePicker: FC<
  CustomDatePickerProps & FieldHookConfig<string>
> = ({ label, minDate, onCalendarClose, ...props }) => {
  const userInfo = useSelector(selectInfoUserSelector);
  const [field, meta, helpers] = useField(props);
  const renderDayContents = (day: number, date: Date) => {
    const dayText = `${date}`;
    return (
      <span className={styles.customDay} title={dayText}>
        {day}
      </span>
    );
  };

  return (
    <>
      <div className={styles.wrapper} style={{ width: '200px' }}>
        {label && (
          <label className="input-label" htmlFor={field.name}>
            {label}
          </label>
        )}
        <DatePicker
          selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            helpers.setValue(String(val));
          }}
          customInput={
            <CustomDatePickerInput error={!!meta.error && meta.touched} />
          }
          renderDayContents={renderDayContents}
          showPopperArrow={false}
          dateFormat="EEE, MMM dd, yyyy"
          calendarStartDay={userInfo?.firstDayOfWeek ?? 1}
          id={field.name}
          minDate={minDate}
          onCalendarClose={onCalendarClose}
        />
        {meta.touched && !!meta.error ? (
          <div className={styles.errorMessage}>*{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};

export default CustomFormikDatePicker;
