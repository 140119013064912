import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { timeAndActivityReportsSlice } from 'features/timeAndActivityReports/timeAndActivityReportsSlice';
import { timeOffRequestsListSlice } from 'features/timeOffRequestsList/timeOffRequestsListSlice';
import { timeOffRequestInfoSlice } from 'features/timeOffRequestInfo/timeOffRequeststInfoSlice';
import { policiesFilterListSlice } from 'features/policiesFilterList/policiesFilterListSlice';
import { holidaysFilterListSlice } from 'features/holidaysFilterList/holidaysFilterListSlice';
import { amountsOwedReportsSlice } from 'features/amountsOwedReports/amountsOwedReportsSlice';
import { projectsFilterListSlice } from 'features/projectsFilterList/projectsFilterListSlice';
import { clientsFilterListSlice } from 'features/clientsFilterList/clientsFilterListSlice';
import { membersFilterListSlice } from 'features/membersFilterList/membersFilterListSlice';
import { organizationSlice } from 'features/organization/organizationSlice';
import { projectsListSlice } from 'features/projectsList/projectsListSlice';
import { holidaysListSlice } from 'features/holidaysList/holidaysListSlice';
import { policiesListSlice } from 'features/policiesList/policiesListSlice';
import { holidayInfoSlice } from 'features/holidayInfo/holidayInfoSlice';
import { projectInfoSlice } from 'features/projectInfo/projectInfoSlice';
import { clientsListSlice } from 'features/clientsList/clientsListSlice';
import { invitesListSlice } from 'features/invitesList/invitesListSlice';
import { timeEntriesSlice } from 'features/timeEntries/timeEntriesSlice';
import { clientInfoSlice } from 'features/clientInfo/clientInfoSlice';
import { memberInfoSlice } from 'features/memberInfo/memberInfoSlice';
import { timesheetsSlice } from 'features/timesheets/timesheetsSlice';
import { policyInfoSlice } from 'features/policyInfo/policyInfoSlice';
import { membersSlice } from 'features/members/membersSlice';
import { userSlice } from 'features/user/userSlice';
import sentryMiddleware from './sentryMiddleware';

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    members: membersSlice.reducer,
    member: memberInfoSlice.reducer,
    clientInfo: clientInfoSlice.reducer,
    timesheets: timesheetsSlice.reducer,
    policyInfo: policyInfoSlice.reducer,
    invitesList: invitesListSlice.reducer,
    projectInfo: projectInfoSlice.reducer,
    holidayInfo: holidayInfoSlice.reducer,
    timeEntries: timeEntriesSlice.reducer,
    clientsList: clientsListSlice.reducer,
    holidaysList: holidaysListSlice.reducer,
    policiesList: policiesListSlice.reducer,
    projectsList: projectsListSlice.reducer,
    organization: organizationSlice.reducer,
    membersFilterList: membersFilterListSlice.reducer,
    clientsFilterList: clientsFilterListSlice.reducer,
    policiesFilterList: policiesFilterListSlice.reducer,
    holidaysFilterList: holidaysFilterListSlice.reducer,
    projectsFilterList: projectsFilterListSlice.reducer,
    amountsOwedReports: amountsOwedReportsSlice.reducer,
    timeOffRequestInfo: timeOffRequestInfoSlice.reducer,
    timeOffRequestsList: timeOffRequestsListSlice.reducer,
    timeAndActivityReports: timeAndActivityReportsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([sentryMiddleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export default store;
