import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { changeProjectInfo, getProjectInfo } from './projectInfoActions';
import {
  IProjectInfo,
  IProjectInfoSliceInitialState,
} from './projectInfoTypes';
import { isRejectedAction } from '../sliceHelpers';

const initialState: IProjectInfoSliceInitialState = {
  info: {
    members: [],
    client: null,
  } as unknown as IProjectInfo,
  isLoading: false,
  errorMessage: '',
};

export const projectInfoSlice = createSlice({
  name: '@@projectInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjectInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProjectInfo.fulfilled, (state, action) => {
      state.info = action.payload;
      state.isLoading = false;
    });
    builder.addCase(changeProjectInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeProjectInfo.fulfilled, (state, action) => {
      state.info = action.payload;
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const projectInfoSelector = createSelector(
  [(state: RootState) => state.projectInfo.info],
  (projectInfo) => projectInfo
);

export const isLoadingProjectInfoSelector = createSelector(
  [(state: RootState) => state.projectInfo.isLoading],
  (isLoading) => isLoading
);
