import { useEffect, RefObject } from 'react';

type Event = MouseEvent | TouchEvent;

export const useClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  callBack: (event: Event) => void
) => {
  useEffect(() => {
    const handleClick = (event: Event) => {
      const el = ref?.current;
      if (el && !el.contains(event.target as Node)) {
        callBack(event);
      }
    };

    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [ref, callBack]);
};
