import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/store';
import {
  downloadAmountsOwedReports,
  fetchAmountsOwedReports,
} from 'api/amountsOwedReports';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import {
  AmountsOwedReportsParamsForDownloading,
  IAmountsOwedReportsData,
  IGetAmountsOwedReportsParams,
} from './amountsOwedReportsTypes';
import { setIsReportDownloadingState } from './amountsOwedReportsSlice';

export const getAmountsOwedReports = createAsyncThunk<
  IAmountsOwedReportsData,
  IGetAmountsOwedReportsParams,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@amountsOwed/getAmountsOwedReports', async (params, thunkApi) => {
  try {
    const res = await fetchAmountsOwedReports(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const exportAmountsOwedReports = createAsyncThunk<
  void,
  AmountsOwedReportsParamsForDownloading,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@amountsOwed/exportAmountsOwedReports', async (params, thunkApi) => {
  try {
    console.log('exportAmountsOwedReports');
    thunkApi.dispatch(setIsReportDownloadingState(true));
    await downloadAmountsOwedReports(params);
    thunkApi.dispatch(setIsReportDownloadingState(false));
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
