import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { ClientInfoSliceInitialState, IClientInfo } from './clientInfoTypes';
import { changeClientInfo, getClientInfo } from './clientInfoActions';
import { isRejectedAction } from '../sliceHelpers';

const initialState: ClientInfoSliceInitialState = {
  info: {} as IClientInfo,
  isLoading: false,
  errorMessage: '',
};

export const clientInfoSlice = createSlice({
  name: '@@clientInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClientInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeClientInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClientInfo.fulfilled, (state, action) => {
      state.info = action.payload;
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addCase(changeClientInfo.fulfilled, (state, action) => {
      state.info = action.payload;
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    });
  },
});

export const clientInfoSelector = createSelector(
  [(state: RootState) => state.clientInfo.info],
  (info) => info
);

export const isLoadingClientInfoSelector = createSelector(
  [(state: RootState) => state.clientInfo.isLoading],
  (isLoading) => isLoading
);
