import * as Sentry from '@sentry/react';
import { Middleware } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

const sentryMiddleware: Middleware = () => (next) => (action: AnyAction) => {
  try {
    return next(action);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export default sentryMiddleware;
