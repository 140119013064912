import React, { forwardRef } from 'react';

interface CustomDatePickerInputProps
  extends React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    React.AriaAttributes {}

const CustomDatePickerInput = forwardRef<
  HTMLButtonElement,
  CustomDatePickerInputProps
>(({ value, onClick }, ref) => {
  return (
    <button
      className="form-control"
      style={{ color: 'black' }}
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  );
});

export default CustomDatePickerInput;
