import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import { SelectOption } from 'components/shared/forms/CustomFormikSelect/CustomFormikSelect';
import Button from 'components/shared/Button';
import { MaxLength40Symbols } from 'constants/formsConstants';
import { IClientShortInfo } from 'features/clientInfo/clientInfoTypes';
import { IMemberShortInfo } from 'features/memberInfo/memberInfoTypes';
import { IAddingProjectData } from 'features/projectsList/projectsListTypes';
import styles from './AddProjectForm.module.scss';

interface AddProjectFormProps {
  organizationMembers: IMemberShortInfo[];
  organizationClients: IClientShortInfo[];
  onSubmit: (data: IAddingProjectData) => void;
  closeAddProjectForm: () => void;
}

const AddProjectForm: FC<AddProjectFormProps> = ({
  organizationMembers,
  organizationClients,
  onSubmit,
  closeAddProjectForm,
}) => {
  const InitialValues: {
    projectName: string;
    client: null | SelectOption;
    members: SelectOption[];
  } = {
    projectName: '',
    client: null,
    members: [] as SelectOption[],
  };
  const validationSchema = Yup.object({
    projectName: Yup.string()
      .max(
        MaxLength40Symbols,
        ` *Maximum length ${MaxLength40Symbols} characters`
      )
      .required('Required field'),
  });

  const clientsOptions = organizationClients.map((c) => ({
    value: String(c.id),
    label: c.name,
  }));

  const membersOptions = organizationMembers.map((m) => ({
    value: String(m.id),
    label: m.name,
  }));

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        validationSchema={validationSchema}
        onSubmit={(
          values: typeof InitialValues,
          { setSubmitting, resetForm }
        ) => {
          onSubmit({
            projectName: values.projectName,
            membersIds: values.members.map((member) => Number(member.value)),
            clientId: values.client ? Number(values.client.value) : null,
          });
          setSubmitting(false);
          resetForm();
          closeAddProjectForm();
        }}
      >
        {(formik) => (
          <Form>
            <ValidationTextInput
              label="Project name"
              name="projectName"
              type="text"
              placeholder="Add a project name"
            />

            <CustomFormikSelect
              label={'Members'}
              name="members"
              options={membersOptions}
              isMulti
            />

            <CustomFormikSelect
              label="Client"
              name="client"
              options={clientsOptions}
            />

            <div className={styles.controlWrapper}>
              <Button
                type="button"
                onClick={() => {
                  formik.resetForm();
                  closeAddProjectForm();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting}
                preloader={formik.isSubmitting}
              >
                Add
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddProjectForm;
