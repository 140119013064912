import React from 'react';
import { useField } from 'formik';
import styles from './CustomFormikCheckbox.module.scss';

interface CustomFormikCheckboxProps {
  label: string;
  name: string;
  type?: 'checkbox' | 'radio';
}

const CustomFormikCheckbox: React.FC<CustomFormikCheckboxProps> = ({
  label,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className={styles.wrapper}>
        <input {...field} {...props} id={field.name} />
        <label htmlFor={field.name}>{label}</label>
      </div>
      {meta.touched && meta.error ? (
        <div className={styles.error}>*{meta.error}</div>
      ) : null}
    </>
  );
};

CustomFormikCheckbox.defaultProps = {
  type: 'checkbox',
};

export default CustomFormikCheckbox;
