import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  IMemberInfoSliceInitialState,
  RateHistoryEntry,
} from './memberInfoTypes';
import { changeMemberInfo, getMemberInfo } from './memberInfoActions';
import { isRejectedAction } from '../sliceHelpers';

const initialState: IMemberInfoSliceInitialState = {
  info: null,
  rateEntryToEdit: null,
  isLoading: false,
  errorMessage: '',
};

export const memberInfoSlice = createSlice({
  name: '@@memberInfo',
  initialState,
  reducers: {
    setRateHistoryEntryToEdit: (
      state,
      action: PayloadAction<RateHistoryEntry>
    ) => {
      state.rateEntryToEdit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMemberInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeMemberInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMemberInfo.fulfilled, (state, action) => {
      state.info = action.payload;
      state.isLoading = false;
      state.errorMessage = '';
    });
    builder.addCase(changeMemberInfo.fulfilled, (state, action) => {
      state.info = action.payload;
      state.isLoading = false;
      state.errorMessage = '';
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    });
  },
});

export const { setRateHistoryEntryToEdit } = memberInfoSlice.actions;

export const organizationMemberInfoSelector = createSelector(
  [(state: RootState) => state.member.info],
  (info) => info
);
export const rateHistoryEntryToEditMemberInfoSelector = createSelector(
  [(state: RootState) => state.member.rateEntryToEdit],
  (entry) => entry
);
export const isLoadingMemberInfoSelector = createSelector(
  [(state: RootState) => state.member.isLoading],
  (isLoading) => isLoading
);
