import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { TimeAndActivityReportsSliceInitialState } from './timeAndActivityReportsTypes';
import { getTimeAndActivityReports } from './timeAndActivityReportsActions';

const initialState: TimeAndActivityReportsSliceInitialState = {
  groupsTableHeaders: [],
  groups: [],
  summary: [],
  isLoading: false,
  isReportDownloading: false,
  errorMessage: '',
};

export const timeAndActivityReportsSlice = createSlice({
  name: '@@timeAndActivity',
  initialState,
  reducers: {
    setIsReportDownloadingState: (state, action: PayloadAction<boolean>) => {
      state.isReportDownloading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTimeAndActivityReports.pending, (state) => {
      state.errorMessage = '';
      state.isLoading = true;
    });
    builder.addCase(getTimeAndActivityReports.fulfilled, (state, action) => {
      state.groupsTableHeaders = action.payload.groupsTableHeaders;
      state.groups = action.payload.groups;
      state.summary = action.payload.summary;
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addCase(getTimeAndActivityReports.rejected, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const { setIsReportDownloadingState } =
  timeAndActivityReportsSlice.actions;

export const reportsGroupsListTimeAndActivitySelector = createSelector(
  [(state: RootState) => state.timeAndActivityReports.groups],
  (groups) => groups
);

export const groupsTableHeadersTimeAndActivitySelector = createSelector(
  [(state: RootState) => state.timeAndActivityReports.groupsTableHeaders],
  (headers) => [...headers].sort((a, b) => a.position - b.position)
);

export const summaryTimeAndActivitySelector = createSelector(
  [(state: RootState) => state.timeAndActivityReports.summary],
  (summary) => summary
);

export const isLoadingTimeAndActivitySelector = createSelector(
  [(state: RootState) => state.timeAndActivityReports.isLoading],
  (isLoading) => isLoading
);

export const isReportsDownloadingTimeAndActivitySelector = createSelector(
  [(state: RootState) => state.timeAndActivityReports.isReportDownloading],
  (isReportsDownloading) => isReportsDownloading
);
