import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useAppDispatch } from 'app/store';
import BackTo from 'components/shared/BackTo';
import PageTabs from 'components/shared/PageTabs';
import { getClientInfo } from 'features/clientInfo/clientInfoActions';

const EditClient = () => {
  const dispatch = useAppDispatch();
  const { clientId } = useParams();
  const tabsData = [
    {
      path: `/clients/${clientId}/edit`,
      name: 'General',
    },
  ];

  useEffect(() => {
    clientId && dispatch(getClientInfo(clientId));
  }, []);

  return (
    <>
      <BackTo title="Clients" path={`/clients`} />
      <PageTabs tabsData={tabsData} />
      <Outlet />
    </>
  );
};

export default EditClient;
