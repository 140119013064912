import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useAppDispatch } from 'app/store';
import PageTabs from 'components/shared/PageTabs';
import BackTo from 'components/shared/BackTo';
import { getMembersFilterList } from 'features/membersFilterList/membersFilterListActions';
import { getHolidayInfo } from 'features/holidayInfo/holidayInfoActions';

const EditHoliday = () => {
  const dispatch = useAppDispatch();
  const { holidayId } = useParams();
  const tabsData = [
    {
      path: `/time-off/holidays/${holidayId}/edit`,
      name: 'General',
    },
    {
      path: `/time-off/holidays/${holidayId}/edit/members`,
      name: 'Members',
    },
  ];

  useEffect(() => {
    holidayId && dispatch(getHolidayInfo(Number(holidayId)));
    dispatch(getMembersFilterList());
  }, []);

  return (
    <>
      <BackTo title="Holidays" path={`/time-off/holidays`} />
      <PageTabs tabsData={tabsData} />
      <Outlet />
    </>
  );
};

export default EditHoliday;
