import React from 'react';
import { useSelector } from 'react-redux';
import EmptyStatePlaceholder from 'components/shared/EmptyStatePlaceholder';
import ProjectsTable from 'components/projects/ProjectsTable';
import Preloader from 'components/shared/Preloader';
import {
  isLoadingProjectsListSelector,
  projectsProjectsListSelector,
} from 'features/projectsList/projectsListSlice';

const ActiveProjects = () => {
  const isLoading = useSelector(isLoadingProjectsListSelector);
  const projects = useSelector(projectsProjectsListSelector);

  const emptyMessage = 'No projects here.';

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          {projects.length ? (
            <ProjectsTable />
          ) : (
            <EmptyStatePlaceholder>{emptyMessage}</EmptyStatePlaceholder>
          )}
        </>
      )}
    </>
  );
};

export default ActiveProjects;
