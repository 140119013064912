import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import EditPolicyGeneralForm from 'components/timeOff/EditPolicyGeneralForm';
import Preloader from 'components/shared/Preloader';
import { IPolicyInfoUpdatingData } from 'features/policyInfo/policyInfoTypes';
import {
  isLoadingPolicyInfoSelector,
  policyInfoSelector,
} from 'features/policyInfo/policyInfoSlice';
import { changePolicyInfo } from 'features/policyInfo/policyInfoActions';

const EditPolicyGeneral = () => {
  const isLoading = useSelector(isLoadingPolicyInfoSelector);
  const policy = useSelector(policyInfoSelector);
  const dispatch = useAppDispatch();

  const handleSubmit = (data: IPolicyInfoUpdatingData) => {
    dispatch(changePolicyInfo(data));
  };

  return isLoading ? (
    <Preloader />
  ) : (
    <EditPolicyGeneralForm policy={policy} onSubmit={handleSubmit} />
  );
};

export default EditPolicyGeneral;
