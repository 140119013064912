import React from 'react';
import { useSelector } from 'react-redux';
import EmptyStatePlaceholder from 'components/shared/EmptyStatePlaceholder';
import RequestsTable from 'components/timeOff/RequestsTable';
import Preloader from 'components/shared/Preloader';
import {
  isLoadingTimeOffRequestsSelector,
  timeOffRequestsListSelector,
} from 'features/timeOffRequestsList/timeOffRequestsListSlice';

const DeniedTimeOffRequests = () => {
  const requests = useSelector(timeOffRequestsListSelector);
  const isLoading = useSelector(isLoadingTimeOffRequestsSelector);

  const emptyMessage = 'There are no denied time off requests.';

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : requests.length ? (
        <RequestsTable />
      ) : (
        <EmptyStatePlaceholder>{emptyMessage}</EmptyStatePlaceholder>
      )}
    </>
  );
};

export default DeniedTimeOffRequests;
