import React, { FC } from 'react';
import styles from './AddTimeFloatBtn.module.scss';

interface AddTimeFloatBtnProps {
  onClick: () => void;
}

const AddTimeFloatBtn: FC<AddTimeFloatBtnProps> = ({ onClick }) => {
  return (
    <button className={styles.root} onClick={onClick}>
      +
    </button>
  );
};

export default AddTimeFloatBtn;
