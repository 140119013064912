import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import EditClientGeneralForm from 'components/clients/EditClientGeneralForm';
import Preloader from 'components/shared/Preloader';
import { changeClientInfo } from 'features/clientInfo/clientInfoActions';
import { IClientInfo } from 'features/clientInfo/clientInfoTypes';
import {
  clientInfoSelector,
  isLoadingClientInfoSelector,
} from 'features/clientInfo/clientInfoSlice';

const EditClientGeneral = () => {
  const isLoading = useSelector(isLoadingClientInfoSelector);
  const currentClient = useSelector(clientInfoSelector);
  const dispatch = useAppDispatch();

  const handleSubmit = (data: IClientInfo) => {
    dispatch(changeClientInfo(data));
  };

  return isLoading ? (
    <Preloader />
  ) : (
    <EditClientGeneralForm
      projectClient={currentClient}
      onSubmit={handleSubmit}
    />
  );
};

export default EditClientGeneral;
