import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './PageTabs.module.scss';

export interface IPageTab {
  path: string;
  name: string;
}

interface PageTabsProps {
  tabsData: IPageTab[];
}

const PageTabs: FC<PageTabsProps> = ({ tabsData }) => {
  return (
    <div className={styles.nav}>
      {tabsData.map((tab, index) => (
        <NavLink
          key={index}
          to={tab.path}
          className={({ isActive }) => (isActive ? styles.activeTab : '')}
          end
        >
          <div className={styles.text}>{tab.name}</div>
          <div className={styles.border} />
        </NavLink>
      ))}
    </div>
  );
};

export default PageTabs;
