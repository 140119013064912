import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import debounce from 'lodash.debounce';
import AddTimeOffHolidayForm from 'components/timeOff/AddTimeOffHolidayForm';
import EmptyStatePlaceholder from 'components/shared/EmptyStatePlaceholder';
import HolidaysTable from 'components/timeOff/HolidaysTable';
import SearchInput from 'components/shared/forms/SearchForm/SearchInput';
import Preloader from 'components/shared/Preloader';
import Button from 'components/shared/Button';
import Modal from 'components/shared/Modal';
import { getMembersFilterList } from 'features/membersFilterList/membersFilterListActions';
import {
  IAddingHolidayData,
  IFetchHolidaysParams,
} from 'features/holidaysList/holidaysListTypes';
import {
  filterHolidaysListSelector,
  holidaysListSelector,
  isLoadingHolidaysListSelector,
  setHolidaysFilter,
} from 'features/holidaysList/holidaysListSlice';
import {
  addHoliday,
  getHolidaysList,
} from 'features/holidaysList/holidaysListActions';
import { startsWithNoSpace } from 'helpers/regularExpressions';
import styles from './TimeOffHolidays.module.scss';

const emptyMessage = 'There are no created holidays yet.';

const TimeOffHolidays = () => {
  const holidaysPageFilter = useSelector(filterHolidaysListSelector);
  const isLoading = useSelector(isLoadingHolidaysListSelector);
  const holidaysData = useSelector(holidaysListSelector);
  const didMountRef = useRef(false);
  const dispatch = useAppDispatch();
  const [addHolidayFormOpen, setAddHolidayFormOpen] = useState(false);
  const handleAddHoliday = (data: IAddingHolidayData) => {
    dispatch(addHoliday(data));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    if (startsWithNoSpace.test(text)) {
      dispatch(
        setHolidaysFilter({
          ...holidaysPageFilter,
          searchValue: text,
          skip: 0,
          take: 20,
        })
      );
    }
  };

  const clearInput = () => {
    dispatch(
      setHolidaysFilter({
        ...holidaysPageFilter,
        searchValue: '',
        skip: 0,
        take: 20,
      })
    );
  };
  const debouncedHolidaysFiltration = useCallback(
    debounce((params: IFetchHolidaysParams) => {
      dispatch(getHolidaysList(params));
    }, 300),
    []
  );

  useEffect(() => {
    dispatch(getHolidaysList(holidaysPageFilter));
    dispatch(getMembersFilterList());
  }, []);

  useEffect(() => {
    if (didMountRef.current) {
      debouncedHolidaysFiltration(holidaysPageFilter);
    }
    didMountRef.current = true;
  }, [holidaysPageFilter.searchValue]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.controls}>
        <div className={styles.searchWrapper}>
          <SearchInput
            value={holidaysPageFilter.searchValue}
            onChange={handleInputChange}
            clearInput={clearInput}
            placeholder="Search holidays"
            outlined
          />
        </div>

        <div>
          <Button
            variant="primary"
            onClick={() => setAddHolidayFormOpen(true)}
            aria-label="Open add holiday form."
          >
            Add holiday
          </Button>
        </div>
      </div>

      {isLoading ? (
        <Preloader />
      ) : holidaysData.length ? (
        <HolidaysTable />
      ) : (
        <EmptyStatePlaceholder>{emptyMessage}</EmptyStatePlaceholder>
      )}

      <Modal
        open={addHolidayFormOpen}
        onClose={() => setAddHolidayFormOpen(false)}
        title="Add public holiday"
      >
        <AddTimeOffHolidayForm
          handleCLose={() => setAddHolidayFormOpen(false)}
          handleSubmit={handleAddHoliday}
        />
      </Modal>
    </div>
  );
};

export default TimeOffHolidays;
