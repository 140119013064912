import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/store';
import {
  fetchMemberTimesheetsInfo,
  fetchTimesheetsMembersFilterList,
} from 'api/timesheets';
import { IMemberTimesheetsInfo } from './timesheetsTypes';
import { IMemberShortInfo } from 'features/memberInfo/memberInfoTypes';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';

export const getTimesheetsFilterlist = createAsyncThunk<
  IMemberShortInfo[],
  void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@timesheets/getTimesheetsFilterlist', async (_, thunkApi) => {
  try {
    const res = await fetchTimesheetsMembersFilterList();
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const getMemberTimesheetsInfo = createAsyncThunk<
  IMemberTimesheetsInfo,
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@timesheets/getMemberTimesheetsInfo', async (memberId, thunkApi) => {
  try {
    const res = await fetchMemberTimesheetsInfo(memberId);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
