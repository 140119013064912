import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { fetchPolicyInfo, updatePolicyInfo } from 'api/policyInfo';
import { AppDispatch } from 'app/store';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import { IPolicyInfo, IPolicyInfoUpdatingData } from './policyInfoTypes';

export const getPolicyInfo = createAsyncThunk<
  IPolicyInfo,
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@policyInfo/getPolicyInfo', async (policyId, thunkApi) => {
  try {
    const res = await fetchPolicyInfo(policyId);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const changePolicyInfo = createAsyncThunk<
  IPolicyInfo,
  IPolicyInfoUpdatingData,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@policyInfo/changePolicyInfo', async (policyInfo, thunkApi) => {
  try {
    const res = await updatePolicyInfo(policyInfo);
    if (res.status === 200) {
      toast.success('Policy successfully updated!');
    }
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
