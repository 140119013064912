import React, { FC, ReactNode, useRef } from 'react';
import UseMount from 'hooks/useMount';
import { useClickOutside } from 'hooks/useClickOutside';
import styles from './MenuDrawer.module.scss';

interface MenuDrawerProps {
  open: boolean;
  onClose: (e: Event) => void;
  children: ReactNode;
}

const MenuDrawer: FC<MenuDrawerProps> = ({ open, onClose, children }) => {
  const { mounted } = UseMount({ opened: open, animationTime: 300 });
  const asideRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(asideRef, onClose);

  if (!mounted) return null;

  return (
    <>
      <div
        className={styles.overlay}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
      <aside
        ref={asideRef}
        className={`${styles.menu} ${open ? styles.show : ''}`}
      >
        <div className={styles.content}>{children}</div>
      </aside>
    </>
  );
};

export default MenuDrawer;
