import React, { FC, ReactNode, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './SidebarSubmenuLinks.module.scss';
import { ArrowIcon } from 'assets/icons/ArrowIcon';

export interface ISubMenuLink {
  title: string;
  path: string;
  isAvailable: boolean;
}

interface SidebarSubMenuLinksProps {
  menuTitle: string;
  icon: ReactNode;
  subMenuItemsData: ISubMenuLink[];
  activeTitle: boolean;
}

const SidebarSubMenuLinks: FC<SidebarSubMenuLinksProps> = ({
  menuTitle,
  icon,
  subMenuItemsData,
  activeTitle,
}) => {
  const [subNav, setSubNav] = useState(false);
  const toggleSubNav = () => setSubNav(!subNav);

  useEffect(() => {
    if (activeTitle) {
      setSubNav(true);
    }
  }, []);

  return (
    <>
      <li
        className={`${styles.subMenuTitle} ${
          activeTitle ? styles.activeTitle : ''
        }`}
        onClick={toggleSubNav}
      >
        {icon}
        <>
          <div className={styles.title}>{menuTitle}</div>
          <button
            aria-label={
              subNav ? 'Close navigation links' : 'Open navigation links'
            }
            className={`${styles.arrow} ${subNav ? styles.openedSubNav : ''}`}
          >
            <ArrowIcon />
          </button>
        </>
      </li>

      {subNav && (
        <ul className={styles.subMenu}>
          {subMenuItemsData.map(
            (v, i) =>
              v.isAvailable && (
                <li key={i} className={styles.subItem}>
                  <NavLink
                    to={v.path}
                    aria-label={`${v.title} page link`}
                    className={({ isActive }) => {
                      return isActive
                        ? `${styles.subItemLink} ${styles.activeClassName}`
                        : styles.subItemLink;
                    }}
                  >
                    {v.title}
                  </NavLink>
                </li>
              )
          )}
        </ul>
      )}
    </>
  );
};

export default SidebarSubMenuLinks;
