import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import EmptyStatePlaceholder from 'components/shared/EmptyStatePlaceholder';
import InvitedMembersTable from 'components/members/InvitedMembersTable';
import Preloader from 'components/shared/Preloader';
import { getInvites } from 'features/invitesList/invitesListActions';
import {
  filterInvitesSelector,
  isLoadingInvitesSelector,
  listInvitesSelector,
} from 'features/invitesList/invitesListSlice';

const Invites = () => {
  const invites = useSelector(listInvitesSelector);
  const isLoading = useSelector(isLoadingInvitesSelector);
  const dispatch = useAppDispatch();
  const invitesPageFilter = useSelector(filterInvitesSelector);

  // useEffect(() => {
  //   dispatch(getInvites(invitesPageFilter));
  // }, []);

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          {invites.length > 0 ? (
            <InvitedMembersTable />
          ) : (
            <EmptyStatePlaceholder>No invitations</EmptyStatePlaceholder>
          )}
        </>
      )}
    </>
  );
};

export default Invites;
