import React, { FC, useState } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import PulseLoader from 'react-spinners/PulseLoader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useAppDispatch } from 'app/store';
import ActionsPopover from 'components/shared/ActionsPopover';
import ConfirmDialog from 'components/shared/ConfirmDialog';
import { IHolidaysListItem } from 'features/holidaysList/holidaysListTypes';
import {
  getHolidaysListInfiniteScroll,
  removeHoliday,
} from 'features/holidaysList/holidaysListActions';
import {
  filterHolidaysListSelector,
  holidaysListSelector,
  isLoadingHolidaysListSelector,
  itemsTotalCountHolidaysListSelector,
} from 'features/holidaysList/holidaysListSlice';
import HolidaysActionsList from '../HolidaysActionsList';
import styles from 'components/shared/tables/infiniteScrollTable.module.scss';

const holidaysTableColumns = [
  { name: 'Holiday name' },
  { name: 'Members' },
  { name: 'Date' },
  { name: 'Actions' },
];

const HolidaysTable: FC = () => {
  const holidaysTotalCount = useSelector(itemsTotalCountHolidaysListSelector);
  const isHolidaysLoading = useSelector(isLoadingHolidaysListSelector);
  const holidaysPageFilter = useSelector(filterHolidaysListSelector);
  const infiniteListItems = useSelector(holidaysListSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [holidayIdForDelete, setHolidayIdForDelete] = useState<
    number | undefined
  >(undefined);
  const [confirmRemoveHoliday, setConfirmRemoveHoliday] = useState(false);
  const handleRemove = (id: number) => {
    setHolidayIdForDelete(id);
    setConfirmRemoveHoliday(true);
  };

  const hasNextPage = infiniteListItems.length < holidaysTotalCount;
  const loadNextPage = async () => {
    dispatch(
      getHolidaysListInfiniteScroll({
        ...holidaysPageFilter,
        skip: infiniteListItems.length,
        take: 20,
      })
    );
  };
  const itemCount = hasNextPage
    ? infiniteListItems.length + 1
    : infiniteListItems.length;
  const loadMoreItems = isHolidaysLoading ? () => {} : loadNextPage;
  const isItemLoaded = (index: number) =>
    !hasNextPage || index < infiniteListItems.length;

  const HolidaysTableItem: FC<ListChildComponentProps<IHolidaysListItem[]>> = (
    props
  ) => {
    const content = infiniteListItems[props.index];

    if (!isItemLoaded(props.index))
      return (
        <div style={props.style} className={styles.loadNextTableIndicator}>
          <PulseLoader
            color="#0168fa"
            size={8}
            margin={5}
            speedMultiplier={1}
          />
        </div>
      );

    return (
      <div style={props.style}>
        <div className={`${styles.fRow} ${styles.fBodyRow}`}>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={() => navigate(`/time-off/holidays/${content.id}/edit`)}
          >
            <span className={`${styles.fBodyCellContent}`}>{content.name}</span>
          </div>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={() =>
              navigate(`/time-off/holidays/${content.id}/edit/members`)
            }
          >
            <span
              className={`${styles.fBodyCellContent} ${
                +content.members.length <= 0 && styles.noDataCellContent
              }`}
            >
              {content.members.length > 0 ? (
                content.members.length !== 1 ? (
                  <div className={styles.fewMembersWrapper}>
                    <div>{content.members[0].name}</div>
                    <div className={styles.remainingMembers}>
                      +{String(content.members.length - 1)}
                    </div>
                  </div>
                ) : (
                  content.members[0].name
                )
              ) : (
                'No members'
              )}
            </span>
          </div>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={() => navigate(`/time-off/holidays/${content.id}/edit`)}
          >
            <span className={`${styles.fBodyCellContent}`}>
              {format(new Date(content.date), 'MMM dd')}
            </span>
          </div>
          <div className={`${styles.fCell} ${styles.fBodyCell}`}>
            <ActionsPopover>
              <HolidaysActionsList
                holidayId={content.id}
                handleRemove={handleRemove}
              />
            </ActionsPopover>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.fTable}>
        <div className={`${styles.fRow} ${styles.fHeaderRow}`}>
          {holidaysTableColumns.map((col) => (
            <div
              key={col.name}
              className={`${styles.fCell} ${styles.fHeaderCell}`}
            >
              {col.name}
            </div>
          ))}
        </div>
        <div className={styles.fTableBody}>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    itemCount={itemCount}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    height={height}
                    overscanCount={1}
                    itemSize={70}
                    width={width}
                  >
                    {HolidaysTableItem}
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </div>
      </div>
      {holidayIdForDelete && (
        <ConfirmDialog
          open={confirmRemoveHoliday}
          title="Confirm"
          description="Delete this holiday?"
          acceptBtnText="Delete"
          declineBtnText="Cancel"
          onClose={() => setConfirmRemoveHoliday(false)}
          onAccept={() => {
            dispatch(removeHoliday(holidayIdForDelete));
          }}
        />
      )}
    </>
  );
};

export default HolidaysTable;
