import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatISO } from 'date-fns';
import { RootState } from 'app/store';
import {
  CalendarViews,
  EventTypes,
  IEventData,
  TimeEntriesSliceInitialState,
} from './timeEntriesTypes';
import {
  addTimeEntry,
  changeTimeEntry,
  getMemberTimeEntries,
} from './timeEntriesActions';
import { dateToTimeZone } from 'helpers/dateToTimeZone';
import { sumTimeRanges } from 'helpers/sumTimeRanges';
import { isFulfilledAction, isRejectedAction } from '../sliceHelpers';
import { ICurrentCalendarParams } from '../timesheets/timesheetsTypes';

const initialState: TimeEntriesSliceInitialState = {
  events: [],
  totalTimeLogged: '00h 00m',
  selectedEventId: '',
  initialCalendarView: 'dayGridMonth',
  isLoading: false,
  errorMessage: '',
};

export const timeEntriesSlice = createSlice({
  name: '@@timeEntries',
  initialState,
  reducers: {
    setEventIdForUpdate: (state, action: PayloadAction<string>) => {
      state.selectedEventId = action.payload;
    },
    setInitialCalendarView: (
      state,
      action: PayloadAction<keyof typeof CalendarViews>
    ) => {
      state.initialCalendarView = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getMemberTimeEntries.fulfilled,
      (
        state,
        action: PayloadAction<{
          events: IEventData[];
          params: ICurrentCalendarParams;
        }>
      ) => {
        const calendarStart = action.payload.params.start;
        const calendarEnd = action.payload.params.end;
        const selectedTimezone = action.payload.params.timeZoneName;

        const timeRanges = action.payload.events.map((e) => {
          if (e.type === EventTypes.Policy || e.type === EventTypes.Holiday)
            return { start: new Date(0), end: new Date(0) };
          return {
            start: dateToTimeZone(e.start, selectedTimezone),
            end: dateToTimeZone(e.end, selectedTimezone),
          };
        });

        state.totalTimeLogged = sumTimeRanges(
          timeRanges,
          calendarStart,
          calendarEnd
        );
        state.events = action.payload.events;
      }
    );
    builder.addCase(getMemberTimeEntries.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addTimeEntry.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeTimeEntry.pending, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(isFulfilledAction, (state) => {
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const { setEventIdForUpdate, setInitialCalendarView } =
  timeEntriesSlice.actions;

export const eventsTimeEntriesSelector = createSelector(
  [(state: RootState) => state.timeEntries.events],
  (events) => {
    return events;
  }
);

export const isLoadingTimeEntriesSelector = createSelector(
  [(state: RootState) => state.timeEntries.isLoading],
  (isLoading) => isLoading
);

export const selectedEventIdTimeEntriesSelector = createSelector(
  [(state: RootState) => state.timeEntries.selectedEventId],
  (id) => {
    return id;
  }
);

export const initialCalendarViewTimeEntriesSelector = createSelector(
  [(state: RootState) => state.timeEntries.initialCalendarView],
  (view) => view
);

export const totalTimeLoggedTimeEntriesSelector = createSelector(
  [(state: RootState) => state.timeEntries.totalTimeLogged],
  (time) => time
);

export const calendarEventsTimeEntriesSelector = createSelector(
  [
    eventsTimeEntriesSelector,
    (state: RootState, memberTimeZone: string) => memberTimeZone,
  ],
  (allEvents, memberTimeZone) => {
    return allEvents.map((e) => {
      if (e.type === EventTypes.Holiday) {
        return {
          id: String(e.id),
          title: `${e.type}: ${e.event.name}`,
          start: formatISO(dateToTimeZone(e.start, memberTimeZone)),
          end: formatISO(dateToTimeZone(e.end, memberTimeZone)),
          borderColor: 'rgb(230, 46, 123)',
          backgroundColor: 'rgba(230, 46, 123, 0.05)',
        };
      } else if (e.type === EventTypes.Policy) {
        return {
          id: String(e.id),
          title: `${e.type}: ${e.event.name}`,
          start: formatISO(dateToTimeZone(e.start, memberTimeZone)),
          end: formatISO(dateToTimeZone(e.end, memberTimeZone)),
          borderColor: 'rgb(136, 51, 255)',
          backgroundColor: 'rgba(136, 51, 255, 0.05)',
        };
      } else {
        return {
          id: String(e.id),
          title: e.event.name,
          start: formatISO(dateToTimeZone(e.start, memberTimeZone)),
          end: formatISO(dateToTimeZone(e.end, memberTimeZone)),
          borderColor: 'rgb(0, 122, 255)',
          backgroundColor: 'rgba(0, 122, 255, 0.05)',
        };
      }
    });
  }
);

export const eventByIdTimeEntriesSelector = createSelector(
  [
    eventsTimeEntriesSelector,
    selectedEventIdTimeEntriesSelector,
    (state: RootState, memberTimeZone: string) => memberTimeZone,
  ],
  (allEvents, id, memberTimeZone) => {
    const event = allEvents.find((event) => String(event.id) === id);
    if (event) {
      return {
        ...event,
        allDay: event.allDay || false,
        start: dateToTimeZone(event.start, memberTimeZone),
        end: dateToTimeZone(event.end, memberTimeZone),
      };
    }
  }
);
