import React, { FC } from 'react';
import TableRowItem from '../TableRowItem';
import TotalRowItem from '../TotalRowItem';
import {
  IAmountsOwedReportsGroup,
  IAmountsOwedReportsTableColumnHeading,
} from 'features/amountsOwedReports/amountsOwedReportsTypes';
import styles from './ReportsGroupBody.module.scss';
import MemberDetailedRowItem from '../MemberDetailedRowItem';

interface ReportsGroupBodyProps {
  group: IAmountsOwedReportsGroup;
  tableHeaders: IAmountsOwedReportsTableColumnHeading[];
}

const ReportsGroupBody: FC<ReportsGroupBodyProps> = ({
  group,
  tableHeaders,
}) => {
  return (
    <>
      <tbody className={styles.groupTitle}>
        <tr>
          <td colSpan={tableHeaders.length}>{group.title}</td>
        </tr>
      </tbody>

      <tbody className={styles.groupBody}>
        {group.membersSummaryReports.map((memberSummary) => (
          <React.Fragment key={memberSummary.id}>
            <TableRowItem item={memberSummary} columns={tableHeaders} />
            {memberSummary.details &&
              memberSummary.details.length > 0 &&
              memberSummary.details.map((d) => (
                <MemberDetailedRowItem
                  key={d.id}
                  item={d}
                  columns={tableHeaders}
                />
              ))}
          </React.Fragment>
        ))}
      </tbody>

      <tbody className={styles.groupTotal}>
        <tr>
          {tableHeaders.map((col, index) => {
            if (index === 0) {
              return <td key={col.title}>Total</td>;
            }
            return (
              <TotalRowItem key={col.title} item={group.total} column={col} />
            );
          })}
        </tr>
      </tbody>
    </>
  );
};

export default ReportsGroupBody;
