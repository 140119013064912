import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { AppDispatch } from 'app/store';
import { fetchOrganizationClientInfo, updateClientInfo } from 'api/clientInfo';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import { IClientInfo } from './clientInfoTypes';
import { IMemberInfo } from '../memberInfo/memberInfoTypes';

export const getClientInfo = createAsyncThunk<
  IClientInfo,
  string,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@clientInfo/getClientInfo', async (clientId, thunkApi) => {
  try {
    const res = await fetchOrganizationClientInfo(clientId);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const changeClientInfo = createAsyncThunk<
  IClientInfo,
  IClientInfo,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@clientInfo/changeClientInfo', async (clientInfo, thunkApi) => {
  try {
    const res = await updateClientInfo(clientInfo);
    console.log(res.data);
    if (res.status === 200) {
      toast.success('Client info successfully updated!');
    }
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
