import React, { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import { useField, FieldHookConfig } from 'formik';

import styles from './ValidationTextInput.module.scss';

interface ValidationTextInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
}

const ValidationTextInput: FC<
  ValidationTextInputProps & FieldHookConfig<string>
> = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div>
      {label && (
        <label className="input-label" htmlFor={props.id || props.name}>
          {label}
        </label>
      )}
      <div className={styles.root}>
        <input
          className={`form-control ${
            meta.touched && meta.error ? 'errorInput' : ''
          }`}
          {...field}
          {...props}
          autoComplete="off"
        />
        {meta.touched && meta.error ? (
          <div className={styles.errorMessage}>*{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};

export default ValidationTextInput;
