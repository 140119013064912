import React, { FC } from 'react';
import { format } from 'date-fns';
import { IoMailOutline, IoPersonCircle, IoTimeOutline } from 'react-icons/io5';
import { IMemberInfo } from 'features/memberInfo/memberInfoTypes';
import styles from './MemberProfileCard.module.scss';

interface MemberProfileCardProps {
  memberInfo: IMemberInfo;
}

const MemberProfileCard: FC<MemberProfileCardProps> = ({ memberInfo }) => {
  const convertedJoinedDate =
    memberInfo.joined && format(new Date(memberInfo.joined), 'MMM dd yyyy');

  return (
    <>
      <div className={styles.card}>
        <div className={styles.profileImage}>
          <IoPersonCircle />
          <div className={styles.joinDate}>Joined</div>
          <div className={styles.joinDate}>{convertedJoinedDate}</div>
        </div>
        <div className={styles.profileInfo}>
          <div className={styles.fullName}>{memberInfo.fullName}</div>
          <div className={styles.email}>
            <IoMailOutline /> <div>{memberInfo.email}</div>
          </div>
          <div className={styles.timeZone}>
            <IoTimeOutline />
            <div> {memberInfo.timeZone}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberProfileCard;
