import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import {
  changeOrganizationInfo,
  fetchOrganizationInfo,
} from 'api/organization';
import { AppDispatch, RootState } from 'app/store';
import { IOrganization, IUpdateOrganizationData } from './organizationTypes';
import { toast } from 'react-toastify';

export const getOrganizationInfo = createAsyncThunk<
  IOrganization,
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@organization/getOrganizationInfo', async (id, thunkApi) => {
  try {
    const res = await fetchOrganizationInfo(id);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const updateOrganizationInfo = createAsyncThunk<
  void,
  IUpdateOrganizationData,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@organization/updateOrganizationInfo', async (data, thunkApi) => {
  try {
    const res = await changeOrganizationInfo(data);
    if (res.status === 200) {
      toast.success('Information updated.');
      const orgId = thunkApi.getState().user.userInfo?.organizationId;
      orgId && thunkApi.dispatch(getOrganizationInfo(orgId));
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
