import React from 'react';
import styles from './CustomCheckbox.module.scss';

interface CustomCheckboxProps {
  label: string;
  isChecked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  label,
  isChecked,
  handleChange,
}) => {
  return (
    <div className={styles.wrapper}>
      <input
        type="checkbox"
        id={label}
        checked={isChecked}
        onChange={handleChange}
      />
      <label htmlFor={label}>{label}</label>
    </div>
  );
};

export default CustomCheckbox;
