export const numToCurrencyWithLocale = (
  num: number,
  currency: string,
  locale: string
) => {
  return num.toLocaleString(locale, {
    style: 'currency',
    currency,
  });
};
