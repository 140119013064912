import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomFormikCheckbox from 'components/shared/forms/CustomFormikCheckbox';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import Button from 'components/shared/Button';
import { MaxLength50Symbols } from 'constants/formsConstants';
import {
  IPolicyInfo,
  IPolicyInfoUpdatingData,
} from 'features/policyInfo/policyInfoTypes';
import styles from './EditPolicyGeneralForm.module.scss';

interface EditPolicyGeneralFormProps {
  policy: IPolicyInfo;
  onSubmit: (data: IPolicyInfoUpdatingData) => void;
}

const yesNoOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];
const paidOrUnpaidOptions = [
  { label: 'Paid', value: 'paid' },
  { label: 'Unpaid', value: 'unpaid' },
];

const EditPolicyGeneralForm: FC<EditPolicyGeneralFormProps> = ({
  policy,
  onSubmit,
}) => {
  const InitialValues = {
    policyName: policy.name || '',
    paidOrUnpaid: policy.paid
      ? { label: 'Paid', value: 'paid' }
      : { label: 'Unpaid', value: 'unpaid' },
    newMembersAutoAdding: policy.newMembersAutoAdding,
    requiresApproval: policy.requiresApproval
      ? { label: 'Yes', value: 'yes' }
      : { label: 'No', value: 'no' },
  };

  const validationSchema = Yup.object({
    policyName: Yup.string()
      .max(
        MaxLength50Symbols,
        `Maximum length ${MaxLength50Symbols} characters`
      )
      .required('Policy name is required field'),
  });

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit({
            id: policy.id,
            name: values.policyName,
            newMembersAutoAdding: values.newMembersAutoAdding,
            requiresApproval: values.requiresApproval.value !== 'no',
            paid: values.paidOrUnpaid.value !== 'unpaid',
          });
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <ValidationTextInput
              label="Policy name"
              name="policyName"
              type="text"
            />

            <CustomFormikSelect
              label={'Paid or unpaid'}
              name="paidOrUnpaid"
              options={paidOrUnpaidOptions}
            />

            <CustomFormikSelect
              label={'Requires approval'}
              name="requiresApproval"
              options={yesNoOptions}
            />

            <CustomFormikCheckbox
              label="Automatically add new members to this policy"
              name="newMembersAutoAdding"
            />

            <div className={styles.submitWrapper}>
              <Button
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting}
                preloader={formik.isSubmitting}
              >
                Save changes
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditPolicyGeneralForm;
