import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchOrganizationProjectInfo,
  updateProjectInfo,
} from 'api/projectInfo';
import { AppDispatch } from 'app/store';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import { IProjectInfo, IProjectInfoUpdatingData } from './projectInfoTypes';
import { toast } from 'react-toastify';

export const getProjectInfo = createAsyncThunk<
  IProjectInfo,
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@projectInfo/getProjectInfo', async (projectId, thunkApi) => {
  try {
    const res = await fetchOrganizationProjectInfo(projectId);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const changeProjectInfo = createAsyncThunk<
  IProjectInfo,
  IProjectInfoUpdatingData,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@projectInfo/changeProjectInfo', async (projectInfo, thunkApi) => {
  try {
    const res = await updateProjectInfo(projectInfo);
    if (res.status === 200) {
      toast.success('Project info successfully updated!');
    }
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
