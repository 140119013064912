import React from 'react';

export const TeamplayLogoIcon = () => {
  return (
    <svg
      width="165"
      height="41"
      viewBox="0 0 165 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.984 6.6H0.672V11.112H6.912V29H11.744V11.112H17.984V6.6ZM35.3205 20.328C35.3205 15.336 31.6725 11.24 26.3605 11.24C21.2405 11.24 17.4005 15.176 17.4005 20.36C17.4005 25.544 21.2725 29.512 26.3605 29.512C30.3925 29.512 33.6885 27.112 34.9365 23.336H30.0405C29.3365 24.584 27.9925 25.352 26.3605 25.352C24.0885 25.352 22.5525 24.04 22.1365 21.672H35.2245C35.2885 21.224 35.3205 20.776 35.3205 20.328ZM26.3605 15.24C28.4725 15.24 29.9765 16.392 30.6485 18.504H22.2645C22.8085 16.456 24.2805 15.24 26.3605 15.24ZM52.7263 11.72L52.2463 13.352C50.7423 12.04 48.7903 11.24 46.5503 11.24C41.3663 11.24 37.4943 15.144 37.4943 20.36C37.4943 25.576 41.3663 29.512 46.5503 29.512C48.7583 29.512 50.6783 28.744 52.1503 27.432L52.5662 29H56.2143V11.72H52.7263ZM46.9343 25.096C44.2143 25.096 42.2303 23.048 42.2303 20.36C42.2303 17.64 44.2143 15.624 46.9343 15.624C49.6543 15.624 51.6383 17.64 51.6383 20.36C51.6383 23.08 49.6543 25.096 46.9343 25.096ZM80.388 11.336C78.18 11.336 76.228 12.424 75.044 14.088C74.02 12.36 72.228 11.336 70.02 11.336C67.972 11.336 66.084 12.328 64.868 13.864L64.004 11.72H60.74V29H65.412V19.656C65.412 16.968 66.66 15.432 68.772 15.432C70.436 15.432 71.332 16.648 71.332 18.952V29H75.876V19.656C75.876 16.968 77.124 15.432 79.204 15.432C80.9 15.432 81.796 16.648 81.796 18.952V29H86.468V18.184C86.468 14.12 83.972 11.336 80.388 11.336Z"
        fill="#007AFF"
      />
      <path
        d="M100.467 11.368C97.4913 11.368 94.9633 12.712 93.3633 14.856L92.9153 11.72H91.3793V35.176H93.5553V26.152C95.1553 28.136 97.6193 29.384 100.467 29.384C105.619 29.384 109.331 25.512 109.331 20.36C109.331 15.208 105.619 11.368 100.467 11.368ZM100.307 27.272C96.4033 27.272 93.4593 24.296 93.4593 20.36C93.4593 16.456 96.4033 13.48 100.307 13.48C104.243 13.48 107.123 16.392 107.123 20.36C107.123 24.36 104.243 27.272 100.307 27.272ZM113.661 29H115.837V5.576H113.661V29ZM136.479 11.72L136.191 14.856C134.623 12.712 132.095 11.368 129.055 11.368C123.967 11.368 120.159 15.208 120.159 20.36C120.159 25.512 123.967 29.384 129.055 29.384C132.095 29.384 134.591 28.04 136.191 25.928L136.415 29H138.239V11.72H136.479ZM129.215 27.272C125.279 27.272 122.367 24.296 122.367 20.36C122.367 16.456 125.279 13.48 129.215 13.48C133.151 13.48 136.031 16.392 136.031 20.36C136.031 24.36 133.151 27.272 129.215 27.272Z"
        fill="black"
      />
      <rect
        x="150.322"
        y="19.48"
        width="0.909514"
        height="13.9213"
        transform="rotate(18.0175 150.322 19.48)"
        fill="#007AFF"
      />
      <circle cx="151.347" cy="18.4426" r="2.04465" fill="black" />
      <rect
        x="163.47"
        y="6.17932"
        width="2.29343"
        height="15.7314"
        transform="rotate(48.1411 163.47 6.17932)"
        fill="black"
      />
      <rect
        x="142"
        y="10.7424"
        width="1.62059"
        height="10.9868"
        transform="rotate(-45.704 142 10.7424)"
        fill="black"
      />
    </svg>
  );
};
