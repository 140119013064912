import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import EditProjectMembersForm from 'components/projects/EditProjectMembersForm';
import Preloader from 'components/shared/Preloader';
import { membersFilterListSelector } from 'features/membersFilterList/membersFilterListSlice';
import { changeProjectInfo } from 'features/projectInfo/projectInfoActions';
import {
  isLoadingProjectInfoSelector,
  projectInfoSelector,
} from 'features/projectInfo/projectInfoSlice';

const EditProjectMembers = () => {
  const currentProject = useSelector(projectInfoSelector);
  const isLoading = useSelector(isLoadingProjectInfoSelector);
  const members = useSelector(membersFilterListSelector);
  const dispatch = useAppDispatch();

  const handleSubmit = (projectId: number, membersIds: number[]) => {
    dispatch(changeProjectInfo({ id: projectId, membersIds }));
  };

  return isLoading ? (
    <Preloader />
  ) : (
    <EditProjectMembersForm
      organizationMembers={members}
      projectId={currentProject.id}
      currentMembers={currentProject.members}
      onSubmit={handleSubmit}
    />
  );
};

export default EditProjectMembers;
