import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import Preloader from 'components/shared/Preloader';
import { logout } from 'features/user/userActions';
import {
  isLoadingUserSelector,
} from 'features/user/userSlice';

const OIDCSignOutCallback = () => {
  const isLoading = useSelector(isLoadingUserSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logout()); 
  }, []);

  return (
    <>
      {isLoading && <Preloader />}
    </>
  );
};

export default OIDCSignOutCallback;
