import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import { filterTimeOffRequestsSelector } from 'features/timeOffRequestsList/timeOffRequestsListSlice';
import { permissionsUserSelector } from 'features/user/userSlice';
import {
  ITimeOffRequestsActionsParams,
  TimeOffRequestStatuses,
} from 'features/timeOffRequestsList/timeOffRequestsListTypes';
import {
  approveTimeOffRequest,
  denyTimeOffRequest,
  resubmitTimeOffRequest,
} from 'features/timeOffRequestsList/timeOffRequestsListActions';

interface RequestsActionsListProps {
  requestId: number;
  status: keyof typeof TimeOffRequestStatuses;
  openViewModal: (id: number) => void;
  handleRemove: (params: ITimeOffRequestsActionsParams) => void;
}

const RequestsActionsList: FC<RequestsActionsListProps> = ({
  requestId,
  status,
  openViewModal,
  handleRemove,
}) => {
  const timeOffRequestsPageFilter = useSelector(filterTimeOffRequestsSelector);
  const permissions = useSelector(permissionsUserSelector);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const tabsPathFilter = pathname.replace('/time-off/requests', '');

  const isViewActionAvailable = !!permissions?.some(
    (el) => el.name === 'View TimeOffRequest'
  );
  const isEditActionAvailable = !!permissions?.some(
    (el) => el.name === 'Update TimeOffRequest'
  );
  const isResubmitActionAvailable = !!permissions?.some(
    (el) => el.name === 'Resubmit TimeOffRequest'
  );
  const isDeleteActionAvailable = !!permissions?.some(
    (el) => el.name === 'Delete TimeOffRequest'
  );
  const isApproveActionAvailable = !!permissions?.some(
    (el) => el.name === 'Approve TimeOffRequest'
  );
  const isDenyActionAvailable = !!permissions?.some(
    (el) => el.name === 'Deny TimeOffRequest'
  );

  const actionsParam: ITimeOffRequestsActionsParams = {
    requestId,
    filter: {
      ...timeOffRequestsPageFilter,
      path: tabsPathFilter,
      skip: 0,
      take: 20,
    },
  };

  const handleDeleteRequest = () => {
    handleRemove(actionsParam);
  };
  const handleApproveRequest = () => {
    dispatch(approveTimeOffRequest(actionsParam));
  };
  const handleDenyRequest = () => {
    dispatch(denyTimeOffRequest(actionsParam));
  };
  const handleResubmit = () => {
    dispatch(resubmitTimeOffRequest(actionsParam));
  };

  return (
    <>
      {isViewActionAvailable && (
        <li>
          <button onClick={() => openViewModal(requestId)} aria-label="View">
            View
          </button>
        </li>
      )}
      {(status === TimeOffRequestStatuses.Submitted ||
        status === TimeOffRequestStatuses.Denied) &&
        isEditActionAvailable && (
          <li>
            <Link to={`/time-off/requests/${requestId}/edit`}>Edit</Link>
          </li>
        )}
      {status === TimeOffRequestStatuses.Denied && isResubmitActionAvailable && (
        <li>
          <button onClick={handleResubmit} aria-label="Resubmit">
            Resubmit
          </button>
        </li>
      )}
      {status !== TimeOffRequestStatuses.Approved && isDeleteActionAvailable && (
        <li>
          <button onClick={handleDeleteRequest} aria-label="Delete">
            Delete
          </button>
        </li>
      )}
      {(status === TimeOffRequestStatuses.Submitted ||
        status === TimeOffRequestStatuses.Denied) &&
        isApproveActionAvailable && (
          <li>
            <button onClick={handleApproveRequest} aria-label="Approve">
              Approve
            </button>
          </li>
        )}
      {(status === TimeOffRequestStatuses.Approved ||
        status === TimeOffRequestStatuses.Submitted) &&
        isDenyActionAvailable && (
          <li>
            <button onClick={handleDenyRequest} aria-label="Deny">
              Deny
            </button>
          </li>
        )}
    </>
  );
};

export default RequestsActionsList;
