import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomFormikCheckbox from 'components/shared/forms/CustomFormikCheckbox';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import { SelectOption } from 'components/shared/forms/CustomFormikSelect/CustomFormikSelect';
import Button from 'components/shared/Button';
import { MaxLength50Symbols } from 'constants/formsConstants';
import { membersFilterListSelector } from 'features/membersFilterList/membersFilterListSlice';
import { IAddingPolicyData } from 'features/policiesList/policiesListTypes';
import styles from './AddTimeOffPolicyForm.module.scss';

interface AddTimeOffPolicyFormProps {
  handleCLose: () => void;
  handleSubmit: (data: IAddingPolicyData) => void;
}

const yesNoOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];
const paidOrUnpaidOptions = [
  { label: 'Paid', value: 'paid' },
  { label: 'Unpaid', value: 'unpaid' },
];

const AddTimeOffPolicyForm: FC<AddTimeOffPolicyFormProps> = ({
  handleCLose,
  handleSubmit,
}) => {
  const members = useSelector(membersFilterListSelector);

  const initialValues = {
    name: '',
    members: [] as SelectOption[],
    newMembersAutoAdding: false,
    requiresApproval: { label: 'No', value: 'no' },
    paidOrUnpaid: { label: 'Unpaid', value: 'unpaid' },
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(
        MaxLength50Symbols,
        `Maximum length ${MaxLength50Symbols} characters`
      )
      .required('Required field'),
  });

  const membersOptions = useMemo(() => {
    return members.map((member) => ({
      label: member.name,
      value: String(member.id),
    }));
  }, [members]);

  return (
    <>
      <div className={styles.wrapper}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(
            values: typeof initialValues,
            { setSubmitting, resetForm }
          ) => {
            handleSubmit({
              name: values.name,
              membersIds: values.members.map((member) => Number(member.value)),
              newMembersAutoAdding: values.newMembersAutoAdding,
              requiresApproval: values.requiresApproval.value !== 'no',
              paid: values.paidOrUnpaid.value !== 'unpaid',
            });
            setSubmitting(false);
            resetForm();
            handleCLose();
          }}
        >
          {(formik) => (
            <Form>
              <ValidationTextInput
                label="Policy name"
                name="name"
                type="text"
                placeholder="Enter a name of the policy"
              />

              <div className={styles.membersWrapper}>
                <CustomFormikSelect
                  label={'Members'}
                  name="members"
                  options={membersOptions}
                  isMulti
                />
                <span
                  onClick={() => {
                    formik.setFieldValue(
                      'members',
                      members.map((member) => ({
                        label: member.name,
                        value: String(member.id),
                      }))
                    );
                  }}
                >
                  Select all
                </span>
              </div>

              <CustomFormikCheckbox
                label="Automatically add new members to this policy"
                name="newMembersAutoAdding"
              />

              <div className={styles.yesNoWrapper}>
                <CustomFormikSelect
                  label={'Requires approval'}
                  name="requiresApproval"
                  options={yesNoOptions}
                />

                <CustomFormikSelect
                  label={'Paid or unpaid'}
                  name="paidOrUnpaid"
                  options={paidOrUnpaidOptions}
                />
              </div>

              <div className={styles.controlWrapper}>
                <Button
                  onClick={() => {
                    formik.resetForm();
                    handleCLose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={formik.isSubmitting}
                  preloader={formik.isSubmitting}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddTimeOffPolicyForm;
