import React, { useEffect, useRef, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { useSelector } from 'react-redux';
import {
  createSearchParams,
  URLSearchParamsInit,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { SelectOption } from 'components/shared/forms/AutocompleteSelect/AutocompleteSelect';
import DropdownSelect from 'components/shared/forms/DropdownSelect';
import { ITimeAndActivityReportsURLSearchParams } from 'features/timeAndActivityReports/timeAndActivityReportsTypes';
import { clientsFilterListSelector } from 'features/clientsFilterList/clientsFilterListSlice';

const ClientsFilter = () => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const clients = useSelector(clientsFilterListSelector);
  const isMountedRef = useRef(false);

  const clientsQuery = searchParams.getAll('clientsIds');

  const clientsFromQueries = clients.filter((client) =>
    clientsQuery.includes(String(client.id))
  );

  const allClientsOptions = clients.map((client) => ({
    label: client.name,
    value: String(client.id),
  }));

  const [clientsOptions, setClientsOptions] =
    useState<MultiValue<SelectOption> | null>(null);

  const handleClientsOptionsChange = (
    option: SingleValue<SelectOption> | MultiValue<SelectOption>
  ) => {
    setClientsOptions(option as MultiValue<SelectOption>);
  };

  useEffect(() => {
    if (clientsFromQueries && clientsFromQueries.length > 0) {
      setClientsOptions(
        clientsFromQueries.map((client) => ({
          label: client.name,
          value: String(client.id),
        }))
      );
    }
  }, [clients]);

  useEffect(() => {
    if (isMountedRef.current) {
      const params = Object.fromEntries([...Array.from(searchParams)]);
      const URLSearchParams: ITimeAndActivityReportsURLSearchParams = {
        ...params,
        columns: searchParams.getAll('columns'),
        projectsIds: searchParams.getAll('projectsIds'),
        membersIds: searchParams.getAll('membersIds'),
      };

      if (clientsOptions && clientsOptions.length > 0) {
        URLSearchParams.clientsIds = clientsOptions.map(
          (option) => option.value
        );
      } else {
        delete URLSearchParams.clientsIds;
      }

      navigate({
        pathname: pathname,
        search: createSearchParams(
          URLSearchParams as URLSearchParamsInit
        ).toString(),
      });
    }
    isMountedRef.current = true;
  }, [clientsOptions]);

  return (
    <>
      <DropdownSelect
        label="Clients"
        value={clientsOptions}
        options={allClientsOptions}
        onChange={(value) => {
          handleClientsOptionsChange(value);
        }}
        isSearchable
        blurInputOnSelect={false}
        isMulti
      />
    </>
  );
};

export default ClientsFilter;
