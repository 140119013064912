import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/store';
import {
  downloadTimeAndActivityReports,
  fetchTimeAndActivityReports,
} from 'api/timeAndActivityReports';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import {
  IGetTimeAndActivityReportsParams,
  ITimeAndActivityReportsData,
  TimeAndActivityReportsParamsForDownloading,
} from './timeAndActivityReportsTypes';
import { setIsReportDownloadingState } from './timeAndActivityReportsSlice';

export const getTimeAndActivityReports = createAsyncThunk<
  ITimeAndActivityReportsData,
  IGetTimeAndActivityReportsParams,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@timeAndActivity/getTimeAndActivityReports', async (params, thunkApi) => {
  try {
    const res = await fetchTimeAndActivityReports(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const exportTimeAndActivityReports = createAsyncThunk<
  void,
  TimeAndActivityReportsParamsForDownloading,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>(
  '@@timeAndActivity/exportTimeAndActivityReports',
  async (params, thunkApi) => {
    try {
      thunkApi.dispatch(setIsReportDownloadingState(true));
      await downloadTimeAndActivityReports(params);
      thunkApi.dispatch(setIsReportDownloadingState(false));
    } catch (err) {
      return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
    }
  }
);
