import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { IRequestTimeOffFormValues } from './RequestTimeOffForm';
import { ValidationTimeMaskInputProps } from '../../shared/forms/ValidationTimeMaskInput/ValidationTimeMaskInput';
import { normalizeNumberToTimeHoursFormat } from 'helpers/normalizeNumberToTimeHoursFormat';
import { hoursColonMinutesFormat } from 'helpers/regularExpressions';
import { daysCountInDateRange } from 'helpers/daysCountInDateRange';
import styles from '../../shared/forms/ValidationTimeMaskInput/ValidationTimeMaskInput.module.scss';

const workHoursPerDay = 8;

const DependentTimeRequestedField: FC<
  ValidationTimeMaskInputProps & FieldHookConfig<string>
> = ({ label, ...props }) => {
  const firstRenderRef = useRef(false);
  const [field, meta, { setTouched }] = useField(props);
  const [time, setTime] = useState(field.value || '');
  const {
    values: { startDate, endDate, allDay },
    setFieldValue,
  } = useFormikContext<IRequestTimeOffFormValues>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputTime = event.target.value;
    if (hoursColonMinutesFormat.test(inputTime)) {
      setTime(inputTime);
    }
  };

  const handleBlur = () => {
    const hoursAndMinutesArray = time.split(':');
    const [stringHours, stringMinutes] = hoursAndMinutesArray;
    const numHours = parseInt(stringHours, 10) || 0;
    let numMinutes = parseInt(stringMinutes, 10) || 0;

    if (hoursAndMinutesArray.length < 2) {
      numMinutes = 0;
    } else if (hoursAndMinutesArray.length === 2 && numMinutes > 59) {
      numMinutes = 0;
    }

    setTime(
      `${normalizeNumberToTimeHoursFormat(
        numHours
      )}:${normalizeNumberToTimeHoursFormat(numMinutes)}`
    );

    setTouched(true);
  };

  useEffect(() => {
    setFieldValue(field.name, time);
  }, [time]);

  useEffect(() => {
    if (allDay && firstRenderRef.current) {
      setTime(
        `${normalizeNumberToTimeHoursFormat(
          daysCountInDateRange(startDate, endDate) * workHoursPerDay
        )}:${normalizeNumberToTimeHoursFormat(0)}`
      );
    }
    firstRenderRef.current = true;
  }, [startDate, endDate, allDay]);

  return (
    <div>
      {label && (
        <label className="input-label" htmlFor={props.id || props.name}>
          {label}
        </label>
      )}
      <div className={styles.root}>
        <input
          className={`form-control ${
            meta.touched && meta.error ? 'errorInput' : ''
          }`}
          {...field}
          {...props}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="off"
        />
        {meta.touched && meta.error ? (
          <div className={styles.errorMessage}>*{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};

export default DependentTimeRequestedField;
