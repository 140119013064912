import React, { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Preloader from 'components/shared/Preloader';
import { selectInfoUserSelector } from 'features/user/userSlice';
import { USER_ROLE } from 'features/user/userTypes';

interface PageGuardProps {
  children: ReactNode;
}

const AdminAndManagerPageGuard: FC<PageGuardProps> = ({ children }) => {
  const userInfo = useSelector(selectInfoUserSelector);

  return (
    <>
      {!userInfo ? (
        <Preloader />
      ) : userInfo.role === USER_ROLE.User ||
        userInfo.role === USER_ROLE.Viewer ? (
        <Navigate to={'/'} replace />
      ) : (
        children
      )}
    </>
  );
};

export default AdminAndManagerPageGuard;
