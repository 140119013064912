import React, { useEffect, useRef, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { useSelector } from 'react-redux';
import {
  createSearchParams,
  URLSearchParamsInit,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { SelectOption } from 'components/shared/forms/AutocompleteSelect/AutocompleteSelect';
import DropdownSelect from 'components/shared/forms/DropdownSelect';
import { IAmountsOwedReportsURLSearchParams } from 'features/amountsOwedReports/amountsOwedReportsTypes';
import { membersFilterListSelector } from 'features/membersFilterList/membersFilterListSlice';

const MembersFilter = () => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const members = useSelector(membersFilterListSelector);
  const isMountedRef = useRef(false);

  const membersQuery = searchParams.getAll('membersIds');
  const membersFromQueries = members.filter((member) =>
    membersQuery.includes(String(member.id))
  );
  const allMembersOptions = members.map((member) => ({
    label: member.name,
    value: String(member.id),
  }));

  const [membersOptions, setMembersOptions] =
    useState<MultiValue<SelectOption> | null>(null);

  const handleMembersOptionsChange = (
    option: SingleValue<SelectOption> | MultiValue<SelectOption>
  ) => {
    setMembersOptions(option as MultiValue<SelectOption>);
  };

  useEffect(() => {
    if (membersFromQueries && membersFromQueries.length > 0) {
      setMembersOptions(
        membersFromQueries.map((member) => ({
          label: member.name,
          value: String(member.id),
        }))
      );
    }
  }, [members]);
  useEffect(() => {
    if (isMountedRef.current) {
      const params = Object.fromEntries([...Array.from(searchParams)]);
      const URLSearchParams: IAmountsOwedReportsURLSearchParams = {
        ...params,
        projectsIds: searchParams.getAll('projectsIds'),
      };

      if (membersOptions && membersOptions.length > 0) {
        URLSearchParams.membersIds = membersOptions.map(
          (option) => option.value
        );
      } else {
        delete URLSearchParams.membersIds;
      }

      navigate({
        pathname: pathname,
        search: createSearchParams(
          URLSearchParams as URLSearchParamsInit
        ).toString(),
      });
    }
    isMountedRef.current = true;
  }, [membersOptions]);

  return (
    <>
      <DropdownSelect
        label="Members"
        value={membersOptions}
        options={allMembersOptions}
        onChange={(value) => {
          handleMembersOptionsChange(value);
        }}
        isSearchable
        blurInputOnSelect={false}
        isMulti
      />
    </>
  );
};

export default MembersFilter;
