import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/store';
import { fetchProjectsFilterList } from 'api/projectsFilterList';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import { IProjectShortInfo } from '../projectInfo/projectInfoTypes';

export const getProjectsFilterList = createAsyncThunk<
  { list: IProjectShortInfo[]; count: number },
  void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@projectsFilterList/getProjectsFilterList', async (_, thunkApi) => {
  try {
    const res = await fetchProjectsFilterList();
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
