import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import AutoSizer from 'react-virtualized-auto-sizer';
import PulseLoader from 'react-spinners/PulseLoader';
import { useAppDispatch } from 'app/store';
import ActiveMembersActionsList from 'components/members/ActiveMembersActionsList';
import ActionsPopover from 'components/shared/ActionsPopover';
import ActivityBadge from 'components/shared/ActivityBadge';
import ConfirmDialog from 'components/shared/ConfirmDialog';
import {
  IMembersListItem,
  MembersTableHeadings,
} from 'features/members/membersTypes';
import {
  getMembersInfiniteScroll,
  removeMember,
} from 'features/members/membersActions';
import {
  filterMembersSelector,
  isLoadingMembersSelector,
  itemsTotalCountMembersSelector,
  listMembersSelector,
} from 'features/members/membersSlice';
import { numToCurrencyWithLocale } from 'helpers/numToCurrencyWithLocale';
import styles from 'components/shared/tables/infiniteScrollTable.module.scss';

const membersTableColumns: Record<'name', MembersTableHeadings>[] = [
  { name: 'Name' },
  { name: 'Role' },
  { name: 'About' },
  { name: 'Projects' },
  { name: 'Payment' },
  { name: 'Status' },
  { name: 'Actions' },
];

const ActiveMembersTable: FC = () => {
  const membersTotalCount = useSelector(itemsTotalCountMembersSelector);
  const isMembersLoading = useSelector(isLoadingMembersSelector);
  const membersPageFilter = useSelector(filterMembersSelector);
  const infiniteListItems = useSelector(listMembersSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [memberIdForDelete, setMemberIdForDelete] = useState<number | null>(
    null
  );
  const [confirmRemoveMember, setConfirmRemoveMember] = useState(false);
  const handleRemove = (id: number) => {
    setMemberIdForDelete(id);
    setConfirmRemoveMember(true);
  };
  const hasNextPage = infiniteListItems.length < membersTotalCount;
  const loadNextPage = async () => {
    dispatch(
      getMembersInfiniteScroll({
        ...membersPageFilter,
        skip: infiniteListItems.length,
        take: 20,
      })
    );
  };
  const itemCount = hasNextPage
    ? infiniteListItems.length + 1
    : infiniteListItems.length;
  const loadMoreItems = isMembersLoading ? () => {} : loadNextPage;
  const isItemLoaded = (index: number) =>
    !hasNextPage || index < infiniteListItems.length;

  const MembersTableItem: FC<ListChildComponentProps<IMembersListItem[]>> = (
    props
  ) => {
    const content = infiniteListItems[props.index];

    if (!isItemLoaded(props.index))
      return (
        <div style={props.style} className={styles.loadNextTableIndicator}>
          <PulseLoader
            color="#0168fa"
            size={8}
            margin={5}
            speedMultiplier={1}
          />
        </div>
      );

    return (
      <div style={props.style}>
        <div
          className={`${styles.fRow} ${styles.fBodyRow} ${
            content.isDeleted ? styles.disabledRow : ''
          }`}
        >
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={() => navigate(`/members/${content.id}/edit`)}
          >
            <span className={`${styles.fBodyCellContent}`}>
              {content.fullName}
            </span>
          </div>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={() => navigate(`/members/${content.id}/edit/roles`)}
          >
            <span className={`${styles.fBodyCellContent}`}>{content.role}</span>
          </div>
          <div
            className={`${styles.fCell} ${styles.fBodyCell} ${styles.membersTableAboutCell}`}
          >
            <span
              id={content.id + 'email'}
              className={`${styles.fBodyCellContent}`}
              data-tooltip-content={content.email}
            >
              {content.email}
            </span>
            <ReactTooltip
              anchorId={content.id + 'email'}
              style={{ zIndex: 1 }}
            />
            <span className={`${styles.fBodyCellContent}`}>
              {content.timeZone}
            </span>
          </div>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={() => navigate(`/members/${content.id}/edit/projects`)}
          >
            <span
              className={`${styles.fBodyCellContent} ${
                content.projects.length === 0 && styles.noDataCellContent
              }`}
            >
              {' '}
              {content.projects.length > 0 ? (
                content.projects.length !== 1 ? (
                  <div className={styles.fewMembersWrapper}>
                    <div>{content.projects[0].name}</div>
                    <div className={styles.remainingMembers}>
                      +{String(content.projects.length - 1)}
                    </div>
                  </div>
                ) : (
                  content.projects[0].name
                )
              ) : (
                'No projects'
              )}
            </span>
          </div>
          <div
            className={`${styles.fCell} ${styles.fBodyCell} ${styles.membersTablePaymentsCell}`}
            onClick={() => navigate(`/members/${content.id}/edit/payments`)}
          >
            <span
              className={`${styles.fBodyCellContent} ${
                +content.payAmount <= 0 && styles.noDataCellContent
              }`}
            >
              {+content.payAmount > 0
                ? `Pay rate: ${numToCurrencyWithLocale(
                    Number(content.payAmount),
                    'USD',
                    'en-US'
                  )}`
                : 'No Pay Rate'}
            </span>
            <span
              className={`${styles.fBodyCellContent} ${
                +content.payAmount <= 0 && styles.noDataCellContent
              }`}
            >
              {+content.billRate > 0
                ? `Bill rate: ${numToCurrencyWithLocale(
                    Number(content.billRate),
                    'USD',
                    'en-US'
                  )}`
                : 'No Bill Rate'}
            </span>
            <span className={`${styles.fBodyCellContent}`}>
              {content.payType}
            </span>
          </div>
          <div className={`${styles.fCell} ${styles.fBodyCell}`}>
            <span className={`${styles.fBodyCellContent}`}>
              <ActivityBadge outlined isActive={content.status} />
            </span>
          </div>
          <div className={`${styles.fCell} ${styles.fBodyCell}`}>
            <ActionsPopover>
              <ActiveMembersActionsList
                memberId={content.id}
                isActive={content.status}
                handleRemove={handleRemove}
              />
            </ActionsPopover>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.fTable}>
        <div className={`${styles.fRow} ${styles.fHeaderRow}`}>
          {membersTableColumns.map((col) => (
            <div
              key={col.name}
              className={`${styles.fCell} ${styles.fHeaderCell} ${
                col.name === 'Payment' && styles.membersTablePaymentsCell
              }`}
            >
              {col.name}
            </div>
          ))}
        </div>
        <div className={styles.fTableBody}>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    itemCount={itemCount}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    height={height}
                    overscanCount={1}
                    itemSize={70}
                    width={width}
                  >
                    {MembersTableItem}
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </div>
      </div>
      {memberIdForDelete && (
        <ConfirmDialog
          open={confirmRemoveMember}
          title="Confirm"
          description="Remove this member? You will not be able to undo the changes."
          acceptBtnText="Remove"
          declineBtnText="Cancel"
          onClose={() => setConfirmRemoveMember(false)}
          onAccept={() => {
            dispatch(removeMember(memberIdForDelete));
          }}
        />
      )}
    </>
  );
};

export default ActiveMembersTable;
