import client from './axiosInstance';
import {
  IAddingPolicyData,
  IFetchPoliciesParams,
} from 'features/policiesList/policiesListTypes';

export const fetchPoliciesList = async (
  params: IFetchPoliciesParams | void
) => {
  if (params) {
    const { searchValue, skip, take } = params;
    const searchStringQuery =
      searchValue && searchValue.length > 0 ? `search=${searchValue}` : '';
    const skipQuery = `${
      searchValue && searchValue.length > 0 ? '&' : ''
    }skip=${skip}`;
    const takeQuery = `&take=${take}`;
    const queryStr = `?${searchStringQuery}${skipQuery}${takeQuery}`;
    return await client.get(`/time-off/policies${queryStr}`);
  } else {
    return await client.get(`/time-off/policies`);
  }
};

export const fetchPoliciesListByMemberId = async (memberId: number) => {
  return await client.get(`/time-off/policies?search=${memberId}`);
};

export const addNewPolicy = async (data: IAddingPolicyData) => {
  return await client.post(`/time-off/policies`, {
    ...data,
  });
};

export const deletePolicy = async (policyId: number) => {
  return await client.delete(`/time-off/policies/${policyId}`);
};
