import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import PulseLoader from 'react-spinners/PulseLoader';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { useAppDispatch } from 'app/store';
import ActionsPopover from 'components/shared/ActionsPopover';
import ConfirmDialog from 'components/shared/ConfirmDialog';
import Modal from 'components/shared/Modal';
import { IPoliciesListItem } from 'features/policiesList/policiesListTypes';
import {
  filterPoliciesListSelector,
  itemsTotalCountPoliciesListSelector,
  loadingPoliciesListSelector,
  policiesListSelector,
} from 'features/policiesList/policiesListSlice';
import {
  getPoliciesListInfiniteScroll,
  removePolicy,
} from 'features/policiesList/policiesListActions';
import PoliciesActionsList from '../PoliciesActionsList';
import styles from 'components/shared/tables/infiniteScrollTable.module.scss';

const policiesTableColumns = [
  { name: 'Policy name' },
  { name: 'Members' },
  { name: 'Payment' },
  { name: 'Actions' },
];

const PoliciesTable: FC = () => {
  const policiesTotalCount = useSelector(itemsTotalCountPoliciesListSelector);
  const isPoliciesLoading = useSelector(loadingPoliciesListSelector);
  const policiesPageFilter = useSelector(filterPoliciesListSelector);
  const infiniteListItems = useSelector(policiesListSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [policyIdForDelete, setPolicyIdForDelete] = useState<
    number | undefined
  >(undefined);
  const [confirmRemovePolicy, setConfirmRemovePolicy] = useState(false);
  const [policyRemovingBan, setPolicyRemovingBan] = useState(false);
  const [
    confirmRemovePolicyWithUnapprovedRequest,
    setConfirmRemovePolicyWithUnapprovedRequest,
  ] = useState(false);
  const handleRemove = (
    id: number,
    hasApproved: boolean,
    hasUnapproved: boolean
  ) => {
    if (hasApproved) {
      setPolicyRemovingBan(true);
    } else if (hasUnapproved) {
      setPolicyIdForDelete(id);
      setConfirmRemovePolicyWithUnapprovedRequest(true);
    } else {
      setPolicyIdForDelete(id);
      setConfirmRemovePolicy(true);
    }
  };

  const hasNextPage = infiniteListItems.length < policiesTotalCount;
  const loadNextPage = async () => {
    dispatch(
      getPoliciesListInfiniteScroll({
        ...policiesPageFilter,
        skip: infiniteListItems.length,
        take: 20,
      })
    );
  };
  const itemCount = hasNextPage
    ? infiniteListItems.length + 1
    : infiniteListItems.length;
  const loadMoreItems = isPoliciesLoading ? () => {} : loadNextPage;
  const isItemLoaded = (index: number) =>
    !hasNextPage || index < infiniteListItems.length;

  const PoliciesTableItem: FC<ListChildComponentProps<IPoliciesListItem[]>> = (
    props
  ) => {
    const content = infiniteListItems[props.index];

    if (!isItemLoaded(props.index))
      return (
        <div style={props.style} className={styles.loadNextTableIndicator}>
          <PulseLoader
            color="#0168fa"
            size={8}
            margin={5}
            speedMultiplier={1}
          />
        </div>
      );

    return (
      <div style={props.style}>
        <div className={`${styles.fRow} ${styles.fBodyRow}`}>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={() => navigate(`/time-off/policies/${content.id}/edit`)}
          >
            <span className={`${styles.fBodyCellContent}`}>{content.name}</span>
          </div>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={() =>
              navigate(`/time-off/policies/${content.id}/edit/members`)
            }
          >
            <span
              className={`${styles.fBodyCellContent} ${
                +content.members.length <= 0 && styles.noDataCellContent
              }`}
            >
              {content.members.length > 0 ? (
                content.members.length !== 1 ? (
                  <div className={styles.fewMembersWrapper}>
                    <div>{content.members[0].name}</div>
                    <div className={styles.remainingMembers}>
                      +{String(content.members.length - 1)}
                    </div>
                  </div>
                ) : (
                  content.members[0].name
                )
              ) : (
                'No members'
              )}
            </span>
          </div>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={() => navigate(`/time-off/policies/${content.id}/edit`)}
          >
            <span className={`${styles.fBodyCellContent}`}>
              {content.paid ? 'Paid' : 'Unpaid'}
            </span>
          </div>

          <div className={`${styles.fCell} ${styles.fBodyCell}`}>
            <ActionsPopover>
              <PoliciesActionsList
                policyId={content.id}
                hasApproved={content.hasApproved}
                hasUnapproved={content.hasUnapproved}
                handleRemove={handleRemove}
              />
            </ActionsPopover>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.fTable}>
        <div className={`${styles.fRow} ${styles.fHeaderRow}`}>
          {policiesTableColumns.map((col) => (
            <div
              key={col.name}
              className={`${styles.fCell} ${styles.fHeaderCell}`}
            >
              {col.name}
            </div>
          ))}
        </div>
        <div className={styles.fTableBody}>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    itemCount={itemCount}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    height={height}
                    overscanCount={1}
                    itemSize={70}
                    width={width}
                  >
                    {PoliciesTableItem}
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </div>
      </div>
      {policyIdForDelete && (
        <ConfirmDialog
          open={confirmRemovePolicy}
          title="Confirm"
          description="Delete this time off policy?"
          acceptBtnText="Delete"
          declineBtnText="Cancel"
          onClose={() => setConfirmRemovePolicy(false)}
          onAccept={() => {
            dispatch(removePolicy(policyIdForDelete));
          }}
        />
      )}
      {policyIdForDelete && (
        <ConfirmDialog
          open={confirmRemovePolicyWithUnapprovedRequest}
          title="Confirm"
          description="There are unapproved time off requests using this policy. Are you sure you want to delete it?"
          acceptBtnText="Delete"
          declineBtnText="Cancel"
          onClose={() => setConfirmRemovePolicyWithUnapprovedRequest(false)}
          onAccept={() => {
            dispatch(removePolicy(policyIdForDelete));
          }}
        />
      )}
      <Modal
        open={policyRemovingBan}
        onClose={() => setPolicyRemovingBan(false)}
        title="Forbidden"
      >
        Policy cannot be deleted because some members have time off requests
        that have already been approved.
      </Modal>
    </>
  );
};

export default PoliciesTable;
