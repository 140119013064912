import { formatHoursAndMinutesToTimeFormat } from './formatHoursAndMinutesToTimeFormat';
import { zonedTimeToUtc } from 'date-fns-tz';

interface TimeRangeItem {
  start: string | Date;
  end: string | Date;
}
export const sumTimeRanges = (
  arr: TimeRangeItem[],
  calendarStart: string,
  calendarEnd: string
) => {
  const startDate = zonedTimeToUtc(calendarStart, 'Etc/UTC');
  const endDate = zonedTimeToUtc(calendarEnd, 'Etc/UTC');

  const totalMinutes = arr.reduce((acc, obj) => {
    const startTime = zonedTimeToUtc(obj.start, 'Etc/UTC');
    const endTime = zonedTimeToUtc(obj.end, 'Etc/UTC');

    const timeDifferenceMs =
      Math.min(endTime.getTime(), endDate.getTime()) -
      Math.max(startTime.getTime(), startDate.getTime());

    if (timeDifferenceMs > 0) {
      return acc + Math.floor(timeDifferenceMs / 60000);
    } else {
      return acc;
    }
  }, 0);

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return formatHoursAndMinutesToTimeFormat(hours, minutes);
};
