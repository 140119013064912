import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  changeMemberActivityStatus,
  fetchOrganizationMembersList,
  removeMemberById,
} from 'api/membersList';
import { AppDispatch } from 'app/store';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import {
  ChangeMemberActivityStatusParams,
  IFetchMembersParams,
  IMembersListItem,
} from './membersTypes';
import { changeMemberStatusReducer, removeMemberReducer } from './membersSlice';

export const getMembers = createAsyncThunk<
  { list: IMembersListItem[]; count: number },
  IFetchMembersParams | void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@membersList/getMembers', async (params, thunkApi) => {
  try {
    const res = await fetchOrganizationMembersList(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const getMembersInfiniteScroll = createAsyncThunk<
  { list: IMembersListItem[]; count: number },
  IFetchMembersParams | void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@membersList/getMembersInfiniteScroll', async (params, thunkApi) => {
  try {
    const res = await fetchOrganizationMembersList(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const changeMemberStatus = createAsyncThunk<
  void,
  ChangeMemberActivityStatusParams,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@membersList/changeMemberStatus', async (params, thunkApi) => {
  try {
    const res = await changeMemberActivityStatus(params);
    if (res.status === 204) {
      toast.success('Status changed.');
      thunkApi.dispatch(changeMemberStatusReducer(params));
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const removeMember = createAsyncThunk<
  void,
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@membersList/removeMember', async (memberId, thunkApi) => {
  try {
    const res = await removeMemberById(memberId);
    if (res.status === 204) {
      toast.success('Member removed.');
      thunkApi.dispatch(removeMemberReducer(memberId));
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
