import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { isRejectedAction } from '../sliceHelpers';
import { IProjectsFilterListSliceInitialState } from './projectsFilterListTypes';
import { getProjectsFilterList } from './projectsFilterListActions';

const initialState: IProjectsFilterListSliceInitialState = {
  list: [],
  isLoading: false,
  errorMessage: '',
};

export const projectsFilterListSlice = createSlice({
  name: '@@projectsFilterList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjectsFilterList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProjectsFilterList.fulfilled, (state, action) => {
      state.list = action.payload.list;
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const projectsFilterListSelector = createSelector(
  [(state: RootState) => state.projectsFilterList.list],
  (list) => list
);
