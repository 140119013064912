import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useSelector } from 'react-redux';
import { SingleValue } from 'react-select';
import { useAppDispatch } from 'app/store';
import TimeZoneDifferenceBanner from 'components/timeOff/TimeZoneDifferenceBanner';
import { ISelectOption } from 'components/shared/forms/DropdownSelect/DropdownSelect';
import CustomDatePicker from 'components/shared/forms/datePickers/CustomDatePicker';
import CustomTimePicker from 'components/shared/forms/timePickers/CustomTimePicker';
import DropdownSelect from 'components/shared/forms/DropdownSelect';
import CustomCheckbox from 'components/shared/forms/CustomCheckbox';
import TextArea from 'components/shared/forms/textAreas/TextArea';
import Button from 'components/shared/Button';
import { MaximumCommentLengthValidationError } from 'constants/formsConstants';
import { isLoadingTimeEntriesSelector } from 'features/timeEntries/timeEntriesSlice';
import { selectInfoUserSelector } from 'features/user/userSlice';
import { AddTimeFormState } from 'features/timesheets/timesheetsTypes';
import { addTimeEntry } from 'features/timeEntries/timeEntriesActions';
import {
  memberInfoTimesheetsSelector,
  setAddTimeFormState,
} from 'features/timesheets/timesheetsSlice';
import { checkIsNextDay } from 'helpers/checkIsNextDay';
import { setTimeInDate } from 'helpers/setTimeInDate';
import { checkIsFuture } from 'helpers/checkIsFuture';
import { checkIsToday } from 'helpers/checkIsToday';
import styles from './AddTimeForm.module.scss';

interface AddTimeFormNewProps {
  data: AddTimeFormState;
  handleClose: () => void;
}

const AddTimeForm: FC<AddTimeFormNewProps> = ({ data, handleClose }) => {
  const isTimeEntriesLoading = useSelector(isLoadingTimeEntriesSelector);
  const selectedMemberInfo = useSelector(memberInfoTimesheetsSelector);
  const userInfo = useSelector(selectInfoUserSelector);
  const dispatch = useAppDispatch();
  const [isNextDay, setIsNextDay] = useState(false);
  const [isFuture, setIsFuture] = useState(false);
  const [isProjectFieldTouched, setIsProjectFieldTouched] = useState(false);
  const isToday = checkIsToday(
    data.timeTrackingDate,
    data.selectedTimeZoneName
  );

  const handleProjectChange = (project: SingleValue<ISelectOption>) => {
    dispatch(
      setAddTimeFormState({
        ...data,
        project,
        isProjectSelected: true,
      })
    );
  };
  const handleTimeTrackingDateChange = (timeTrackingDate: Date) => {
    dispatch(
      setAddTimeFormState({
        ...data,
        timeTrackingDate,
        startTime: setTimeInDate(
          timeTrackingDate,
          data.startTime.getHours(),
          data.startTime.getMinutes()
        ),
        endTime: setTimeInDate(
          timeTrackingDate,
          isNextDay ? data.endTime.getHours() + 24 : data.endTime.getHours(),
          data.endTime.getMinutes()
        ),
      })
    );
  };

  const handleStartTimeChange = (inputStartTime: Date) => {
    if (
      // IS NEXT DAY BY TIME COMPARISON
      inputStartTime.getHours() > data.endTime.getHours() ||
      (inputStartTime.getHours() === data.endTime.getHours() &&
        inputStartTime.getMinutes() >= data.endTime.getMinutes())
    ) {
      dispatch(
        setAddTimeFormState({
          ...data,
          startTime: setTimeInDate(
            data.timeTrackingDate,
            inputStartTime.getHours(),
            inputStartTime.getMinutes()
          ),
          endTime: setTimeInDate(
            data.timeTrackingDate,
            data.endTime.getHours() + 24,
            data.endTime.getMinutes()
          ),
        })
      );
    } else {
      dispatch(
        setAddTimeFormState({
          ...data,
          startTime: setTimeInDate(
            data.timeTrackingDate,
            inputStartTime.getHours(),
            inputStartTime.getMinutes()
          ),
          endTime: setTimeInDate(
            data.timeTrackingDate,
            data.endTime.getHours(),
            data.endTime.getMinutes()
          ),
        })
      );
    }
  };
  const handleEndTimeChange = (inputEndTime: Date) => {
    if (
      // IS NEXT DAY BY TIME COMPARISON
      inputEndTime.getHours() < data.startTime.getHours() ||
      (inputEndTime.getHours() === data.startTime.getHours() &&
        inputEndTime.getMinutes() <= data.startTime.getMinutes())
    ) {
      dispatch(
        setAddTimeFormState({
          ...data,
          endTime: setTimeInDate(
            data.timeTrackingDate,
            inputEndTime.getHours() + 24,
            inputEndTime.getMinutes()
          ),
        })
      );
    } else {
      dispatch(
        setAddTimeFormState({
          ...data,
          endTime: setTimeInDate(
            data.timeTrackingDate,
            inputEndTime.getHours(),
            inputEndTime.getMinutes()
          ),
        })
      );
    }
  };
  const toggleBillable = () => {
    dispatch(setAddTimeFormState({ ...data, isBillable: !data.isBillable }));
  };
  const handleNotesChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      setAddTimeFormState({
        ...data,
        notes: e.target.value,
        commentsLengthError:
          e.target.value.length > MaximumCommentLengthValidationError,
      })
    );
  };
  const handleSubmit = () => {
    dispatch(
      addTimeEntry({
        memberId: Number(selectedMemberInfo.id),
        projectId: Number(data.project?.value),
        start: zonedTimeToUtc(
          data.startTime,
          data.selectedTimeZoneName
        ).toISOString(),
        end: zonedTimeToUtc(
          data.endTime,
          data.selectedTimeZoneName
        ).toISOString(),
        isBillable: data.isBillable,
        note: data.notes,
      })
    );

    handleClose();
  };
  const projectsOptions = selectedMemberInfo.projects.map((p) => ({
    value: String(p.id),
    label: p.name,
  }));
  const disableSubmitCondition =
    isFuture ||
    !data.isProjectSelected ||
    isTimeEntriesLoading ||
    data.commentsLengthError;

  useEffect(() => {
    setIsNextDay(checkIsNextDay(data.startTime, data.endTime));
  }, [data.startTime, data.endTime]);

  useEffect(() => {
    setIsFuture(checkIsFuture(data.endTime, data.selectedTimeZoneName));
  }, [data.endTime, data.selectedTimeZoneName]);

  useEffect(() => {
    if (selectedMemberInfo.projects.length === 1) {
      dispatch(
        setAddTimeFormState({
          ...data,
          project: {
            label: selectedMemberInfo.projects[0].name,
            value: String(selectedMemberInfo.projects[0].id),
          },
          isProjectSelected: true,
        })
      );
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.memberName}>{selectedMemberInfo.name}</div>

      <DropdownSelect
        label="Project*"
        value={data.project}
        options={projectsOptions}
        onChange={handleProjectChange}
        isSearchable={false}
        blurInputOnSelect
        placeholder="Select a project"
        error={!data.isProjectSelected && isProjectFieldTouched}
        errorMessage="*Project should be selected"
        onBlur={() => setIsProjectFieldTouched(true)}
      />

      <div className={styles.trackerWrapper}>
        <div className={styles.dateWrapper}>
          <CustomDatePicker
            now={data.calendarNow}
            selected={data.timeTrackingDate}
            onChange={handleTimeTrackingDateChange}
            label={data.selectedTimeZoneName}
            calendarStartDay={userInfo?.firstDayOfWeek ?? 1}
          />
        </div>

        <div className={styles.timeWrapper}>
          <CustomTimePicker
            label="From"
            now={data.calendarNow}
            selected={data.startTime}
            onChange={handleStartTimeChange}
            isToday={isToday}
          />
          <CustomTimePicker
            label="To"
            now={data.calendarNow}
            selected={data.endTime}
            onChange={handleEndTimeChange}
            isToday={isToday}
            isNextDay={isNextDay}
            isFuture={isFuture || (isToday && isNextDay)}
          />
        </div>
      </div>

      <TimeZoneDifferenceBanner
        startTime={zonedTimeToUtc(data.startTime, data.selectedTimeZoneName)}
        endTime={zonedTimeToUtc(data.endTime, data.selectedTimeZoneName)}
        memberTimeZone={selectedMemberInfo.timeZone}
        selectedInTimeZoneFilterTimeZone={data.selectedTimeZoneName}
      />

      <div className={styles.billingWrapper}>
        <CustomCheckbox
          label="Billable"
          isChecked={data.isBillable}
          handleChange={toggleBillable}
        />
      </div>
      <TextArea
        label="Comments"
        value={data.notes}
        onChange={handleNotesChange}
        error={data.commentsLengthError}
        errorMessage={`*Maximum length ${MaximumCommentLengthValidationError} characters`}
      />
      <div className={styles.controlWrapper}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={disableSubmitCondition}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default AddTimeForm;
