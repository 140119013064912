import client from './axiosInstance';
import { IUpdateTimeOffRequestData } from '../features/timeOffRequestInfo/timeOffRequestInfoTypes';

export const fetchTimeOffRequestInfo = async (requestId: number) => {
  return await client.get(`/time-off/requests/${requestId}`);
};

export const updateTimeOffRequestInfo = async (
  data: IUpdateTimeOffRequestData
) => {
  return await client.patch(`/time-off/requests/${data.id}`, {
    ...data,
  });
};
