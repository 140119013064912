import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import EditTimeOffRequestGeneralForm from 'components/timeOff/EditTimeOffRequestGeneralForm';
import Preloader from 'components/shared/Preloader';
import BackTo from 'components/shared/BackTo';
import { IUpdateTimeOffRequestData } from 'features/timeOffRequestInfo/timeOffRequestInfoTypes';
import { getMembers } from 'features/members/membersActions';
import {
  isLoadingTimeOffRequestInfoSelector,
  timeOffRequestInfoSelector,
} from 'features/timeOffRequestInfo/timeOffRequeststInfoSlice';
import {
  editTimeOffRequest,
  getTimeOffRequestInfo,
} from 'features/timeOffRequestInfo/timeOffRequestInfoActions';

const EditTimeOffRequest: FC = () => {
  const requestIsLoading = useSelector(isLoadingTimeOffRequestInfoSelector);
  const request = useSelector(timeOffRequestInfoSelector);
  const dispatch = useAppDispatch();
  const { requestId } = useParams();

  const handleSubmit = (data: IUpdateTimeOffRequestData) => {
    dispatch(editTimeOffRequest(data));
  };

  useEffect(() => {
    requestId && dispatch(getTimeOffRequestInfo(Number(requestId)));
    dispatch(getMembers());
  }, []);

  return (
    <>
      <BackTo title="Requests" path={`/time-off/requests`} />
      {requestIsLoading ? (
        <Preloader />
      ) : (
        request && (
          <EditTimeOffRequestGeneralForm
            request={request}
            onSubmit={handleSubmit}
          />
        )
      )}
    </>
  );
};

export default EditTimeOffRequest;
