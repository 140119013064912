import React, { ReactNode } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import styles from './Button.module.scss';

type ButtonVariantType = 'primary' | 'delete';
interface ButtonProps
  extends React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    React.AriaAttributes {
  preloader?: boolean;
  icon?: ReactNode;
  children: string | ReactNode;
  variant?: ButtonVariantType;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ preloader, icon, children, variant, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={`${styles.root} ${
          variant ? styles[variant] : styles.default
        }`}
        {...props}
      >
        {(icon || preloader) && (
          <div className={styles.iconContainer}>{icon}</div>
        )}
        <div className={styles.title}>{children}</div>
        {(icon || preloader) && (
          <div className={`${styles.iconContainer}`}>
            {preloader && (
              <div className={styles.preloaderContainer}>
                <AiOutlineLoading3Quarters size="20px" />
              </div>
            )}
          </div>
        )}
      </button>
    );
  }
);

export default Button;
