import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useSelector } from 'react-redux';
import { SingleValue } from 'react-select';
import { useAppDispatch } from 'app/store';
import { TrashIcon } from 'assets/icons/TrashIcon';
import TimeZoneDifferenceBanner from 'components/timeOff/TimeZoneDifferenceBanner';
import { ISelectOption } from 'components/shared/forms/DropdownSelect/DropdownSelect';
import CustomDatePicker from 'components/shared/forms/datePickers/CustomDatePicker';
import CustomTimePicker from 'components/shared/forms/timePickers/CustomTimePicker';
import DropdownSelect from 'components/shared/forms/DropdownSelect';
import CustomCheckbox from 'components/shared/forms/CustomCheckbox';
import ConfirmDialog from 'components/shared/ConfirmDialog';
import TextArea from 'components/shared/forms/textAreas/TextArea';
import Button from 'components/shared/Button';
import { MaximumCommentLengthValidationError } from 'constants/formsConstants';
import { isLoadingTimeEntriesSelector } from 'features/timeEntries/timeEntriesSlice';
import { selectInfoUserSelector } from 'features/user/userSlice';
import { EditTimeFormState } from 'features/timesheets/timesheetsTypes';
import {
  changeTimeEntry,
  deleteTimeEntry,
} from 'features/timeEntries/timeEntriesActions';
import {
  memberInfoTimesheetsSelector,
  setEditTimeFormState,
} from 'features/timesheets/timesheetsSlice';
import { checkIsNextDay } from 'helpers/checkIsNextDay';
import { setTimeInDate } from 'helpers/setTimeInDate';
import { checkIsFuture } from 'helpers/checkIsFuture';
import { checkIsToday } from 'helpers/checkIsToday';
import styles from './EditTimeForm.module.scss';

interface AEditTimeFormNewProps {
  data: EditTimeFormState;
  handleClose: () => void;
}

const EditTimeForm: FC<AEditTimeFormNewProps> = ({ data, handleClose }) => {
  const isTimeEntriesLoading = useSelector(isLoadingTimeEntriesSelector);
  const selectedMemberInfo = useSelector(memberInfoTimesheetsSelector);
  const userInfo = useSelector(selectInfoUserSelector);
  const [confirmDeleteEntry, setConfirmDeleteEntry] = useState(false);
  const [isNextDay, setIsNextDay] = useState(false);
  const [isFuture, setIsFuture] = useState(false);
  const dispatch = useAppDispatch();
  const isToday = checkIsToday(
    data.timeTrackingDate,
    data.selectedTimeZoneName
  );

  const handleProjectChange = (project: SingleValue<ISelectOption>) => {
    dispatch(
      setEditTimeFormState({
        ...data,
        project,
        isProjectSelected: true,
      })
    );
  };
  const handleTimeTrackingDateChange = (timeTrackingDate: Date) => {
    dispatch(
      setEditTimeFormState({
        ...data,
        timeTrackingDate,
        startTime: setTimeInDate(
          timeTrackingDate,
          data.startTime.getHours(),
          data.startTime.getMinutes()
        ),
        endTime: setTimeInDate(
          timeTrackingDate,
          isNextDay ? data.endTime.getHours() + 24 : data.endTime.getHours(),
          data.endTime.getMinutes()
        ),
      })
    );
  };
  const handleStartTimeChange = (inputStartTime: Date) => {
    if (
      inputStartTime.getHours() > data.endTime.getHours() ||
      (inputStartTime.getHours() === data.endTime.getHours() &&
        inputStartTime.getMinutes() >= data.endTime.getMinutes())
    ) {
      dispatch(
        setEditTimeFormState({
          ...data,
          startTime: setTimeInDate(
            data.timeTrackingDate,
            inputStartTime.getHours(),
            inputStartTime.getMinutes()
          ),
          endTime: setTimeInDate(
            data.timeTrackingDate,
            data.endTime.getHours() + 24,
            data.endTime.getMinutes()
          ),
        })
      );
    } else {
      dispatch(
        setEditTimeFormState({
          ...data,
          startTime: setTimeInDate(
            data.timeTrackingDate,
            inputStartTime.getHours(),
            inputStartTime.getMinutes()
          ),
          endTime: setTimeInDate(
            data.timeTrackingDate,
            data.endTime.getHours(),
            data.endTime.getMinutes()
          ),
        })
      );
    }
  };
  const handleEndTimeChange = (inputEndTime: Date) => {
    if (
      // IS NEXT DAY BY TIME COMPARISON
      inputEndTime.getHours() < data.startTime.getHours() ||
      (inputEndTime.getHours() === data.startTime.getHours() &&
        inputEndTime.getMinutes() <= data.startTime.getMinutes())
    ) {
      dispatch(
        setEditTimeFormState({
          ...data,
          endTime: setTimeInDate(
            data.timeTrackingDate,
            inputEndTime.getHours() + 24,
            inputEndTime.getMinutes()
          ),
        })
      );
    } else {
      // not next day condition
      dispatch(
        setEditTimeFormState({
          ...data,
          startTime: data.startTime,
          endTime: setTimeInDate(
            data.timeTrackingDate,
            inputEndTime.getHours(),
            inputEndTime.getMinutes()
          ),
        })
      );
    }
  };
  const toggleBillable = () => {
    dispatch(setEditTimeFormState({ ...data, isBillable: !data.isBillable }));
  };
  const handleNotesChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(
      setEditTimeFormState({
        ...data,
        notes: e.target.value,
        commentsLengthError:
          e.target.value.length > MaximumCommentLengthValidationError,
      })
    );
  };
  const handleSubmit = () => {
    dispatch(
      changeTimeEntry({
        timeEntryId: data.timeEntryId,
        memberId: Number(selectedMemberInfo.id),
        projectId: Number(data.project?.value),
        start: zonedTimeToUtc(
          data.startTime,
          data.selectedTimeZoneName
        ).toISOString(),
        end: zonedTimeToUtc(
          data.endTime,
          data.selectedTimeZoneName
        ).toISOString(),
        timeZone: data.selectedTimeZoneName,
        isBillable: data.isBillable,
        note: data.notes,
      })
    );

    handleClose();
  };
  const handleDeleteTimeEntry = () => {
    dispatch(deleteTimeEntry(data.timeEntryId));
    setConfirmDeleteEntry(false);
    handleClose();
  };
  const projectsOptions = selectedMemberInfo.projects.map((p) => ({
    value: String(p.id),
    label: p.name,
  }));
  const disableSubmitCondition =
    isFuture ||
    !data.isProjectSelected ||
    isTimeEntriesLoading ||
    data.commentsLengthError;

  useEffect(() => {
    setIsNextDay(checkIsNextDay(data.startTime, data.endTime));
  }, [data.startTime, data.endTime]);

  useEffect(() => {
    setIsFuture(checkIsFuture(data.endTime, data.selectedTimeZoneName));
  }, [data.endTime, data.selectedTimeZoneName]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.memberName}>{selectedMemberInfo.name}</div>

      <DropdownSelect
        label="Project*"
        value={data.project}
        options={projectsOptions}
        onChange={handleProjectChange}
        isSearchable={false}
        blurInputOnSelect
        placeholder="Select a project"
        error={!data.isProjectSelected}
        errorMessage="*Project should be selected"
      />

      <div className={styles.trackerWrapper}>
        <div className={styles.dateWrapper}>
          <CustomDatePicker
            now={data.calendarNow}
            selected={data.timeTrackingDate}
            onChange={handleTimeTrackingDateChange}
            label={data.selectedTimeZoneName}
            calendarStartDay={userInfo?.firstDayOfWeek ?? 1}
          />
        </div>

        <div className={styles.timeWrapper}>
          <CustomTimePicker
            label="From"
            now={data.calendarNow}
            selected={data.startTime}
            onChange={handleStartTimeChange}
            isToday={isToday}
          />
          <CustomTimePicker
            label="To"
            now={data.calendarNow}
            selected={data.endTime}
            onChange={handleEndTimeChange}
            isToday={isToday}
            isNextDay={isNextDay}
            isFuture={isFuture || (isToday && isNextDay)}
          />
        </div>
      </div>

      <TimeZoneDifferenceBanner
        startTime={zonedTimeToUtc(data.startTime, data.selectedTimeZoneName)}
        endTime={zonedTimeToUtc(data.endTime, data.selectedTimeZoneName)}
        memberTimeZone={selectedMemberInfo.timeZone}
        selectedInTimeZoneFilterTimeZone={data.selectedTimeZoneName}
      />

      <div className={styles.billingWrapper}>
        <CustomCheckbox
          label="Billable"
          isChecked={data.isBillable}
          handleChange={toggleBillable}
        />
      </div>
      <TextArea
        label="Comments"
        value={data.notes}
        onChange={handleNotesChange}
        error={data.commentsLengthError}
        errorMessage={`*Maximum length ${MaximumCommentLengthValidationError} characters`}
      />
      <div className={styles.controlWrapper}>
        <div className={styles.deleteBtn}>
          <Button
            type="button"
            variant="delete"
            onClick={() => setConfirmDeleteEntry(true)}
          >
            <TrashIcon />
          </Button>
        </div>
        <div className={styles.cancelBtn}>
          <Button type="button" onClick={handleClose}>
            Cancel
          </Button>
        </div>
        <div className={styles.saveBtn}>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={disableSubmitCondition}
          >
            Save
          </Button>
        </div>
      </div>
      <ConfirmDialog
        open={confirmDeleteEntry}
        title="Confirm"
        description="Delete this time entry? You will not be able to undo the changes."
        acceptBtnText="Delete"
        declineBtnText="Cancel"
        onClose={() => setConfirmDeleteEntry(false)}
        onAccept={handleDeleteTimeEntry}
      />
    </div>
  );
};

export default EditTimeForm;
