import React from 'react';

export const TrashIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.525 18C2.125 18 1.775 17.85 1.475 17.55C1.175 17.25 1.025 16.9 1.025 16.5V2.25H0.75C0.533333 2.25 0.354167 2.17917 0.2125 2.0375C0.0708334 1.89583 0 1.71667 0 1.5C0 1.28333 0.0708334 1.10417 0.2125 0.9625C0.354167 0.820833 0.533333 0.75 0.75 0.75H4.7C4.7 0.533333 4.77083 0.354167 4.9125 0.2125C5.05417 0.0708334 5.23333 0 5.45 0H10.55C10.7667 0 10.9458 0.0708334 11.0875 0.2125C11.2292 0.354167 11.3 0.533333 11.3 0.75H15.25C15.4667 0.75 15.6458 0.820833 15.7875 0.9625C15.9292 1.10417 16 1.28333 16 1.5C16 1.71667 15.9292 1.89583 15.7875 2.0375C15.6458 2.17917 15.4667 2.25 15.25 2.25H14.975V16.5C14.975 16.9 14.825 17.25 14.525 17.55C14.225 17.85 13.875 18 13.475 18H2.525ZM5.175 13.6C5.175 13.8167 5.24583 13.9958 5.3875 14.1375C5.52917 14.2792 5.70833 14.35 5.925 14.35C6.14167 14.35 6.32083 14.2792 6.4625 14.1375C6.60417 13.9958 6.675 13.8167 6.675 13.6V5.125C6.675 4.90833 6.60417 4.72917 6.4625 4.5875C6.32083 4.44583 6.14167 4.375 5.925 4.375C5.70833 4.375 5.52917 4.44583 5.3875 4.5875C5.24583 4.72917 5.175 4.90833 5.175 5.125V13.6ZM9.325 13.6C9.325 13.8167 9.39583 13.9958 9.5375 14.1375C9.67917 14.2792 9.85833 14.35 10.075 14.35C10.2917 14.35 10.4708 14.2792 10.6125 14.1375C10.7542 13.9958 10.825 13.8167 10.825 13.6V5.125C10.825 4.90833 10.7542 4.72917 10.6125 4.5875C10.4708 4.44583 10.2917 4.375 10.075 4.375C9.85833 4.375 9.67917 4.44583 9.5375 4.5875C9.39583 4.72917 9.325 4.90833 9.325 5.125V13.6Z" />
    </svg>
  );
};
