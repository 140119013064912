import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import {
  deleteInvite,
  fetchInvitesList,
  inviteMember,
  resendInvite,
} from 'api/invitesList';
import { AppDispatch } from 'app/store';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import {
  IFetchInvitesParams,
  IInvite,
  IInviteMemberData,
} from './invitesListTypes';

export const getInvites = createAsyncThunk<
  { list: IInvite[]; count: number },
  IFetchInvitesParams | void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@invitesList/getInvites', async (params, thunkApi) => {
  try {
    const res = await fetchInvitesList(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const getInvitesInfiniteScroll = createAsyncThunk<
  { list: IInvite[]; count: number },
  IFetchInvitesParams | void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@invitesList/getInvitesInfiniteScroll', async (params, thunkApi) => {
  try {
    const res = await fetchInvitesList(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const inviteUser = createAsyncThunk<
  IInvite,
  IInviteMemberData,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@invitesList/inviteUser', async (invitationData, thunkApi) => {
  try {
    const res = await inviteMember(invitationData);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const resendMemberInvite = createAsyncThunk<
  void,
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@invitesList/resendMemberInvite', async (inviteId, thunkApi) => {
  try {
    await resendInvite(inviteId);
    // toast.success('Invitation resent!');
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const deleteMemberInvite = createAsyncThunk<
  void,
  number,
  {
    dispatch: Dispatch;
    rejectValue: string | unknown;
  }
>('@@invitesList/deleteMemberInvite', async (inviteId, thunkApi) => {
  try {
    await deleteInvite(inviteId);
    // toast.success('Invitation deleted!');
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
