import React, { FC } from 'react';
import { format } from 'date-fns';
import { memberInfoTimesheetsSelector } from 'features/timesheets/timesheetsSlice';
import { EventTypes, IEventData } from 'features/timeEntries/timeEntriesTypes';
import { useSelector } from 'react-redux';
import styles from './HolidayOrPolicyView.module.scss';

interface HolidayOrPolicyViewProps {
  eventForUpdate: IEventData;
}

const HolidayOrPolicyView: FC<HolidayOrPolicyViewProps> = ({
  eventForUpdate,
}) => {
  const memberInfo = useSelector(memberInfoTimesheetsSelector);
  const { name: memberName } = memberInfo;
  const {
    event,
    type: eventType,
    start,
    end,
    note,
    allDay,
    timeRequested,
  } = eventForUpdate;

  return (
    <div className={styles.wrapper}>
      <span className="input-label">Member</span>
      <div>{memberName}</div>
      <span className="input-label">{eventType} name</span>
      <div>{event.name}</div>

      {eventType === EventTypes.Policy && (
        <>
          <span className="input-label">Total time span</span>
          <div>
            {format(
              start,
              allDay ? 'EEE, MMM dd yyyy' : 'EEE, MMM dd yyyy, hh:mm aaa'
            )}{' '}
            -{' '}
            {format(
              end,
              allDay ? 'EEE, MMM dd yyyy' : 'EEE, MMM dd yyyy, hh:mm aaa'
            )}
            <br />
          </div>
        </>
      )}

      {eventType === EventTypes.Holiday && (
        <>
          <span className="input-label">Holiday date</span>
          <div>
            {format(start, 'EEE, MMM dd yyyy')}
            <br />
          </div>
        </>
      )}
      {eventType === EventTypes.Policy && timeRequested && (
        <>
          <span className="input-label">Time requested</span>
          <div>{timeRequested}</div>
        </>
      )}
      {note && (
        <>
          <span className="input-label">Comments</span>
          <div>{note}</div>
        </>
      )}
    </div>
  );
};

export default HolidayOrPolicyView;
