import React, { FC, useEffect } from 'react';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { SingleValue } from 'react-select';
import { useAppDispatch } from 'app/store';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import { ISelectOption } from 'components/shared/forms/DropdownSelect/DropdownSelect';
import InfoBanner from 'components/shared/InfoBanner';
import { CustomFormikTimePickerProps } from 'components/shared/forms/timePickers/CustomFormikTimePicker/CustomFormikTimePicker';
import { getPoliciesListByMemberId } from 'features/policiesList/policiesListActions';
import {
  loadingPoliciesListSelector,
  policiesListSelector,
} from 'features/policiesList/policiesListSlice';
import { IRequestTimeOffFormValues } from './RequestTimeOffForm';

const DependentPolicyPicker: FC<
  CustomFormikTimePickerProps & FieldHookConfig<SingleValue<ISelectOption>>
> = ({ label, ...props }) => {
  const policiesLoading = useSelector(loadingPoliciesListSelector);
  const policies = useSelector(policiesListSelector);
  const dispatch = useAppDispatch();
  const [field] = useField(props);
  const {
    values: { memberForRequest },
    setFieldValue,
  } = useFormikContext<IRequestTimeOffFormValues>();

  const idForGetPolicies = Number(memberForRequest.value);
  const policiesOptions = policies.map((policy) => ({
    label: policy.name,
    value: String(policy.id),
  }));

  useEffect(() => {
    if (memberForRequest.value) {
      dispatch(getPoliciesListByMemberId(idForGetPolicies));
      setFieldValue('policy', null);
    }
  }, [memberForRequest.value]);

  return (
    <>
      {policiesOptions.length === 0 && !policiesLoading && (
        <InfoBanner
          variant="warning"
          message="This user is not assigned to any policies"
        />
      )}
      <CustomFormikSelect
        label={label}
        name={field.name}
        options={policiesOptions}
      />
    </>
  );
};

export default DependentPolicyPicker;
