import React, { forwardRef } from 'react';

interface CustomDatePickerInputProps
  extends React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    React.AriaAttributes {
  error?: boolean;
}

const CustomFormikDatePicker = forwardRef<
  HTMLInputElement,
  CustomDatePickerInputProps
>(({ value, error, onClick }, ref) => (
  <input
    type="text"
    className={`form-control ${error ? 'errorInput' : ''}`}
    style={{ color: 'black' }}
    onClick={onClick}
    ref={ref}
    value={value}
    readOnly
  />
));

export default CustomFormikDatePicker;
