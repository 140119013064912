import React, { FC } from 'react';
import { numToCurrencyWithLocale } from 'helpers/numToCurrencyWithLocale';
import {
  IAmountsOwedReportsGroupTotalItem,
  IAmountsOwedReportsTableColumnHeading,
} from 'features/amountsOwedReports/amountsOwedReportsTypes';

interface TotalRowItemProps {
  item: IAmountsOwedReportsGroupTotalItem;
  column: IAmountsOwedReportsTableColumnHeading;
}

export const TotalRowItem: FC<TotalRowItemProps> = ({ item, column }) => {
  return (
    <>
      <td key={column.value}>
        {column.value === 'amount'
          ? numToCurrencyWithLocale(item[column.value], 'USD', 'en-US')
          : column.value === 'totalHours'
          ? item[column.value]
          : ''}
      </td>
    </>
  );
};

export default TotalRowItem;
