import React, { FC } from 'react';
import * as ReactDOM from 'react-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import styles from './Preloader.module.scss';

const Preloader: FC = () => {
  return ReactDOM.createPortal(
    <div className={styles.overlay}>
      <PulseLoader color="#0168fa" size={12} margin={7} speedMultiplier={1} />
    </div>,
    document.getElementById('portal') as HTMLElement
  );
};

export default Preloader;
