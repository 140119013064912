import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import Button from 'components/shared/Button';
import { selectTimeZonesListUserSelector } from 'features/user/userSlice';
import { organizationInfoSelector } from 'features/organization/organizationSlice';
import styles from './EditOrganizationForm.module.scss';
import { IUpdateOrganizationData } from 'features/organization/organizationTypes';

interface EditOrganizationFormProps {
  onSubmit: (data: IUpdateOrganizationData) => void;
}

const EditOrganizationForm: FC<EditOrganizationFormProps> = ({ onSubmit }) => {
  const timeZonesList = useSelector(selectTimeZonesListUserSelector);
  const organization = useSelector(organizationInfoSelector);

  const InitialValues = {
    name: organization.name,
    timeZone: {
      value: organization.timeZone.id,
      label: `(${organization.timeZone.greenwichMeanTime}) ${organization.timeZone.name}`,
    },
  };

  const timeZonesOptions = timeZonesList.map((tz) => ({
    value: tz.id,
    label: `(${tz.greenwichMeanTime}) ${tz.name}`,
  }));

  const validationSchema = Yup.object({
    name: Yup.string().required('Organization name is required field'),
  });

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit({
            name: values.name,
            timeZone: values.timeZone.label.split(' ')[1],
            organizationId: organization.id,
          });
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <ValidationTextInput
              label="Organization name"
              name="name"
              type="text"
              placeholder="Enter the organization name..."
            />

            <CustomFormikSelect
              label="Time zone"
              name="timeZone"
              options={timeZonesOptions}
              isSearchable
            />

            <div className={styles.submitWrapper}>
              <Button
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting}
                preloader={formik.isSubmitting}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditOrganizationForm;
