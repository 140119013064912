import { dateToTimeZone } from './dateToTimeZone';

export const checkIsToday = (dateForCheck: Date, timeZone: string) => {
  const currentDate = dateToTimeZone(new Date(), timeZone);
  const yearOfCurrentDate = currentDate.getFullYear();
  const yearOfDateForCheck = dateForCheck.getFullYear();
  const monthOfCurrentDate = currentDate.getMonth();
  const monthOfDateForCheck = dateForCheck.getMonth();
  const dateOfCurrentDate = currentDate.getDate();
  const dateOfDateForCheck = dateForCheck.getDate();
  return (
    yearOfCurrentDate === yearOfDateForCheck &&
    monthOfCurrentDate === monthOfDateForCheck &&
    dateOfCurrentDate === dateOfDateForCheck
  );
};
