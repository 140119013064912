import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import { addMonths } from 'date-fns';
import CustomDatePickerInput from '../CustomDatePickerInput';
import styles from '../CustomDatePicker.module.scss';

interface CustomDatePickerProps {
  now: Date;
  selected: Date;
  onChange: (date: Date) => void;
  label?: string;
  calendarStartDay: number;
}

const CustomDatePicker: FC<CustomDatePickerProps> = ({
  now,
  selected,
  onChange,
  label,
  calendarStartDay,
}) => {
  const minDate = addMonths(now, -6);
  const renderDayContents = (day: number, date: Date) => {
    const dayText = `${date}`;
    return (
      <span className={styles.customDay} title={dayText}>
        {day}
      </span>
    );
  };

  return (
    <>
      <div className={styles.wrapper}>
        {label && <label className="input-label">{label}</label>}
        <DatePicker
          selected={selected}
          onChange={(date: Date) => onChange(date)}
          customInput={<CustomDatePickerInput />}
          minDate={minDate}
          maxDate={now}
          renderDayContents={renderDayContents}
          showPopperArrow={false}
          dateFormat="MMM dd, yyyy"
          calendarStartDay={calendarStartDay}
        />
      </div>
    </>
  );
};

export default CustomDatePicker;
