import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  createTimeEntry,
  editTimeEntry,
  fetchMemberTimeEntries,
  removeTimeEntry,
} from 'api/timeEntries';
import { AppDispatch, RootState } from 'app/store';
import {
  IAddTimeEntryData,
  IEditTimeEntryData,
  IEventData,
  IGetMemberEventsData,
} from './timeEntriesTypes';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import { ICurrentCalendarParams } from '../timesheets/timesheetsTypes';

export const getMemberTimeEntries = createAsyncThunk<
  { events: IEventData[]; params: ICurrentCalendarParams },
  IGetMemberEventsData,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('@@timeEntries/getMemberTimeEntries', async (data, thunkApi) => {
  try {
    const res = await fetchMemberTimeEntries(data);
    const currentParams = thunkApi.getState().timesheets.currentCalendarParams;
    return { events: res.data, params: currentParams };
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const addTimeEntry = createAsyncThunk<
  void,
  IAddTimeEntryData,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@timeEntries/addTimeEntry', async (data, thunkApi) => {
  try {
    const res = await createTimeEntry(data);
    if (res.status === 200) {
      const currentParams =
        thunkApi.getState().timesheets.currentCalendarParams;
      thunkApi.dispatch(getMemberTimeEntries(currentParams));
      toast.success('Time entry created.');
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const changeTimeEntry = createAsyncThunk<
  void,
  IEditTimeEntryData,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@timeEntries/changeTimeEntry', async (data, thunkApi) => {
  try {
    const res = await editTimeEntry(data);
    if (res.status >= 200 && res.status < 300) {
      const currentParams =
        thunkApi.getState().timesheets.currentCalendarParams;
      thunkApi.dispatch(getMemberTimeEntries(currentParams));
      toast.success('Time entry successfully updated.');
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const deleteTimeEntry = createAsyncThunk<
  void,
  number,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@timeEntries/deleteTimeEntry', async (timeEntryId, thunkApi) => {
  try {
    const res = await removeTimeEntry(timeEntryId);
    if (res.status === 204) {
      const currentParams =
        thunkApi.getState().timesheets.currentCalendarParams;
      thunkApi.dispatch(getMemberTimeEntries(currentParams));
      toast.success('Time entry deleted.');
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
