import React from 'react';

export const MagnifyIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.35 13.3562C13.2566 13.4481 13.131 13.4998 13 13.5C12.8672 13.4994 12.7397 13.448 12.6437 13.3562L9.94372 10.65C8.80659 11.6051 7.34462 12.0844 5.86273 11.9878C4.38083 11.8912 2.99343 11.2263 1.98988 10.1316C0.986331 9.03698 0.444121 7.59717 0.476333 6.11248C0.508545 4.62779 1.11269 3.21286 2.16277 2.16277C3.21286 1.11269 4.62779 0.508545 6.11248 0.476333C7.59717 0.444121 9.03698 0.986331 10.1316 1.98988C11.2263 2.99343 11.8912 4.38083 11.9878 5.86273C12.0844 7.34462 11.6051 8.80659 10.65 9.94373L13.35 12.6437C13.3972 12.6903 13.4347 12.7457 13.4603 12.8069C13.486 12.868 13.4991 12.9337 13.4991 13C13.4991 13.0663 13.486 13.1319 13.4603 13.1931C13.4347 13.2542 13.3972 13.3097 13.35 13.3562ZM6.24997 11C7.18944 11 8.1078 10.7214 8.88893 10.1995C9.67007 9.67752 10.2789 8.93567 10.6384 8.06772C10.9979 7.19977 11.092 6.24471 10.9087 5.3233C10.7254 4.40189 10.273 3.55552 9.60873 2.89122C8.94443 2.22692 8.09806 1.77452 7.17665 1.59124C6.25524 1.40797 5.30018 1.50203 4.43223 1.86155C3.56428 2.22106 2.82243 2.82988 2.30049 3.61102C1.77856 4.39215 1.49997 5.31051 1.49997 6.24998C1.50163 7.50925 2.0026 8.71647 2.89304 9.60691C3.78348 10.4973 4.9907 10.9983 6.24997 11Z" />
    </svg>
  );
};
