import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik, isNaN } from 'formik';
import { Options } from 'react-select';
import * as Yup from 'yup';
import PhoneNumberValidationInput from 'components/shared/forms/PhoneNumberValidationInput';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import { SelectOption } from 'components/shared/forms/CustomFormikSelect/CustomFormikSelect';
import Button from 'components/shared/Button';
import { MaxLength50Symbols } from 'constants/formsConstants';
import { selectTimeZonesListUserSelector } from 'features/user/userSlice';
import {
  FirstDayOfWeek,
  IUpdateUserInfo,
  IUser,
} from 'features/user/userTypes';
import styles from './EditAccountForm.module.scss';

export interface EditAccountFormProps {
  user: IUser;
  handleSubmit: (data: IUpdateUserInfo) => void;
}

const EditAccountForm: FC<EditAccountFormProps> = ({ user, handleSubmit }) => {
  const allTimeZones = useSelector(selectTimeZonesListUserSelector);

  const InitialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber,
    timeZone: {
      label: `(${user.timeZone.greenwichMeanTime}) ${user.timeZone.name}`,
      value: user.timeZone.id,
    },
    firstDayOfWeek:
      user.firstDayOfWeek || user.firstDayOfWeek === 0
        ? {
            label: FirstDayOfWeek[user.firstDayOfWeek],
            value: String(user.firstDayOfWeek),
          }
        : { label: 'Monday', value: '1' },
  };

  const daysOfWeekOptions = (
    Object.keys(FirstDayOfWeek) as Array<keyof typeof FirstDayOfWeek>
  )
    .filter((key) => isNaN(Number(key)))
    .map((value, idx) => ({
      label: value,
      value: String(idx),
    })) as Options<SelectOption>;

  const validationSchema = Yup.object({
    firstName: Yup.string().max(
      50,
      `Maximum length ${MaxLength50Symbols} characters`
    ),
    lastName: Yup.string().max(
      50,
      `Maximum length ${MaxLength50Symbols} characters`
    ),
  });

  const timeZoneOptions = allTimeZones.map((tz) => ({
    value: tz.id,
    label: `(${tz.greenwichMeanTime}) ${tz.name}`,
  }));

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit({
            ...values,
            timeZone: {
              id: values.timeZone.value,
              name: values.timeZone.label.split(' ')[1],
              greenwichMeanTime: values.timeZone.label
                .split(' ')[0]
                .slice(1, -1),
            },
            firstDayOfWeek: Number(values.firstDayOfWeek.value) as 0 | 1,
          });
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <ValidationTextInput
              label="First name"
              name="firstName"
              type="text"
            />
            <ValidationTextInput
              label="Last name"
              name="lastName"
              type="text"
            />

            <PhoneNumberValidationInput
              label="Phone number"
              name="phoneNumber"
            />

            <CustomFormikSelect
              label="Time zone"
              name="timeZone"
              options={timeZoneOptions}
              isSearchable
            />

            <CustomFormikSelect
              label="First day of week"
              name="firstDayOfWeek"
              options={daysOfWeekOptions}
              isSearchable
            />

            <div className={styles.submitWrapper}>
              <Button
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting || !formik.isValid}
                preloader={formik.isSubmitting}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditAccountForm;
