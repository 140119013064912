import React from 'react';

export const ReportsIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.675 14.025H10.05C10.2667 14.025 10.4458 13.9542 10.5875 13.8125C10.7292 13.6708 10.8 13.4917 10.8 13.275C10.8 13.0583 10.7292 12.8792 10.5875 12.7375C10.4458 12.5958 10.2667 12.525 10.05 12.525H4.675C4.45833 12.525 4.27917 12.5958 4.1375 12.7375C3.99583 12.8792 3.925 13.0583 3.925 13.275C3.925 13.4917 3.99583 13.6708 4.1375 13.8125C4.27917 13.9542 4.45833 14.025 4.675 14.025ZM4.675 9.75H13.325C13.5417 9.75 13.7208 9.67917 13.8625 9.5375C14.0042 9.39583 14.075 9.21667 14.075 9C14.075 8.78333 14.0042 8.60417 13.8625 8.4625C13.7208 8.32083 13.5417 8.25 13.325 8.25H4.675C4.45833 8.25 4.27917 8.32083 4.1375 8.4625C3.99583 8.60417 3.925 8.78333 3.925 9C3.925 9.21667 3.99583 9.39583 4.1375 9.5375C4.27917 9.67917 4.45833 9.75 4.675 9.75ZM4.675 5.475H13.325C13.5417 5.475 13.7208 5.40417 13.8625 5.2625C14.0042 5.12083 14.075 4.94167 14.075 4.725C14.075 4.50833 14.0042 4.32917 13.8625 4.1875C13.7208 4.04583 13.5417 3.975 13.325 3.975H4.675C4.45833 3.975 4.27917 4.04583 4.1375 4.1875C3.99583 4.32917 3.925 4.50833 3.925 4.725C3.925 4.94167 3.99583 5.12083 4.1375 5.2625C4.27917 5.40417 4.45833 5.475 4.675 5.475ZM1.5 18C1.1 18 0.75 17.85 0.45 17.55C0.15 17.25 0 16.9 0 16.5V1.5C0 1.1 0.15 0.75 0.45 0.45C0.75 0.15 1.1 0 1.5 0H16.5C16.9 0 17.25 0.15 17.55 0.45C17.85 0.75 18 1.1 18 1.5V16.5C18 16.9 17.85 17.25 17.55 17.55C17.25 17.85 16.9 18 16.5 18H1.5Z" />
    </svg>
  );
};
