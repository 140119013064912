import React, { FC, useEffect } from 'react';
import { useAppDispatch } from 'app/store';
import EditOrganizationForm from 'components/organization/EditOrganizationForm';
import { IUpdateOrganizationData } from 'features/organization/organizationTypes';
import { getTimeZones } from 'features/user/userActions';
import {
  getOrganizationInfo,
  updateOrganizationInfo,
} from 'features/organization/organizationActions';
import { selectInfoUserSelector } from 'features/user/userSlice';
import { useSelector } from 'react-redux';

const Organization: FC = () => {
  const dispatch = useAppDispatch();
  const userInfo = useSelector(selectInfoUserSelector);

  useEffect(() => {
    dispatch(getTimeZones());
    userInfo?.organizationId &&
      dispatch(getOrganizationInfo(userInfo?.organizationId));
  }, []);

  const handleSubmit = (data: IUpdateOrganizationData) => {
    dispatch(updateOrganizationInfo(data));
  };

  return (
    <>
      <>
        <EditOrganizationForm onSubmit={handleSubmit} />
      </>
    </>
  );
};

export default Organization;
