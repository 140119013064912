import { useEffect, useState } from 'react';

const UseMount = ({
  opened,
  animationTime,
}: {
  opened: boolean;
  animationTime: number;
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (opened && !mounted) {
      setMounted(true);
    } else if (!opened && mounted) {
      setTimeout(() => {
        setMounted(false);
      }, animationTime);
    }
  }, [opened]);

  return { mounted };
};

export default UseMount;
