import React from 'react';

export const ThreeDotsIcon = () => {
  return (
    <svg
      width="4"
      height="16"
      viewBox="0 0 4 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.11489 8.88515C1.35675 9.12701 1.65667 9.25004 2 9.25004M1.11489 8.88515L2 9.25004M1.11489 8.88515L1.29167 8.70837L2 9.25004M1.11489 8.88515C0.873028 8.64329 0.75 8.34337 0.75 8.00004C0.75 7.65672 0.873028 7.35679 1.11489 7.11493C1.35675 6.87307 1.65667 6.75004 2 6.75004C2.34332 6.75004 2.64325 6.87307 2.88511 7.11493C3.12697 7.35679 3.25 7.65672 3.25 8.00004C3.25 8.34337 3.12697 8.64329 2.88511 8.88515C2.64325 9.12701 2.34332 9.25004 2 9.25004M1.11489 14.5518C1.35675 14.7937 1.65667 14.9167 2 14.9167C2.34333 14.9167 2.64325 14.7937 2.88511 14.5518C3.12697 14.31 3.25 14.01 3.25 13.6667C3.25 13.3234 3.12697 13.0235 2.88511 12.7816C2.64325 12.5397 2.34333 12.4167 2 12.4167C1.65667 12.4167 1.35675 12.5397 1.11489 12.7816C0.873029 13.0235 0.75 13.3234 0.75 13.6667C0.75 14.01 0.873028 14.31 1.11489 14.5518ZM1.11489 3.21848C1.35675 3.46035 1.65667 3.58337 2 3.58337C2.34332 3.58337 2.64325 3.46035 2.88511 3.21848C3.12697 2.97662 3.25 2.6767 3.25 2.33337C3.25 1.99005 3.12697 1.69013 2.88511 1.44826C2.64325 1.2064 2.34332 1.08337 2 1.08337C1.65667 1.08337 1.35675 1.2064 1.11489 1.44826C0.873028 1.69013 0.75 1.99005 0.75 2.33337C0.75 2.6767 0.873028 2.97662 1.11489 3.21848Z"
        strokeWidth="0.5"
      />
    </svg>
  );
};
