import React, { FC, useEffect, useState } from 'react';
import {
  createSearchParams,
  URLSearchParamsInit,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { addDays } from 'date-fns';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import { useAppDispatch } from 'app/store';
import AmountsOwedReportsFilter from 'components/reports/amountsOwed/AmountsOwedReportsFilter';
import CustomDateRangePicker from 'components/shared/forms/CustomDateRangePicker';
import EmptyStatePlaceholder from 'components/shared/EmptyStatePlaceholder';
import { IDateRangeData } from 'components/shared/forms/CustomDateRangePicker/CustomDateRangePicker';
import SummaryBanner from 'components/reports/SummaryBanner';
import ReportsTable from 'components/reports/amountsOwed/ReportsTable';
import { IPageTab } from 'components/shared/PageTabs/PageTabs';
import MenuDrawer from 'components/shared/MenuDrawer';
import PageTitle from 'components/shared/PageTitle';
import Preloader from 'components/shared/Preloader';
import PageTabs from 'components/shared/PageTabs';
import Button from 'components/shared/Button';
import { organizationInfoSelector } from 'features/organization/organizationSlice';
import { permissionsUserSelector } from 'features/user/userSlice';
import {
  exportAmountsOwedReports,
  getAmountsOwedReports,
} from 'features/amountsOwedReports/amountsOwedReportsActions';
import { getProjectsFilterList } from 'features/projectsFilterList/projectsFilterListActions';
import { getMembersFilterList } from 'features/membersFilterList/membersFilterListActions';
import {
  groupsTableHeadersAmountsOwedSelector,
  isLoadingAmountsOwedSelector,
  isReportDownloadingAmountsOwedSelector,
  reportsGroupsListAmountsOwedSelector,
  summaryAmountsOwedSelector,
} from 'features/amountsOwedReports/amountsOwedReportsSlice';
import {
  AmountsOwedReportsParamsForDownloading,
  IAmountsOwedReportsURLSearchParams,
  IGetAmountsOwedReportsParams,
} from 'features/amountsOwedReports/amountsOwedReportsTypes';
import { dateToISODate } from 'helpers/dateToISODate';
import styles from '../Reports.module.scss';

const AmountsOwed: FC = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const myReportsPage = pathname.endsWith('/my');
  const reportsGroups = useSelector(reportsGroupsListAmountsOwedSelector);
  const tableHeaders = useSelector(groupsTableHeadersAmountsOwedSelector);
  const isLoading = useSelector(isLoadingAmountsOwedSelector);
  const isReportDownloading = useSelector(
    isReportDownloadingAmountsOwedSelector
  );
  const permissions = useSelector(permissionsUserSelector);
  const summary = useSelector(summaryAmountsOwedSelector);
  const { name: organizationName, timeZone } = useSelector(
    organizationInfoSelector
  );

  const location = useLocation();
  const summaryHours = summary.find((el) => el.label === 'Hours');
  const isAnyHours =
    Number(summaryHours?.value) !== 0 && summaryHours?.value !== '0:00:00';

  const tabsData = [
    {
      path: `/reports/amounts-owed/my${location.search}`,
      name: 'Me',
    },
    {
      path: `/reports/amounts-owed${location.search}`,
      name: 'All',
    },
  ];

  let tabsWithPermissions: IPageTab[] = tabsData;

  const isMyReportAvailable = permissions?.some(
    (el) => el.name === 'View My Amounts owed'
  );
  const isAllReportsAvailable = permissions?.some(
    (el) => el.name === 'View Amounts owed'
  );

  if (!isMyReportAvailable) {
    tabsWithPermissions = tabsData.filter((el) => el.name !== 'My');
  }
  if (!isAllReportsAvailable) {
    tabsWithPermissions = tabsData.filter((el) => el.name !== 'All');
  }

  //date range filter
  const [filtersOpen, setFiltersOpen] = useState(false);
  const startParam = searchParams.get('startDate');
  const endParam = searchParams.get('endDate');
  const defaultStart = startParam
    ? new Date(startParam)
    : addDays(new Date(), -7);
  const defaultEnd = endParam ? new Date(endParam) : new Date();
  const [dateRange, setDateRange] = useState<IDateRangeData>({
    start: defaultStart,
    end: defaultEnd,
  });

  const onDateRangeChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setDateRange({ start, end });
  };

  const handleDateRangeFilterApply = () => {
    const params = Object.fromEntries([...Array.from(searchParams)]);
    const URLSearchParams: IAmountsOwedReportsURLSearchParams = {
      ...params,
      startDate: dateToISODate(dateRange.start),
      endDate: dateToISODate(dateRange.end),
      projectsIds: searchParams.getAll('projectsIds'),
      membersIds: searchParams.getAll('membersIds'),
    };

    navigate({
      pathname: pathname,
      search: createSearchParams(
        URLSearchParams as URLSearchParamsInit
      ).toString(),
    });
  };

  const handleExportReport = () => {
    const requestParams: AmountsOwedReportsParamsForDownloading = {
      startDate: '',
      endDate: '',
      organizationName,
      reportType: 'amountsOwed',
    };

    const startDateQuery = searchParams.get('startDate');
    const endDateQuery = searchParams.get('endDate');

    const sumDateRangesQuery = searchParams.get('sumDateRanges');
    const detailedReportsQuery = searchParams.get('detailedReports');
    const projectsQuery = searchParams.getAll('projectsIds');
    const membersQuery = searchParams.getAll('membersIds');

    if (startDateQuery) requestParams.startDate = startDateQuery;
    if (endDateQuery) requestParams.endDate = endDateQuery;
    if (sumDateRangesQuery) requestParams.sumDateRanges = sumDateRangesQuery;
    if (detailedReportsQuery)
      requestParams.detailedReports = detailedReportsQuery;
    if (projectsQuery.length > 0) requestParams.projectsIds = projectsQuery;
    if (membersQuery.length > 0) requestParams.membersIds = membersQuery;

    dispatch(exportAmountsOwedReports(requestParams));
  };

  useEffect(() => {
    dispatch(getProjectsFilterList());
    dispatch(getMembersFilterList());
  }, []);
  useEffect(() => {
    const params = Object.fromEntries([...Array.from(searchParams)]);
    if (!isEmpty(params)) {
      const requestParams = {} as IGetAmountsOwedReportsParams;

      requestParams.isMyReports = myReportsPage;
      const startDateQuery = searchParams.get('startDate');
      const endDateQuery = searchParams.get('endDate');

      const sumDateRangesQuery = searchParams.get('sumDateRanges');
      const detailedReportsQuery = searchParams.get('detailedReports');
      const projectsQuery = searchParams.getAll('projectsIds');
      const membersQuery = searchParams.getAll('membersIds');

      if (startDateQuery) requestParams.startDate = startDateQuery;
      if (endDateQuery) requestParams.endDate = endDateQuery;
      if (sumDateRangesQuery) requestParams.sumDateRanges = sumDateRangesQuery;
      if (detailedReportsQuery)
        requestParams.detailedReports = detailedReportsQuery;
      if (projectsQuery.length > 0) requestParams.projectsIds = projectsQuery;
      if (membersQuery.length > 0) requestParams.membersIds = membersQuery;

      dispatch(getAmountsOwedReports(requestParams));
    }
  }, [searchParams, myReportsPage]);
  useEffect(() => {
    const params = Object.fromEntries([...Array.from(searchParams)]);

    if (isEmpty(params)) {
      const URLSearchParams: IAmountsOwedReportsURLSearchParams = {
        startDate: dateToISODate(dateRange.start),
        endDate: dateToISODate(dateRange.end),
      };

      navigate({
        pathname: pathname,
        search: createSearchParams(
          URLSearchParams as URLSearchParamsInit
        ).toString(),
      });
    }
  }, [pathname]);

  return (
    <>
      <div className={styles.controls}>
        <PageTabs tabsData={tabsWithPermissions} />

        <div className={styles.datesRangeContainer}>
          <div className={styles.datesRangeWrapper}>
            <CustomDateRangePicker
              startDate={dateRange.start}
              endDate={dateRange.end}
              handleOnChange={onDateRangeChange}
            />
          </div>
          <div>
            <Button
              onClick={handleDateRangeFilterApply}
              disabled={!dateRange.start || !dateRange.end}
            >
              Apply
            </Button>
          </div>
        </div>

        <div>
          <Button variant="primary" onClick={() => setFiltersOpen(true)}>
            Filters
          </Button>
        </div>
      </div>

      <MenuDrawer open={filtersOpen} onClose={() => setFiltersOpen(false)}>
        <AmountsOwedReportsFilter closeFilters={() => setFiltersOpen(false)} />
      </MenuDrawer>

      {isLoading ? (
        <Preloader />
      ) : (
        <>
          {isAnyHours ? (
            <>
              <div className={styles.summaryWrapper}>
                <SummaryBanner summary={summary} />
              </div>

              <div className={styles.tableTitle}>
                <PageTitle title={organizationName} />
                <div className={styles.orgTimeZone}>{timeZone.name}</div>
                <button
                  aria-label="Export report"
                  onClick={handleExportReport}
                  className={styles.export}
                  disabled={isReportDownloading}
                >
                  <IoCloudDownloadOutline />
                  <span>Export</span>
                </button>
                {isReportDownloading && <Preloader />}
              </div>

              <ReportsTable data={reportsGroups} tableHeaders={tableHeaders} />
            </>
          ) : (
            <EmptyStatePlaceholder>
              No reports matching your filter parameters.
            </EmptyStatePlaceholder>
          )}
        </>
      )}
    </>
  );
};

export default AmountsOwed;
