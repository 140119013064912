import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useAppDispatch } from 'app/store';
import CustomCheckbox from 'components/shared/forms/CustomCheckbox';
import InvitationForm from 'components/members/InvitationForm';
import { IPageTab } from 'components/shared/PageTabs/PageTabs';
import SearchInput from 'components/shared/forms/SearchForm/SearchInput';
import PageTabs from 'components/shared/PageTabs';
import Button from 'components/shared/Button';
import Modal from 'components/shared/Modal';
import { projectsFilterListSelector } from 'features/projectsFilterList/projectsFilterListSlice';
import { permissionsUserSelector } from 'features/user/userSlice';
import { getProjectsFilterList } from 'features/projectsFilterList/projectsFilterListActions';
import { IFetchMembersParams } from 'features/members/membersTypes';
import { getMembers } from 'features/members/membersActions';
import {
  filterMembersSelector,
  setMembersPageFilter,
} from 'features/members/membersSlice';
import { startsWithNoSpace } from 'helpers/regularExpressions';
import styles from './Members.module.scss';

const Members: FC = () => {
  const membersPageFilter = useSelector(filterMembersSelector);
  const permissions = useSelector(permissionsUserSelector);
  const projects = useSelector(projectsFilterListSelector);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const isInvitesTabActive = pathname.endsWith('/invites');
  const [inviteFormOpen, setInviteFormOpen] = useState(false);
  const closeInviteForm = () => setInviteFormOpen(false);
  const openInviteForm = () => setInviteFormOpen(true);
  const didMountRef = useRef(false);
  const tabsData: IPageTab[] = [
    {
      path: `/members`,
      name: 'Active members',
    },
    {
      path: `invites`,
      name: 'Invites',
    },
  ];

  const toggleShowDeleted = () => {
    dispatch(
      setMembersPageFilter({
        ...membersPageFilter,
        showDeleted: !membersPageFilter.showDeleted,
      })
    );
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    if (startsWithNoSpace.test(text)) {
      dispatch(
        setMembersPageFilter({
          ...membersPageFilter,
          searchValue: text,
          skip: 0,
          take: 20,
        })
      );
    }
  };
  const clearInput = () => {
    dispatch(
      setMembersPageFilter({
        ...membersPageFilter,
        searchValue: '',
        skip: 0,
        take: 20,
      })
    );
  };
  const debouncedMembersFiltration = useCallback(
    debounce((params: IFetchMembersParams) => {
      dispatch(getMembers(params));
    }, 300),
    []
  );

  let tabsWithPermissions: IPageTab[] = tabsData;
  const isInvitesAvailable = permissions?.some(
    (el) => el.name === 'ViewInvitesPage'
  );

  if (!isInvitesAvailable) {
    tabsWithPermissions = tabsData.filter((el) => el.name !== 'Invites');
  }

  useEffect(() => {
    dispatch(getMembers(membersPageFilter));
    dispatch(getProjectsFilterList());
  }, []);
  useEffect(() => {
    if (didMountRef.current) {
      debouncedMembersFiltration(membersPageFilter);
    }
    didMountRef.current = true;
  }, [membersPageFilter.searchValue, membersPageFilter.showDeleted]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.controls}>
        <PageTabs tabsData={tabsWithPermissions} />
        <div className={styles.searchAndActionBtnWrapper}>
          {!isInvitesTabActive ? (
            <>
              <div className={styles.checkboxWrapper}>
                <CustomCheckbox
                  label="Show removed members"
                  isChecked={membersPageFilter.showDeleted}
                  handleChange={toggleShowDeleted}
                />
              </div>
              <div className={styles.searchWrapper}>
                <SearchInput
                  value={membersPageFilter.searchValue}
                  onChange={handleInputChange}
                  clearInput={clearInput}
                  placeholder="Search members"
                  outlined
                />
              </div>
            </>
          ) : (
            <>
              <Button
                variant="primary"
                onClick={openInviteForm}
                aria-label="Open invitation form."
              >
                Invite user
              </Button>
              <Modal
                title="Invite user"
                open={inviteFormOpen}
                onClose={closeInviteForm}
              >
                <InvitationForm
                  closeInviteForm={closeInviteForm}
                  onSubmit={() => {}}
                  organizationProjects={projects}
                />
              </Modal>
            </>
          )}
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default Members;
