import React, { FC, useState } from 'react';
import { FieldHookConfig, useField } from 'formik';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import ConfirmDialog from 'components/shared/ConfirmDialog';
import CustomDatePickerInput from '../CustomDatePickerInput';
import { selectInfoUserSelector } from 'features/user/userSlice';
import styles from '../CustomDatePicker.module.scss';

interface CustomDatePickerProps {
  label?: string;
  maxDate?: Date;
  currentRateDate?: Date;
  onCalendarClose?: () => void;
}

const EditPayRateFormikDatePicker: FC<
  CustomDatePickerProps & FieldHookConfig<string>
> = ({ label, maxDate, currentRateDate, onCalendarClose, ...props }) => {
  const userInfo = useSelector(selectInfoUserSelector);
  const [field, meta, helpers] = useField(props);
  const [newDateToStart, setNewDateToStart] = useState<null | Date>(null);
  const [confirmChangeStartDate, setConfirmChangeStartDate] = useState(false);

  const renderDayContents = (day: number, date: Date) => {
    const dayText = `${date}`;
    return (
      <span className={styles.customDay} title={dayText}>
        {day}
      </span>
    );
  };

  const handleChange = (val: Date) => {
    if (!currentRateDate) {
      helpers.setValue(String(val));
    } else {
      if (
        val.getFullYear() === new Date(currentRateDate).getFullYear() &&
        val.getMonth() === new Date(currentRateDate).getMonth() &&
        val.getDate() > new Date(currentRateDate).getDate()
      ) {
        helpers.setValue(String(val));
      } else {
        setNewDateToStart(val);
        setConfirmChangeStartDate(true);
      }
    }
  };

  return (
    <>
      <div className={styles.wrapper} style={{ width: '200px' }}>
        {label && (
          <label className="input-label" htmlFor={field.name}>
            {label}
          </label>
        )}
        <DatePicker
          selected={(field.value && new Date(field.value)) || null}
          onChange={handleChange}
          customInput={
            <CustomDatePickerInput error={!!meta.error && meta.touched} />
          }
          renderDayContents={renderDayContents}
          showPopperArrow={false}
          dateFormat="EEE, MMM dd, yyyy"
          id={field.name}
          maxDate={maxDate}
          onCalendarClose={onCalendarClose}
          calendarStartDay={userInfo?.firstDayOfWeek ?? 1}
        />
        {meta.touched && !!meta.error ? (
          <div className={styles.errorMessage}>*{meta.error}</div>
        ) : null}
      </div>

      <ConfirmDialog
        open={confirmChangeStartDate}
        title="Confirm"
        description={`The effective date of this rate change is set prior to existing 
        rate changes and will override anything set after ${
          newDateToStart && format(newDateToStart, 'MMM dd, yyyy')
        }. You will need to save the page to confirm these changes.`}
        acceptBtnText="Apply"
        declineBtnText="Cancel"
        onClose={() => {
          setConfirmChangeStartDate(false);
        }}
        onAccept={() => {
          if (newDateToStart) {
            helpers.setValue(String(newDateToStart));
          }
        }}
      />
    </>
  );
};

export default EditPayRateFormikDatePicker;
