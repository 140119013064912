import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import EditHolidayMembersForm from 'components/timeOff/EditHolidayMembersForm';
import Preloader from 'components/shared/Preloader';
import { IHolidayInfoUpdatingData } from 'features/holidayInfo/holidayInfoTypes';
import { changeHolidayInfo } from 'features/holidayInfo/holidayInfoActions';
import {
  holidayInfoSelector,
  isLoadingHolidayInfoSelector,
} from 'features/holidayInfo/holidayInfoSlice';
import { membersFilterListSelector } from 'features/membersFilterList/membersFilterListSlice';

const EditHolidayMembers = () => {
  const isLoading = useSelector(isLoadingHolidayInfoSelector);
  const holiday = useSelector(holidayInfoSelector);
  const members = useSelector(membersFilterListSelector);
  const dispatch = useAppDispatch();

  const handleSubmit = (data: IHolidayInfoUpdatingData) => {
    dispatch(changeHolidayInfo(data));
  };

  return isLoading ? (
    <Preloader />
  ) : (
    <EditHolidayMembersForm
      organizationMembers={members}
      holidayId={holiday.id}
      currentMembers={holiday.members}
      onSubmit={handleSubmit}
    />
  );
};

export default EditHolidayMembers;
