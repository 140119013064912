import React, { FC } from 'react';
import { numToCurrencyWithLocale } from 'helpers/numToCurrencyWithLocale';
import {
  IAmountsOwedReport,
  IAmountsOwedReportsTableColumnHeading,
} from 'features/amountsOwedReports/amountsOwedReportsTypes';

interface TableRowItemProps {
  item: IAmountsOwedReport;
  columns: IAmountsOwedReportsTableColumnHeading[];
}

export const TableRowItem: FC<TableRowItemProps> = ({ item, columns }) => {
  return (
    <>
      <tr>
        {columns.map((col) => {
          if (col.value === 'details') {
            return <></>;
          }
          return (
            <td key={col.value}>
              {col.value === 'amount' || col.value === 'currentRate'
                ? numToCurrencyWithLocale(item[col.value], 'USD', 'en-US')
                : item[col.value]}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default TableRowItem;
