import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CustomFormikDatePicker from 'components/shared/forms/datePickers/CustomFormikDatePicker';
import CustomFormikCheckbox from 'components/shared/forms/CustomFormikCheckbox';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import FormikTextArea from 'components/shared/forms/textAreas/FormikTextArea';
import Button from 'components/shared/Button';
import { MaximumCommentLengthValidationError } from 'constants/formsConstants';
import { policiesListSelector } from 'features/policiesList/policiesListSlice';
import {
  ITimeOffRequestInfo,
  IUpdateTimeOffRequestData,
} from 'features/timeOffRequestInfo/timeOffRequestInfoTypes';
import { hoursColonMinutesFormat } from 'helpers/regularExpressions';
import { dateToTimeZone } from 'helpers/dateToTimeZone';
import { setTimeInDate } from 'helpers/setTimeInDate';
import { MaximumHoursForTimeOffRequest } from '../RequestTimeOffForm/RequestTimeOffForm';
import FetchPoliciesOptionsComponent from '../RequestTimeOffForm/FetchPoliciesOptionsComponent';
import DependentTimeRequestedField from '../RequestTimeOffForm/DependentTimeRequestedField';
import DependentStartTimePicker from '../RequestTimeOffForm/DependentStartTimePicker';
import DependentEndTimePicker from '../RequestTimeOffForm/DependentEndTimePicker';
import DependentTimeZoneField from '../RequestTimeOffForm/DependentTimeZoneField';
import styles from './EditTimeOffRequestGeneralForm.module.scss';

interface EditPolicyGeneralFormProps {
  request: ITimeOffRequestInfo;
  onSubmit: (data: IUpdateTimeOffRequestData) => void;
}

const EditTimeOffRequestGeneralForm: FC<EditPolicyGeneralFormProps> = ({
  request,
  onSubmit,
}) => {
  const {
    id,
    member,
    policy,
    startDate,
    startTime,
    endDate,
    endTime,
    allDay,
    timeRequested,
    reason,
    timeZone,
  } = request;

  const policies = useSelector(policiesListSelector);
  const policiesOptions = useMemo(() => {
    return policies.map((policy) => ({
      label: policy.name,
      value: String(policy.id),
    }));
  }, [policies]);
  const initialValues = {
    memberForRequest: { label: member.name, value: member.id },
    policy: { label: policy.name, value: policy.id },
    startDate: dateToTimeZone(startDate, timeZone.name),
    startTime: dateToTimeZone(startTime, timeZone.name),
    endDate: dateToTimeZone(endDate, timeZone.name),
    endTime: dateToTimeZone(endTime, timeZone.name),
    timeRequested: timeRequested,
    allDay: allDay,
    reason: reason,
    selectedMemberTimeZoneName: timeZone?.name || '',
  };
  const validationSchema = Yup.object().shape({
    policy: Yup.object().shape({
      label: Yup.string().required('Policy is required'),
      value: Yup.string().required('Policy is required'),
    }),
    startDate: Yup.date()
      .typeError('Start date is required')
      .required('Start date is required'),
    endDate: Yup.date()
      .typeError('End date is required')
      .required('End date is required')
      .when('startDate', (startDate) => {
        return Yup.date()
          .min(startDate, 'End date must be after Start date')
          .typeError('End date is required');
      }),
    startTime: Yup.date()
      .typeError('Start time is required')
      .required('Start time is required'),
    endTime: Yup.date()
      .typeError('End time is required')
      .required('End time is required')
      .when('startTime', (startTime) => {
        return Yup.date().when('allDay', {
          is: false,
          then: Yup.date().min(
            setTimeInDate(
              new Date(startTime),
              new Date(startTime).getHours(),
              new Date(startTime).getMinutes() + 1
            ),
            'Invalid time'
          ),
        });
      }),
    timeRequested: Yup.string()
      .matches(hoursColonMinutesFormat, 'HH:MM time format must be specifying')
      .test('maxHours', `Maximum time exceeded (99999:59)`, (value) => {
        if (!value) return true;
        const hours = value.split(':')[0];
        return Number(hours) < MaximumHoursForTimeOffRequest;
      }),
    reason: Yup.string()
      .max(
        MaximumCommentLengthValidationError,
        `Maximum length ${MaximumCommentLengthValidationError} characters`
      )
      .required('Please add a comment'),
  });

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const formattedStartTime = zonedTimeToUtc(
            values.allDay
              ? setTimeInDate(values.startDate, 0, 0)
              : new Date(values.startTime),
            values.selectedMemberTimeZoneName
          ).toISOString();
          const formattedStartDate = zonedTimeToUtc(
            new Date(values.startDate),
            values.selectedMemberTimeZoneName
          ).toISOString();
          const formattedEndTime = zonedTimeToUtc(
            values.allDay
              ? setTimeInDate(values.endDate, 23, 59)
              : new Date(values.endTime),
            values.selectedMemberTimeZoneName
          ).toISOString();
          const formattedEndDate = zonedTimeToUtc(
            new Date(values.endDate),
            values.selectedMemberTimeZoneName
          ).toISOString();
          onSubmit({
            id: Number(id),
            memberId: member.id,
            timeRequested: values.timeRequested,
            policyId: values.policy.value,
            startTime: formattedStartTime,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            endTime: formattedEndTime,
            allDay: values.allDay,
            reason: values.reason,
          });
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <FetchPoliciesOptionsComponent />

            <span className="input-label">Member</span>
            <div className={styles.memberField}>{member.name}</div>

            <CustomFormikSelect
              label={'Policy*'}
              name="policy"
              options={policiesOptions}
            />

            <div className={styles.datesFlex}>
              <CustomFormikDatePicker
                label="Start date"
                name="startDate"
                onCalendarClose={() => {
                  if (formik.values.allDay) {
                    const startValue = new Date(formik.values.startDate);
                    const endValue = new Date(formik.values.endDate);
                    if (startValue.getTime() > endValue.getTime()) {
                      formik.setFieldValue('endDate', formik.values.startDate);
                    }
                  }
                }}
              />

              {!formik.values.allDay && (
                <DependentStartTimePicker label="Time" name="startTime" />
              )}
            </div>

            <div className={styles.datesFlex}>
              <CustomFormikDatePicker
                label="End date"
                name="endDate"
                minDate={(() => {
                  return new Date(formik.values.startDate);
                })()}
              />
              {!formik.values.allDay && (
                <DependentEndTimePicker label="Time" name="endTime" />
              )}
            </div>

            <DependentTimeZoneField />

            <CustomFormikCheckbox label="All day" name="allDay" />

            <div className={styles.hoursAmountWrapper}>
              <DependentTimeRequestedField
                label={'Total time off requested'}
                name="timeRequested"
                placeholder={'HH:MM'}
              />
            </div>

            <FormikTextArea label="Comments*" name="reason" />

            <div className={styles.submitWrapper}>
              <Button
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting || !formik.isValid}
                preloader={formik.isSubmitting}
              >
                Save changes
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditTimeOffRequestGeneralForm;
