import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import CustomDatePickerInput from './CustomDatePickerInput';
import styles from './CustomDateRangePicker.module.scss';

interface CustomDateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  handleOnChange: (dates: [Date, Date]) => void;
}

export interface IDateRangeData {
  start: Date;
  end: Date;
}

const CustomDateRangePicker: FC<CustomDateRangePickerProps> = ({
  startDate,
  endDate,
  handleOnChange,
}) => {
  return (
    <>
      <div className={styles.wrapper}>
        <DatePicker
          selected={startDate}
          onChange={handleOnChange}
          startDate={startDate}
          endDate={endDate}
          maxDate={new Date()}
          customInput={<CustomDatePickerInput />}
          selectsRange
          showPopperArrow={false}
          dateFormat="MMM dd, yyyy"
          monthsShown={1}
          popperPlacement="bottom-start"
        />
      </div>
    </>
  );
};

export default CustomDateRangePicker;
