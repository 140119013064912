import React, { DetailedHTMLProps, FC, TextareaHTMLAttributes } from 'react';
import styles from '../TextArea.module.scss';

export interface TextAreaProps
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label?: string;
  error?: boolean;
  errorMessage?: string;
}

const TextArea: FC<TextAreaProps> = ({
  label,
  error,
  errorMessage,
  ...props
}) => {
  return (
    <div className={styles.root}>
      {label && <label className={styles.label}>{label}</label>}
      <textarea
        className={`form-control ${error && 'errorInput'}`}
        {...props}
      />
      {error && errorMessage && (
        <span className={styles.errorMessage}>{errorMessage}</span>
      )}
    </div>
  );
};

export default TextArea;
