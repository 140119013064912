import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'app/store';
import * as Sentry from '@sentry/react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import './index.scss';
import './sentry';
import App from 'app/App';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Sentry.ErrorBoundary>
      <App />
    </Sentry.ErrorBoundary>
  </Provider>
);
