import React from 'react';
import { useSelector } from 'react-redux';
import EmptyStatePlaceholder from 'components/shared/EmptyStatePlaceholder';
import ActiveMembersTable from 'components/members/ActiveMembersTable';
import Preloader from 'components/shared/Preloader';
import {
  isLoadingMembersSelector,
  listMembersSelector,
} from 'features/members/membersSlice';

const ActiveMembers = () => {
  const isLoading = useSelector(isLoadingMembersSelector);
  const members = useSelector(listMembersSelector);

  const emptyMessage = 'No members here.';

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          {members.length ? (
            <ActiveMembersTable />
          ) : (
            <EmptyStatePlaceholder>{emptyMessage}</EmptyStatePlaceholder>
          )}
        </>
      )}
    </>
  );
};

export default ActiveMembers;
