import React from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import EditPolicyMembersForm from 'components/timeOff/EditPolicyMembersForm';
import Preloader from 'components/shared/Preloader';
import { membersFilterListSelector } from 'features/membersFilterList/membersFilterListSlice';
import { IPolicyInfoUpdatingData } from 'features/policyInfo/policyInfoTypes';
import { changePolicyInfo } from 'features/policyInfo/policyInfoActions';
import {
  isLoadingPolicyInfoSelector,
  policyInfoSelector,
} from 'features/policyInfo/policyInfoSlice';

const EditPolicyMembers = () => {
  const isLoading = useSelector(isLoadingPolicyInfoSelector);
  const policy = useSelector(policyInfoSelector);
  const members = useSelector(membersFilterListSelector);
  const dispatch = useAppDispatch();

  const handleSubmit = (data: IPolicyInfoUpdatingData) => {
    dispatch(changePolicyInfo(data));
  };

  return isLoading ? (
    <Preloader />
  ) : (
    <EditPolicyMembersForm
      organizationMembers={members}
      policyId={policy.id}
      currentMembers={policy.members}
      onSubmit={handleSubmit}
    />
  );
};

export default EditPolicyMembers;
