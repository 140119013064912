import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  IFetchPoliciesParams,
  PoliciesListInitialState,
} from './policiesListTypes';
import {
  getPoliciesList,
  getPoliciesListByMemberId,
  getPoliciesListInfiniteScroll,
} from './policiesListActions';
import { isFulfilledAction, isRejectedAction } from '../sliceHelpers';

const initialState: PoliciesListInitialState = {
  policies: [],
  filter: { searchValue: '', skip: 0, take: 20 },
  totalCount: 0,
  isLoading: false,
  errorMessage: '',
};

export const policiesListSlice = createSlice({
  name: '@@policiesList',
  initialState,
  reducers: {
    setPoliciesFilter: (state, action: PayloadAction<IFetchPoliciesParams>) => {
      state.filter = action.payload;
    },
    removePolicyReducer: (state, action: PayloadAction<number>) => {
      state.policies = state.policies.filter((p) => p.id !== action.payload);
      state.totalCount -= 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPoliciesList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPoliciesListByMemberId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPoliciesList.fulfilled, (state, action) => {
      state.policies = action.payload.list;
      state.totalCount = action.payload.count;
    });
    builder.addCase(
      getPoliciesListInfiniteScroll.fulfilled,
      (state, action) => {
        state.policies.push(...action.payload.list);
        state.totalCount = action.payload.count;
      }
    );
    builder.addCase(getPoliciesListByMemberId.fulfilled, (state, action) => {
      state.policies = action.payload.list;
      state.totalCount = action.payload.count;
    });
    builder.addMatcher(isFulfilledAction, (state) => {
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const { setPoliciesFilter, removePolicyReducer } =
  policiesListSlice.actions;
export const policiesListSelector = createSelector(
  [(state: RootState) => state.policiesList.policies],
  (policies) => policies
);
export const loadingPoliciesListSelector = createSelector(
  [(state: RootState) => state.policiesList.isLoading],
  (isLoading) => isLoading
);
export const filterPoliciesListSelector = createSelector(
  [(state: RootState) => state.policiesList.filter],
  (filter) => filter
);
export const itemsTotalCountPoliciesListSelector = createSelector(
  [(state: RootState) => state.policiesList.totalCount],
  (totalCount) => totalCount
);
