import React, { FC, ReactNode, useRef, useState } from 'react';
import { useClickOutside } from 'hooks/useClickOutside';
import { ThreeDotsIcon } from 'assets/icons/ThreeDotsIcon';
import styles from './ActionsPopover.module.scss';

interface ActionsPopoverProps {
  children: ReactNode;
  disableEditing?: boolean;
}

const ActionsPopover: FC<ActionsPopoverProps> = ({
  children,
  disableEditing,
}) => {
  const [open, setOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(popoverRef, () => setOpen(false));

  return (
    <div ref={popoverRef} className={styles.wrapper}>
      <button
        onClick={() => setOpen(!open)}
        className={`${styles.btn} ${open ? styles.activeBtn : ''} ${
          disableEditing ? styles.disabledBtn : ''
        }`}
        disabled={disableEditing}
      >
        <ThreeDotsIcon />
      </button>
      {open && (
        <div className={styles.popover}>
          <ul
            onClick={() => {
              setOpen(false);
            }}
          >
            {children}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ActionsPopover;
