import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { AppDispatch } from 'app/store';
import {
  approveRequest,
  deleteRequest,
  denyRequest,
  fetchTimeOffRequestsList,
  resubmitRequest,
  sendTimeOffRequest,
} from 'api/timeOffrequestsList';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import {
  IFetchTimeOffRequestsParams,
  IRequestingTimeOffData,
  IRequestsCount,
  ITimeOffRequestListItem,
  ITimeOffRequestsActionsParams,
} from './timeOffRequestsListTypes';

export const requestTimeOff = createAsyncThunk<
  void,
  { data: IRequestingTimeOffData; filter: IFetchTimeOffRequestsParams },
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@timeOffRequests/getPoliciesList', async ({ data, filter }, thunkApi) => {
  try {
    const res = await sendTimeOffRequest(data);
    if (res.status === 200) {
      toast.success('Request sent.');
      thunkApi.dispatch(getTimeOffRequestsList(filter));
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const getTimeOffRequestsList = createAsyncThunk<
  {
    requests: ITimeOffRequestListItem[];
    requestsCount: IRequestsCount;
    totalCount: number;
  },
  IFetchTimeOffRequestsParams,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@timeOffRequests/getTimeOffRequestsList', async (params, thunkApi) => {
  try {
    const res = await fetchTimeOffRequestsList(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const getTimeOffRequestsListInfiniteScroll = createAsyncThunk<
  {
    requests: ITimeOffRequestListItem[];
    requestsCount: IRequestsCount;
    totalCount: number;
  },
  IFetchTimeOffRequestsParams,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>(
  '@@timeOffRequests/getTimeOffRequestsListInfiniteScroll',
  async (params, thunkApi) => {
    try {
      const res = await fetchTimeOffRequestsList(params);
      return res.data;
    } catch (err) {
      return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
    }
  }
);

export const approveTimeOffRequest = createAsyncThunk<
  void,
  ITimeOffRequestsActionsParams,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@timeOffRequests/approveTimeOffRequest', async (params, thunkApi) => {
  try {
    const res = await approveRequest(params.requestId);
    if (res.status === 200) {
      thunkApi.dispatch(getTimeOffRequestsList(params.filter));
      toast.success('Request approved.');
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const denyTimeOffRequest = createAsyncThunk<
  void,
  ITimeOffRequestsActionsParams,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@timeOffRequests/denyTimeOffRequest', async (params, thunkApi) => {
  try {
    const res = await denyRequest(params.requestId);
    if (res.status === 200) {
      thunkApi.dispatch(getTimeOffRequestsList(params.filter));
      toast.success('Request denied.');
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const resubmitTimeOffRequest = createAsyncThunk<
  void,
  ITimeOffRequestsActionsParams,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@timeOffRequests/resubmitTimeOffRequest', async (params, thunkApi) => {
  try {
    const res = await resubmitRequest(params.requestId);
    if (res.status === 200) {
      thunkApi.dispatch(getTimeOffRequestsList(params.filter));
      toast.success('Request resubmitted.');
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const removeTimeOffRequest = createAsyncThunk<
  void,
  ITimeOffRequestsActionsParams,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@timeOffRequests/removeTimeOffRequest', async (params, thunkApi) => {
  try {
    const res = await deleteRequest(params.requestId);
    if (res.status === 204) {
      thunkApi.dispatch(getTimeOffRequestsList(params.filter));
      toast.success('Request deleted.');
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
