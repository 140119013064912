import React, { forwardRef } from 'react';

interface CustomCustomFormikTimePickerInputProps
  extends React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    React.AriaAttributes {
  error?: boolean;
}

const CustomFormikTimePickerInput = forwardRef<
  HTMLInputElement,
  CustomCustomFormikTimePickerInputProps
>(({ value, error, onClick }, ref) => (
  <input
    type="text"
    className={`form-control ${error ? 'errorInput' : ''}`}
    style={{ color: 'black', width: '100px' }}
    onClick={onClick}
    ref={ref}
    value={value}
    readOnly
  />
));

export default CustomFormikTimePickerInput;
