import React from 'react';

const UserIcon = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6855 33.6374C12.698 32.3916 14.6785 31.4413 16.6271 30.7864C18.5758 30.1315 20.7001 29.8041 23.0001 29.8041C25.3001 29.8041 27.4403 30.1315 29.4209 30.7864C31.4015 31.4413 33.382 32.3916 35.3626 33.6374C36.7681 31.9124 37.7584 30.1874 38.3334 28.4624C38.9084 26.7374 39.1959 24.9166 39.1959 22.9999C39.1959 18.3999 37.6466 14.5506 34.548 11.452C31.4494 8.35342 27.6001 6.80411 23.0001 6.80411C18.4001 6.80411 14.5508 8.35342 11.4521 11.452C8.35354 14.5506 6.80423 18.3999 6.80423 22.9999C6.80423 24.9166 7.09972 26.7374 7.69069 28.4624C8.28166 30.1874 9.27993 31.9124 10.6855 33.6374ZM23.0001 24.5333C21.1153 24.5333 19.5421 23.8944 18.2803 22.6166C17.0185 21.3388 16.3876 19.7576 16.3876 17.8729C16.3876 16.0201 17.0265 14.4548 18.3042 13.177C19.582 11.8992 21.1473 11.2604 23.0001 11.2604C24.8848 11.2604 26.4581 11.8992 27.7199 13.177C28.9817 14.4548 29.6126 16.0361 29.6126 17.9208C29.6126 19.7736 28.9737 21.3388 27.6959 22.6166C26.4181 23.8944 24.8528 24.5333 23.0001 24.5333ZM23.0001 42.4541C20.2848 42.4541 17.7452 41.943 15.3813 40.9208C13.0174 39.8986 10.957 38.501 9.20007 36.7281C7.44312 34.9552 6.06152 32.8947 5.05527 30.5468C4.04902 28.1989 3.5459 25.6833 3.5459 22.9999C3.5459 20.2847 4.05701 17.7531 5.07923 15.4052C6.10145 13.0572 7.49902 11.0048 9.27194 9.24786C11.0449 7.49092 13.1053 6.10133 15.4532 5.07911C17.8011 4.05689 20.3327 3.54578 23.048 3.54578C25.7313 3.54578 28.2469 4.05689 30.5949 5.07911C32.9428 6.10133 34.9952 7.49092 36.7521 9.24786C38.5091 11.0048 39.8987 13.0652 40.9209 15.4291C41.9431 17.793 42.4542 20.3166 42.4542 22.9999C42.4542 25.6833 41.9431 28.1989 40.9209 30.5468C39.8987 32.8947 38.5091 34.9552 36.7521 36.7281C34.9952 38.501 32.9348 39.8986 30.5709 40.9208C28.207 41.943 25.6834 42.4541 23.0001 42.4541Z"
        fill="#637381"
      />
    </svg>
  );
};

export default UserIcon;
