import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { IProjectShortInfo } from 'features/projectInfo/projectInfoTypes';
import { USER_ROLE } from 'features/user/userTypes';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import Button from 'components/shared/Button';
import styles from './InvitationForm.module.scss';

interface InvitationFormProps {
  organizationProjects: IProjectShortInfo[];
  closeInviteForm: () => void;
  onSubmit: (
    userEmail: string,
    role: keyof typeof USER_ROLE,
    projectsIds: number[]
  ) => void;
}

const roleOptions = [
  { value: USER_ROLE.Manager, label: 'Manager' },
  { value: USER_ROLE.Admin, label: 'Admin' },
  { value: USER_ROLE.User, label: 'User' },
  { value: USER_ROLE.Viewer, label: 'Viewer' },
];

const InvitationForm: FC<InvitationFormProps> = ({
  organizationProjects,
  closeInviteForm,
  onSubmit,
}) => {
  const InitialValues = {
    email: '',
    payAmount: '',
    role: USER_ROLE.User,
    projectsIds: [] as number[],
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Required field'),
    payAmount: Yup.number().typeError('You must specify a number'),
  });

  const projectsOptions = organizationProjects.map((p) => ({
    value: String(p.id),
    label: p.name,
  }));

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        validationSchema={validationSchema}
        onSubmit={(
          values: typeof InitialValues,
          { setSubmitting, resetForm }
        ) => {
          onSubmit(values.email, values.role, values.projectsIds);
          setSubmitting(false);
          resetForm();
        }}
      >
        {(formik) => (
          <Form>
            <ValidationTextInput
              label="Email"
              name="email"
              type="email"
              placeholder="Add an email"
            />

            <CustomFormikSelect
              label={'Role'}
              name="role"
              options={roleOptions}
            />

            <CustomFormikSelect
              label={'Projects'}
              name="projectsIds"
              options={projectsOptions}
              isMulti
            />

            <div className={styles.controlWrapper}>
              <Button
                onClick={() => {
                  formik.resetForm();
                  closeInviteForm();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting}
                preloader={formik.isSubmitting}
              >
                Send
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InvitationForm;
