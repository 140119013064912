import React, { FC } from 'react';
import { IoInformationCircleOutline, IoWarningOutline } from 'react-icons/io5';
import styles from './InfoBanner.module.scss';

interface InfoBannerProps {
  variant: 'info' | 'warning';
  message: string;
}

const InfoBanner: FC<InfoBannerProps> = ({ variant, message }) => {
  return (
    <div
      className={`${styles.wrapper} ${
        variant === 'warning' && styles.warningWrapper
      } ${variant === 'info' && styles.infoWrapper}`}
    >
      <div className={styles.icon}>
        {variant === 'info' && <IoInformationCircleOutline />}
        {variant === 'warning' && <IoWarningOutline />}
      </div>
      <div
        className={`${styles.content} ${
          variant === 'warning' && styles.warningContent
        } ${variant === 'info' && styles.infoContent}`}
      >
        {message}
      </div>
    </div>
  );
};

export default InfoBanner;
