import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import Button from 'components/shared/Button';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import { IMemberShortInfo } from 'features/memberInfo/memberInfoTypes';
import styles from './EditProjectMembersForm.module.scss';

interface EditProjectMembersFormProps {
  organizationMembers: IMemberShortInfo[];
  projectId: number;
  currentMembers: IMemberShortInfo[];
  onSubmit: (projectId: number, membersIds: number[]) => void;
}

const EditProjectMembersForm: FC<EditProjectMembersFormProps> = ({
  organizationMembers,
  projectId,
  currentMembers,
  onSubmit,
}) => {
  const InitialValues = {
    members: currentMembers.map((m) => ({
      value: String(m.id),
      label: m.name,
    })),
  };

  const membersOptions = organizationMembers.map((m) => ({
    value: String(m.id),
    label: m.name,
  }));

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(
            projectId,
            values.members.map((m) => Number(m.value))
          );
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <CustomFormikSelect
              label={'Project members'}
              name="members"
              options={membersOptions}
              isMulti
            />

            <div className={styles.submitWrapper}>
              <Button
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting}
                preloader={formik.isSubmitting}
              >
                Save changes
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditProjectMembersForm;
