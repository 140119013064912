import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from 'components/shared/ActionsPopover/ActionsPopover.module.scss';

interface PoliciesActionsListProps {
  policyId: number;
  handleRemove: (
    id: number,
    hasApproved: boolean,
    hasUnapproved: boolean
  ) => void;
  hasApproved: boolean;
  hasUnapproved: boolean;
}

const PoliciesActionsList: FC<PoliciesActionsListProps> = ({
  policyId,
  handleRemove,
  hasApproved,
  hasUnapproved,
}) => {
  const handleRemovePolicy = () => {
    handleRemove(policyId, hasApproved, hasUnapproved);
  };

  return (
    <>
      <li>
        <Link
          to={`/time-off/policies/${policyId}/edit`}
          className={styles.popoverLink}
        >
          Edit policy
        </Link>
      </li>
      <li>
        <button onClick={handleRemovePolicy} aria-label="Remove policy">
          Delete policy
        </button>
      </li>
    </>
  );
};

export default PoliciesActionsList;
