import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { IPolicyInfo, IPolicyInfoSliceInitialState } from './policyInfoTypes';
import { changePolicyInfo, getPolicyInfo } from './policyInfoActions';
import { IMemberShortInfo } from '../memberInfo/memberInfoTypes';
import { isRejectedAction } from '../sliceHelpers';

const initialState: IPolicyInfoSliceInitialState = {
  info: { members: [] as IMemberShortInfo[] } as IPolicyInfo,
  isLoading: false,
};

export const policyInfoSlice = createSlice({
  name: '@@policyInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPolicyInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changePolicyInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPolicyInfo.fulfilled, (state, action) => {
      state.info = action.payload;
      state.isLoading = false;
    });
    builder.addCase(changePolicyInfo.fulfilled, (state, action) => {
      state.info = action.payload;
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state) => {
      state.isLoading = false;
    });
  },
});

export const policyInfoSelector = createSelector(
  [(state: RootState) => state.policyInfo.info],
  (policyInfo) => policyInfo
);

export const isLoadingPolicyInfoSelector = createSelector(
  [(state: RootState) => state.policyInfo.isLoading],
  (isLoading) => isLoading
);
