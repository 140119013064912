import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { isRejectedAction } from '../sliceHelpers';
import { getHolidaysFilterList } from './holiadysFilterListActions';
import { IHolidaysFilterListSliceInitialState } from './holidaysFilterListTypes';

const initialState: IHolidaysFilterListSliceInitialState = {
  list: [],
  isLoading: false,
  errorMessage: '',
};

export const holidaysFilterListSlice = createSlice({
  name: '@@holidaysFilterList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHolidaysFilterList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getHolidaysFilterList.fulfilled, (state, action) => {
      state.list = action.payload.list;
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const clientsFilterListSelector = createSelector(
  [(state: RootState) => state.holidaysFilterList.list],
  (list) => list
);
