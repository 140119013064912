import React, { FC, ReactNode } from 'react';
import * as ReactDOM from 'react-dom';
import UseMount from 'hooks/useMount';
import ModalLayout from './ModalLayout';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  children?: ReactNode;
  transparentOverlay?: boolean;
}

const Modal: FC<ModalProps> = ({
  open,
  onClose,
  title,
  children,
  transparentOverlay,
}) => {
  const { mounted } = UseMount({ opened: open, animationTime: 300 });

  if (!mounted) return null;

  return ReactDOM.createPortal(
    <>
      <ModalLayout
        open={open}
        title={title}
        transparentOverlay={transparentOverlay}
        onClose={onClose}
      >
        {children}
      </ModalLayout>
    </>,
    document.getElementById('portal') as HTMLElement
  );
};

export default Modal;
