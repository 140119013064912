import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { fetchHolidayInfo, updateHolidayInfo } from 'api/holidayInfo';
import { AppDispatch } from 'app/store';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import { IHolidayInfo, IHolidayInfoUpdatingData } from './holidayInfoTypes';

export const getHolidayInfo = createAsyncThunk<
  IHolidayInfo,
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@holidayInfo/getHolidayInfo', async (holidayId, thunkApi) => {
  try {
    const res = await fetchHolidayInfo(holidayId);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const changeHolidayInfo = createAsyncThunk<
  IHolidayInfo,
  IHolidayInfoUpdatingData,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@holidayInfo/changeHolidayInfo', async (holidayInfo, thunkApi) => {
  try {
    const res = await updateHolidayInfo(holidayInfo);
    if (res.status === 200) {
      toast.success('Holiday successfully updated!');
    }
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
