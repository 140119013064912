import React, { FC } from 'react';
import styles from './ActivityBadge.module.scss';

interface ActivityBadgeProps {
  isActive: boolean;
  outlined?: boolean;
}

const ActivityBadge: FC<ActivityBadgeProps> = ({ isActive, outlined }) => {
  return (
    <>
      {outlined ? (
        <div
          className={`${styles.root} ${
            isActive ? styles.outlinedActive : styles.outlinedInactive
          }`}
        >
          {isActive ? 'Active' : 'Inactive'}
        </div>
      ) : (
        <div
          className={`${styles.root} ${
            isActive ? styles.active : styles.inactive
          }`}
        >
          {isActive ? 'Active' : 'Inactive'}
        </div>
      )}
    </>
  );
};

export default ActivityBadge;
