import client from './axiosInstance';
import {
  IAddTimeEntryData,
  IEditTimeEntryData,
  IGetMemberEventsData,
} from 'features/timeEntries/timeEntriesTypes';

export const fetchMemberTimeEntries = async (data: IGetMemberEventsData) => {
  const startDateQuery = encodeURIComponent(data.start);
  const endDateQuery = encodeURIComponent(data.end);
  const timeZoneQuery = encodeURIComponent(data.timeZoneName);
  return await client.get(
    `/time-entries/${data.id}?startDate=${startDateQuery}&endDate=${endDateQuery}&timeZone=${timeZoneQuery}`
  );
};

export const createTimeEntry = async (data: IAddTimeEntryData) => {
  return await client.post(`/time-entries/${data.memberId}`, {
    projectId: data.projectId,
    start: data.start,
    note: data.note,
    isBillable: data.isBillable,
    end: data.end,
  });
};

export const editTimeEntry = async (data: IEditTimeEntryData) => {
  return await client.put(
    `/time-entries/${data.memberId}/${data.timeEntryId}`,
    {
      projectId: data.projectId,
      start: data.start,
      end: data.end,
      isBillable: data.isBillable,
      note: data.note,
      timeZone: data.timeZone,
    }
  );
};

export const removeTimeEntry = async (timeEntryId: number) => {
  return await client.delete(`/time-entries/${timeEntryId}`);
};
