import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { useAppDispatch } from 'app/store';
import MemberProfileCard from 'components/members/MemberProfileCard';
import Preloader from 'components/shared/Preloader';
import PageTabs from 'components/shared/PageTabs';
import BackTo from 'components/shared/BackTo';
import { getProjectsFilterList } from 'features/projectsFilterList/projectsFilterListActions';
import { getMemberInfo } from 'features/memberInfo/memberInfoActions';
import {
  isLoadingMemberInfoSelector,
  organizationMemberInfoSelector,
} from 'features/memberInfo/memberInfoSlice';

const EditMemberInfo: FC = () => {
  const currentMember = useSelector(organizationMemberInfoSelector);
  const isLoading = useSelector(isLoadingMemberInfoSelector);
  const dispatch = useAppDispatch();
  const { memberId } = useParams();
  const tabsData = [
    {
      path: `/members/${memberId}/edit`,
      name: 'About',
    },
    {
      path: `/members/${memberId}/edit/projects`,
      name: 'Projects',
    },
    {
      path: `/members/${memberId}/edit/roles`,
      name: 'Roles',
    },
    {
      path: `/members/${memberId}/edit/payments`,
      name: 'Payments',
    },
  ];

  useEffect(() => {
    memberId && dispatch(getMemberInfo(Number(memberId)));
    dispatch(getProjectsFilterList());
  }, []);

  return (
    <>
      <BackTo title="Members" path={`/members`} />
      {isLoading ? (
        <Preloader />
      ) : (
        currentMember && (
          <>
            <MemberProfileCard memberInfo={currentMember} />
            <PageTabs tabsData={tabsData} />
          </>
        )
      )}
      <Outlet />
    </>
  );
};

export default EditMemberInfo;
