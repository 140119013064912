import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch } from 'app/store';
import Navbar from './Navbar';
import Content from './Content';
import Sidebar from './Sidebar';
import { getUserInfo } from 'features/user/userActions';
import styles from './AppLayout.module.scss';

const AppLayout: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const hideMobileSidebar = () => setMobileSidebarOpen(false);
  const showMobileSidebar = () => setMobileSidebarOpen(true);

  useEffect(() => {
    mobileSidebarOpen && hideMobileSidebar();
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.sidebarWrapper}>
        <Sidebar
          isOnMobileOpen={mobileSidebarOpen}
          closeMobileSidebar={hideMobileSidebar}
        />
      </div>
      <div className={styles.navbarAndContentWrapper}>
        <Navbar onMobileMenuClick={showMobileSidebar} />
        <Content />
      </div>

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default AppLayout;
