import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import styles from './CustomTimePicker.module.scss';

interface CustomTimePickerProps {
  label: string;
  selected: Date;
  onChange: (time: Date) => void;
  isToday?: boolean;
  timeFilter?: boolean;
  isNextDay?: boolean;
  isFuture?: boolean;
  now?: Date;
}

const CustomTimePicker: FC<CustomTimePickerProps> = ({
  label,
  selected,
  onChange,
  isToday,
  isNextDay,
  isFuture,
  now,
}) => {
  const filterFutureTime = (time: Date) => {
    if (isToday && now) {
      if (now.getHours() === time.getHours()) {
        return now.getMinutes() > time.getMinutes();
      }
      return now.getHours() > time.getHours();
    }
    return true;
  };

  const handleTimeChange = (date: Date) => {
    try {
      return onChange(date);
    } catch (e) {
      if (
        e instanceof TypeError &&
        e.message.includes("Cannot read properties of null (reading 'getDate')")
      ) {
        return;
      } else console.error(e);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        {label && <label className="input-label">{label}</label>}
        <div className={styles.picker}>
          <DatePicker
            selected={selected}
            onChange={handleTimeChange}
            showTimeSelect
            showTimeSelectOnly
            filterTime={filterFutureTime}
            timeIntervals={30}
            timeCaption="Time"
            dateFormat="hh:mm aa"
            showPopperArrow={false}
            className={`form-control ${
              (isNextDay && isFuture) || isFuture || false ? 'errorInput' : ''
            }`}
          />
          {isNextDay && <div className={styles.nextDayTooltip}>Next day</div>}
          {((isNextDay && isFuture) || isFuture) && (
            <span className={styles.futureError}>*Cannot be in the future</span>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomTimePicker;
