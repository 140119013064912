import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useAppDispatch } from 'app/store';
import PageTabs from 'components/shared/PageTabs';
import BackTo from 'components/shared/BackTo';
import { getMembersFilterList } from 'features/membersFilterList/membersFilterListActions';
import { getPolicyInfo } from 'features/policyInfo/policyInfoActions';

const EditPolicy = () => {
  const dispatch = useAppDispatch();
  const { policyId } = useParams();

  const tabsData = [
    {
      path: `/time-off/policies/${policyId}/edit`,
      name: 'General',
    },
    {
      path: `/time-off/policies/${policyId}/edit/members`,
      name: 'Members',
    },
  ];

  useEffect(() => {
    policyId && dispatch(getPolicyInfo(Number(policyId)));
    dispatch(getMembersFilterList());
  }, []);

  return (
    <>
      <BackTo title="Policies" path={`/time-off/policies`} />
      <PageTabs tabsData={tabsData} />
      <Outlet />
    </>
  );
};

export default EditPolicy;
