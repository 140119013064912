import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from 'components/shared/ActionsPopover/ActionsPopover.module.scss';

interface ClientsActionsListProps {
  clientId: number;
  handleDelete: (id: number) => void;
}

const ClientsActionsList: FC<ClientsActionsListProps> = ({
  clientId,
  handleDelete,
}) => {
  const handleRemoveClient = () => {
    handleDelete(clientId);
  };

  return (
    <>
      <li>
        <Link to={`/clients/${clientId}/edit`} className={styles.popoverLink}>
          Edit client info
        </Link>
      </li>
      <li>
        <button onClick={handleRemoveClient} aria-label="Delete client">
          Delete client
        </button>
      </li>
    </>
  );
};

export default ClientsActionsList;
