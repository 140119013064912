import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import { OIDCInfoUserSelector } from 'features/user/userSlice';
import { signInRedirect } from 'features/user/userActions';

interface AuthProtectedRouteProps {
  element: ReactNode;
}

const AuthProtectedRoute: FC<AuthProtectedRouteProps> = ({ element }) => {
  const user = useSelector(OIDCInfoUserSelector);
  const dispatch = useAppDispatch();

  if (!user) {
    dispatch(signInRedirect());
  }

  return <>{!user ? null : element}</>;
};

export default AuthProtectedRoute;
