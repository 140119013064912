import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import EditBillRateForm from 'components/members/editPayments/EditBillRateForm';
import EditPayRateForm from 'components/members/editPayments/EditPayRateForm';
import ToggleButton from 'components/shared/ToggleButton';
import Preloader from 'components/shared/Preloader';
import { IMemberPaymentInfo } from 'features/memberInfo/memberInfoTypes';
import { changeMemberInfo } from 'features/memberInfo/memberInfoActions';
import {
  isLoadingMemberInfoSelector,
  organizationMemberInfoSelector,
} from 'features/memberInfo/memberInfoSlice';
import PaymentHistoryTable from './PaymentHistoryTable';

const EditPayments: FC = () => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(isLoadingMemberInfoSelector);
  const memberInfo = useSelector(organizationMemberInfoSelector);
  const [selectedValue, setSelectedValue] = useState<'Pay rate' | 'Bill rate'>(
    'Pay rate'
  );

  const handleChangePayments = (
    memberId: number,
    values: IMemberPaymentInfo
  ) => {
    dispatch(changeMemberInfo({ id: memberId, ...values }));
  };

  const currentPayRateChangingDate = memberInfo?.payRateHistory.find(
    (el) => el.status === 'Current'
  )?.startDate;

  const currentBillRateChangingDate = memberInfo?.billRateHistory.find(
    (el) => el.status === 'Current'
  )?.startDate;

  return isLoading || !memberInfo ? (
    <Preloader />
  ) : (
    <>
      <ToggleButton
        value1="Pay rate"
        value2="Bill rate"
        onValue1Click={() => setSelectedValue('Pay rate')}
        onValue2Click={() => setSelectedValue('Bill rate')}
        selectedValue={selectedValue}
        label="Payment rates"
      />
      {selectedValue === 'Pay rate' ? (
        <EditPayRateForm
          memberId={memberInfo.id}
          payType={memberInfo.payType}
          payAmount={memberInfo.payAmount}
          memberTimeZone={memberInfo.timeZone}
          currentRateChangingDate={currentPayRateChangingDate || ''}
          onSubmit={handleChangePayments}
        />
      ) : (
        <EditBillRateForm
          memberId={memberInfo.id}
          billRate={memberInfo.billRate}
          memberTimeZone={memberInfo.timeZone}
          currentRateChangingDate={currentBillRateChangingDate || ''}
          onSubmit={handleChangePayments}
        />
      )}
      {selectedValue === 'Pay rate' ? (
        <PaymentHistoryTable
          title="Pay rate history"
          memberId={memberInfo.id}
          data={memberInfo.payRateHistory}
          memberTimeZone={memberInfo.timeZone}
        />
      ) : (
        <PaymentHistoryTable
          title="Bill rate history"
          memberId={memberInfo.id}
          data={memberInfo.billRateHistory}
          memberTimeZone={memberInfo.timeZone}
        />
      )}
    </>
  );
};

export default EditPayments;
