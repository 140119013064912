import client from './axiosInstance';
import {
  IFetchTimeOffRequestsParams,
  IRequestingTimeOffData,
} from 'features/timeOffRequestsList/timeOffRequestsListTypes';

export const sendTimeOffRequest = async (data: IRequestingTimeOffData) => {
  return await client.post(`/time-off/requests`, {
    ...data,
  });
};

export const fetchTimeOffRequestsList = async (
  params: IFetchTimeOffRequestsParams
) => {
  const { path, members, skip, take } = params;
  const membersIds = members?.map((option) => option.value);

  const pathQuery = path.length > 0 ? `${path}` : '';

  const memberFilterQuery =
    membersIds && membersIds.length > 0
      ? `&search=${membersIds.join('&search=')}`
      : '';
  const skipQuery = `skip=${skip}`;
  const takeQuery = `&take=${take}`;
  const queryStr = `?${skipQuery}${takeQuery}${memberFilterQuery}`;

  return await client.get(`/time-off/requests${pathQuery}${queryStr}`);
};

export const approveRequest = async (requestId: number) => {
  return await client.get(`/time-off/requests/${requestId}/approve`);
};

export const resubmitRequest = async (requestId: number) => {
  return await client.get(`/time-off/requests/${requestId}/resubmit`);
};

export const denyRequest = async (requestId: number) => {
  return await client.get(`/time-off/requests/${requestId}/deny`);
};

export const deleteRequest = async (requestId: number) => {
  return await client.delete(`/time-off/requests/${requestId}`);
};
