import React from 'react';
import { useSelector } from 'react-redux';
import EmptyStatePlaceholder from 'components/shared/EmptyStatePlaceholder';
import ClientsTable from 'components/clients/ClientsTable';
import Preloader from 'components/shared/Preloader';
import {
  isLoadingClientsListSelector,
  clientsListSelector,
} from 'features/clientsList/clientsListSlice';

const ActiveClients = () => {
  const clients = useSelector(clientsListSelector);
  const isLoading = useSelector(isLoadingClientsListSelector);

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          {clients.length > 0 ? (
            <ClientsTable />
          ) : (
            <EmptyStatePlaceholder>There are no clients.</EmptyStatePlaceholder>
          )}
        </>
      )}
    </>
  );
};

export default ActiveClients;
