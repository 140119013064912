import client from './axiosInstance';
import { IProjectInfoUpdatingData } from '../features/projectInfo/projectInfoTypes';

export const fetchOrganizationProjectInfo = async (projectId: number) => {
  return await client.get(`/projects/${projectId}`);
};

export const updateProjectInfo = async (data: IProjectInfoUpdatingData) => {
  return await client.patch(`/projects/${data.id}`, {
    ...data,
  });
};
