import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  ClientsListSliceInitialState,
  IFetchClientsParams,
} from './clientsListTypes';
import {
  getClientsList,
  getClientsListInfiniteScroll,
} from './clientsListActions';
import { isFulfilledAction, isRejectedAction } from '../sliceHelpers';

const initialState: ClientsListSliceInitialState = {
  clients: [],
  totalCount: 0,
  filter: { searchValue: '', skip: 0, take: 20 },
  isLoading: false,
  errorMessage: '',
};

export const clientsListSlice = createSlice({
  name: '@@clientsList',
  initialState,
  reducers: {
    setClientsPageFilter: (
      state,
      action: PayloadAction<IFetchClientsParams>
    ) => {
      state.filter = action.payload;
    },
    removeClientReducer: (state, action: PayloadAction<number>) => {
      state.clients = state.clients.filter((c) => c.id !== action.payload);
      state.totalCount -= 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientsList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClientsList.fulfilled, (state, action) => {
      state.clients = action.payload.list;
      state.totalCount = action.payload.count;
    });
    builder.addCase(getClientsListInfiniteScroll.fulfilled, (state, action) => {
      state.clients.push(...action.payload.list);
      state.totalCount = action.payload.count;
    });
    builder.addMatcher(isFulfilledAction, (state) => {
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const { setClientsPageFilter, removeClientReducer } =
  clientsListSlice.actions;
export const clientsListSelector = createSelector(
  [(state: RootState) => state.clientsList.clients],
  (list) => list
);
export const isLoadingClientsListSelector = createSelector(
  [(state: RootState) => state.clientsList.isLoading],
  (isLoading) => isLoading
);
export const itemsTotalCountClientsListSelector = createSelector(
  [(state: RootState) => state.clientsList.totalCount],
  (totalCount) => totalCount
);
export const filterClientsListSelector = createSelector(
  [(state: RootState) => state.clientsList.filter],
  (filter) => filter
);
