import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { format } from 'date-fns';
import * as Yup from 'yup';
import CustomFormikDatePicker from 'components/shared/forms/datePickers/CustomFormikDatePicker';
import CustomFormikCheckbox from 'components/shared/forms/CustomFormikCheckbox';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import { SelectOption } from 'components/shared/forms/CustomFormikSelect/CustomFormikSelect';
import Button from 'components/shared/Button';
import { MaxLength50Symbols } from 'constants/formsConstants';
import { membersFilterListSelector } from 'features/membersFilterList/membersFilterListSlice';
import { IAddingHolidayData } from 'features/holidaysList/holidaysListTypes';
import styles from './AddTimeOffHolidayForm.module.scss';

interface AddTimeOffHolidayFormProps {
  handleCLose: () => void;
  handleSubmit: (data: IAddingHolidayData) => void;
}

const AddTimeOffHolidayForm: FC<AddTimeOffHolidayFormProps> = ({
  handleCLose,
  handleSubmit,
}) => {
  const members = useSelector(membersFilterListSelector);

  const initialValues = {
    name: '',
    members: [] as SelectOption[],
    newMembersAutoAdding: false,
    date: String(new Date()),
    paidHours: '0',
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(
        MaxLength50Symbols,
        `Maximum length ${MaxLength50Symbols} characters`
      )
      .required('Required field'),
    paidHours: Yup.number()
      .typeError('Paid hours must be a `number`')
      .max(24, 'Must be no more than 24 hours')
      .required('Required field'),
  });

  const membersOptions = useMemo(() => {
    return members.map((member) => ({
      label: member.name,
      value: String(member.id),
    }));
  }, [members]);

  return (
    <>
      <div className={styles.wrapper}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(
            values: typeof initialValues,
            { setSubmitting, resetForm }
          ) => {
            handleSubmit({
              name: values.name,
              date: format(new Date(values.date), 'yyyy-MM-dd'),
              newMembersAutoAdding: values.newMembersAutoAdding,
              membersIds: values.members.map((member) => Number(member.value)),
              paidHours: Number(values.paidHours),
            });
            setSubmitting(false);
            resetForm();
            handleCLose();
          }}
        >
          {(formik) => (
            <Form>
              <ValidationTextInput
                label="Holiday name"
                name="name"
                type="text"
                placeholder="Enter a name of the holiday"
              />

              <div className={styles.membersWrapper}>
                <CustomFormikSelect
                  label={'Members'}
                  name="members"
                  options={membersOptions}
                  isMulti
                />
                <span
                  onClick={() => {
                    formik.setFieldValue(
                      'members',
                      members.map((member) => ({
                        label: member.name,
                        value: String(member.id),
                      }))
                    );
                  }}
                >
                  Select all
                </span>
              </div>

              <CustomFormikCheckbox
                label="Add new members to this holiday"
                name="newMembersAutoAdding"
              />

              <div className={styles.flex}>
                <CustomFormikDatePicker label="Holiday date" name="date" />

                <div className={styles.paidHoursWrapper}>
                  <ValidationTextInput
                    label="Paid hours"
                    name="paidHours"
                    type="text"
                  />
                </div>
              </div>

              <div className={styles.controlWrapper}>
                <Button
                  type="button"
                  onClick={() => {
                    formik.resetForm();
                    handleCLose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={formik.isSubmitting || !formik.isValid}
                  preloader={formik.isSubmitting}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddTimeOffHolidayForm;
