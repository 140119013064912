import client from './axiosInstance';
import { IUpdateUserInfo, IUser } from 'features/user/userTypes';

export const login = async (userData: IUser) => {
  const { data } = await client.post('/login', {
    ...userData,
  });
  return data;
};

export const updateUserData = async (userInfo: IUpdateUserInfo) => {
  return await client.patch('/users', {
    ...userInfo,
  });
};

export const fetchUserData = async () => {
  return await client.get('/users');
};

export const fetchTimeZonesList = async () => {
  return await client.get('/users/time-zone');
};
