import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import Button from 'components/shared/Button';
import { IPolicyInfoUpdatingData } from 'features/policyInfo/policyInfoTypes';
import { IMemberShortInfo } from 'features/memberInfo/memberInfoTypes';
import styles from './EditPolicyMembersForm.module.scss';

interface EditPolicyMembersFormProps {
  organizationMembers: IMemberShortInfo[];
  policyId: number;
  currentMembers: IMemberShortInfo[];
  onSubmit: (data: IPolicyInfoUpdatingData) => void;
}

const EditPolicyMembersForm: FC<EditPolicyMembersFormProps> = ({
  organizationMembers,
  policyId,
  currentMembers,
  onSubmit,
}) => {
  const InitialValues = {
    members:
      currentMembers.length > 0
        ? currentMembers.map((m) => ({
            value: String(m.id),
            label: m.name,
          }))
        : null,
  };

  const membersOptions = organizationMembers.map((m) => ({
    value: String(m.id),
    label: m.name,
  }));

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit({
            id: policyId,
            membersIds:
              values.members && values.members.length > 0
                ? values.members.map((m) => Number(m.value))
                : [],
          });
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <div className={styles.membersWrapper}>
              <CustomFormikSelect
                label={'Policy members'}
                name="members"
                options={membersOptions}
                placeholder="Add members..."
                isMulti
              />
              <span
                onClick={() => {
                  formik.setFieldValue(
                    'members',
                    organizationMembers.map((member) => ({
                      label: member.name,
                      value: String(member.id),
                    }))
                  );
                }}
              >
                Select all
              </span>
            </div>

            <div className={styles.submitWrapper}>
              <Button
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting}
                preloader={formik.isSubmitting}
              >
                Save changes
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditPolicyMembersForm;
