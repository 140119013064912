import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { useAppDispatch } from 'app/store';
import { organizationMemberInfoSelector } from 'features/memberInfo/memberInfoSlice';
import { getMemberInfo } from 'features/memberInfo/memberInfoActions';
import { IRequestTimeOffFormValues } from './RequestTimeOffForm';

const DependentTimeZoneField: FC = () => {
  const userInfo = useSelector(organizationMemberInfoSelector);
  const dispatch = useAppDispatch();
  const {
    values: { memberForRequest },
    setFieldValue,
  } = useFormikContext<IRequestTimeOffFormValues>();

  useEffect(() => {
    if (memberForRequest.value) {
      dispatch(getMemberInfo(Number(memberForRequest.value)));
    }
  }, [memberForRequest.value]);

  useEffect(() => {
    if (userInfo) {
      setFieldValue('selectedMemberTimeZoneName', userInfo.timeZone);
    }
  }, [userInfo?.timeZone]);

  return (
    <div>
      <span className="input-label">{userInfo ? userInfo.timeZone : ''}</span>
    </div>
  );
};

export default DependentTimeZoneField;
