import { useNavigate } from 'react-router-dom';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import PulseLoader from 'react-spinners/PulseLoader';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { useAppDispatch } from 'app/store';
import ClientsActionsList from 'components/clients/ClientsActionsList';
import ActionsPopover from 'components/shared/ActionsPopover';
import ConfirmDialog from 'components/shared/ConfirmDialog';
import { IClientShortInfo } from 'features/clientInfo/clientInfoTypes';
import {
  clientsListSelector,
  filterClientsListSelector,
  isLoadingClientsListSelector,
  itemsTotalCountClientsListSelector,
} from 'features/clientsList/clientsListSlice';
import {
  deleteClient,
  getClientsListInfiniteScroll,
} from 'features/clientsList/clientsListActions';
import styles from 'components/shared/tables/infiniteScrollTable.module.scss';

const clientsTableColumns = [{ name: 'Client name' }, { name: 'Actions' }];

const ClientsTable: FC = () => {
  const clientsTotalCount = useSelector(itemsTotalCountClientsListSelector);
  const isClientsLoading = useSelector(isLoadingClientsListSelector);
  const clientsPageFilter = useSelector(filterClientsListSelector);
  const infiniteListItems = useSelector(clientsListSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [clientIdForDelete, setClientIdForDelete] = useState<
    number | undefined
  >(undefined);
  const [confirmDeleteClient, setConfirmDeleteClient] = useState(false);
  const handleDelete = (id: number) => {
    setClientIdForDelete(id);
    setConfirmDeleteClient(true);
  };

  const hasNextPage = infiniteListItems.length < clientsTotalCount;
  const loadNextPage = async () => {
    dispatch(
      getClientsListInfiniteScroll({
        ...clientsPageFilter,
        skip: infiniteListItems.length,
        take: 20,
      })
    );
  };
  const itemCount = hasNextPage
    ? infiniteListItems.length + 1
    : infiniteListItems.length;
  const loadMoreItems = isClientsLoading ? () => {} : loadNextPage;
  const isItemLoaded = (index: number) =>
    !hasNextPage || index < infiniteListItems.length;

  const ClientsTableItem: FC<ListChildComponentProps<IClientShortInfo[]>> = (
    props
  ) => {
    const content = infiniteListItems[props.index];

    if (!isItemLoaded(props.index))
      return (
        <div style={props.style} className={styles.loadNextTableIndicator}>
          <PulseLoader
            color="#0168fa"
            size={8}
            margin={5}
            speedMultiplier={1}
          />
        </div>
      );

    return (
      <div style={props.style}>
        <div className={`${styles.fRow} ${styles.fBodyRow}`}>
          <div
            className={`${styles.fCell} ${styles.fBodyCell}`}
            onClick={() => navigate(`/clients/${content.id}/edit`)}
          >
            <span className={`${styles.fBodyCellContent}`}>{content.name}</span>
          </div>
          <div className={`${styles.fCell} ${styles.fBodyCell}`}>
            <ActionsPopover>
              <ClientsActionsList
                clientId={content.id}
                handleDelete={handleDelete}
              />
            </ActionsPopover>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.fTable}>
        <div className={`${styles.fRow} ${styles.fHeaderRow}`}>
          {clientsTableColumns.map((col) => (
            <div
              key={col.name}
              className={`${styles.fCell} ${styles.fHeaderCell}`}
            >
              {col.name}
            </div>
          ))}
        </div>
        <div className={styles.fTableBody}>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    itemCount={itemCount}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    height={height}
                    overscanCount={1}
                    itemSize={70}
                    width={width}
                  >
                    {ClientsTableItem}
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </div>
      </div>
      {clientIdForDelete && (
        <ConfirmDialog
          open={confirmDeleteClient}
          title="Confirm"
          description="Delete this client? You will not be able to undo the changes."
          acceptBtnText="Delete"
          declineBtnText="Cancel"
          onClose={() => setConfirmDeleteClient(false)}
          onAccept={() => {
            dispatch(deleteClient(clientIdForDelete));
          }}
        />
      )}
    </>
  );
};

export default ClientsTable;
