import React, { FC } from 'react';
import styles from './Modal.module.scss';

interface ModalOverlayProps {
  transparentOverlay?: boolean;
  closing: boolean;
}

const ModalOverlay: FC<ModalOverlayProps> = ({
  transparentOverlay,
  closing,
}) => {
  return (
    <div
      className={`${styles.overlay} ${
        transparentOverlay ? styles.transparentOverlay : styles.shadowOverlay
      } ${closing ? styles.overlayOnClose : styles.overlayOnOpen}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  );
};

export default ModalOverlay;
