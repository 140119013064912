import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from 'components/shared/ActionsPopover/ActionsPopover.module.scss';

interface ProjectsActionsListProps {
  projectId: number;
  handleRemove: (id: number) => void;
}

const ProjectsActionsList: FC<ProjectsActionsListProps> = ({
  projectId,
  handleRemove,
}) => {
  const handleRemoveProject = () => {
    handleRemove(projectId);
  };

  return (
    <>
      <li>
        <Link to={`/projects/${projectId}/edit`} className={styles.popoverLink}>
          Edit project
        </Link>
      </li>
      <li onClick={handleRemoveProject}>
        <button aria-label={'Delete project'}>Delete project</button>
      </li>
    </>
  );
};

export default ProjectsActionsList;
