import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from 'components/shared/ActionsPopover/ActionsPopover.module.scss';

interface HolidaysActionsListProps {
  holidayId: number;
  handleRemove: (id: number) => void;
}

const HolidaysActionsList: FC<HolidaysActionsListProps> = ({
  holidayId,
  handleRemove,
}) => {
  const handleRemoveHoliday = () => {
    handleRemove(holidayId);
  };

  return (
    <>
      <li>
        <Link
          to={`/time-off/holidays/${holidayId}/edit`}
          className={styles.popoverLink}
        >
          Edit holiday
        </Link>
      </li>
      <li>
        <button onClick={handleRemoveHoliday} aria-label="Delete holiday">
          Delete holiday
        </button>
      </li>
    </>
  );
};

export default HolidaysActionsList;
