import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ChapterNavigationCard.module.scss';

export interface ChapterNavigationCardProps {
  title: string;
  link: string;
  description: string;
}

const ChapterNavigationCard: FC<ChapterNavigationCardProps> = ({
  title,
  link,
  description,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={styles.wrapper}
      onClick={() => {
        navigate(`${link}`);
      }}
    >
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default ChapterNavigationCard;
