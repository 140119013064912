import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deletePayRateHistoryEntry,
  fetchOrganizationMemberInfo,
  updateMemberInfo,
  updatePayRateHistoryEntry,
} from 'api/memberInfo';
import { AppDispatch } from 'app/store';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import {
  IDeleteRateHistoryEntryData,
  IMemberInfo,
  IMemberInfoUpdatingData,
  UpdateRateHistoryEntryData,
} from './memberInfoTypes';
import { toast } from 'react-toastify';

export const getMemberInfo = createAsyncThunk<
  IMemberInfo,
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@memberInfo/getMemberInfo', async (memberId, thunkApi) => {
  try {
    const res = await fetchOrganizationMemberInfo(memberId);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const changeMemberInfo = createAsyncThunk<
  IMemberInfo,
  IMemberInfoUpdatingData,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@memberInfo/changeMemberInfo', async (memberInfo, thunkApi) => {
  try {
    const res = await updateMemberInfo(memberInfo);
    if (res.status === 200) {
      toast.success('Member info successfully updated!');
    }
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const changeRateHistoryEntry = createAsyncThunk<
  void,
  { memberId: number; payload: UpdateRateHistoryEntryData },
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>(
  '@@memberInfo/changeRateHistoryEntry',
  async ({ memberId, payload }, thunkApi) => {
    try {
      const res = await updatePayRateHistoryEntry({ memberId, payload });
      if (res.status === 204) {
        toast.success('Rate history entry successfully updated!');
        thunkApi.dispatch(getMemberInfo(memberId));
      }
    } catch (err) {
      return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
    }
  }
);

export const deleteRateHistoryEntry = createAsyncThunk<
  void,
  IDeleteRateHistoryEntryData,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@memberInfo/deleteRateHistoryEntry', async (data, thunkApi) => {
  try {
    const res = await deletePayRateHistoryEntry(data);
    if (res.status === 204) {
      toast.success('Rate history entry deleted!');
      thunkApi.dispatch(getMemberInfo(data.memberId));
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
