import React, { FC } from 'react';
import { useAppDispatch } from 'app/store';
import {
  deleteMemberInvite,
  resendMemberInvite,
} from 'features/invitesList/invitesListActions';

interface InvitedMembersActionsListProps {
  memberId: number;
}

const InvitedMembersActionsList: FC<InvitedMembersActionsListProps> = ({
  memberId,
}) => {
  const dispatch = useAppDispatch();

  const handleResend = () => {
    dispatch(resendMemberInvite(memberId));
  };
  const handleDelete = () => {
    dispatch(deleteMemberInvite(memberId));
  };

  return (
    <>
      <li>
        <button onClick={handleResend} aria-label="Resend invitation">
          Resend Invite
        </button>
      </li>
      <li>
        <button onClick={handleDelete} aria-label="Delete invitation">
          Delete Invite
        </button>
      </li>
    </>
  );
};

export default InvitedMembersActionsList;
