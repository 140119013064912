import client from './axiosInstance';
import { IPolicyInfoUpdatingData } from '../features/policyInfo/policyInfoTypes';

export const fetchPolicyInfo = async (policyId: number) => {
  return await client.get(`/time-off/policies/${policyId}`);
};

export const updatePolicyInfo = async (data: IPolicyInfoUpdatingData) => {
  return await client.patch(`/time-off/policies/${data.id}`, {
    ...data,
  });
};
