import React, { FC } from 'react';
import styles from './EmptyStatePlaceholder.module.scss';

interface EmptyStatePlaceholderProps {
  children: string;
}

const EmptyStatePlaceholder: FC<EmptyStatePlaceholderProps> = ({
  children,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.message}>{children}</div>
    </div>
  );
};

export default EmptyStatePlaceholder;
