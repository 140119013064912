import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { TimeOffRequestInfoInitialState } from './timeOffRequestInfoTypes';
import { getTimeOffRequestInfo } from './timeOffRequestInfoActions';

const initialState: TimeOffRequestInfoInitialState = {
  info: null,
  isLoading: false,
  errorMessage: '',
};

export const timeOffRequestInfoSlice = createSlice({
  name: '@@timeOffRequestInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTimeOffRequestInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTimeOffRequestInfo.fulfilled, (state, action) => {
      state.info = action.payload;
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addCase(getTimeOffRequestInfo.rejected, (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    });
  },
});

export const timeOffRequestInfoSelector = createSelector(
  [(state: RootState) => state.timeOffRequestInfo.info],
  (requestInfo) => requestInfo
);

export const isLoadingTimeOffRequestInfoSelector = createSelector(
  [(state: RootState) => state.timeOffRequestInfo.isLoading],
  (isLoading) => isLoading
);
