import React, { FC } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PulseLoader from 'react-spinners/PulseLoader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import InvitedMembersActionsList from 'components/members/InvitedMembersActionsList';
import ActionsPopover from 'components/shared/ActionsPopover';
import { getInvitesInfiniteScroll } from 'features/invitesList/invitesListActions';
import {
  IInvite,
  InvitesTableHeadings,
} from 'features/invitesList/invitesListTypes';
import {
  filterInvitesSelector,
  isLoadingInvitesSelector,
  itemsTotalCountInvitesSelector,
  listInvitesSelector,
} from 'features/invitesList/invitesListSlice';

import styles from 'components/shared/tables/infiniteScrollTable.module.scss';

const invitedMembersTableColumns: Record<'name', InvitesTableHeadings>[] = [
  { name: 'User email' },
  { name: 'Role' },
  { name: 'Status' },
  { name: 'Actions' },
];

const InvitedMembersTable: FC = () => {
  const invitesTotalCount = useSelector(itemsTotalCountInvitesSelector);
  const isInvitesLoading = useSelector(isLoadingInvitesSelector);
  const invitesPageFilter = useSelector(filterInvitesSelector);
  const infiniteListItems = useSelector(listInvitesSelector);
  const dispatch = useAppDispatch();

  const hasNextPage = infiniteListItems.length < invitesTotalCount;
  const loadNextPage = async () => {
    dispatch(
      getInvitesInfiniteScroll({
        ...invitesPageFilter,
        skip: infiniteListItems.length,
        take: 20,
      })
    );
  };

  const itemCount = hasNextPage
    ? infiniteListItems.length + 1
    : infiniteListItems.length;
  const loadMoreItems = isInvitesLoading ? () => {} : loadNextPage;
  const isItemLoaded = (index: number) =>
    !hasNextPage || index < infiniteListItems.length;

  const MembersTableItem: FC<ListChildComponentProps<IInvite[]>> = (props) => {
    const content = infiniteListItems[props.index];

    if (!isItemLoaded(props.index))
      return (
        <div style={props.style} className={styles.loadNextTableIndicator}>
          <PulseLoader
            color="#0168fa"
            size={8}
            margin={5}
            speedMultiplier={1}
          />
        </div>
      );

    return (
      <div style={props.style}>
        <div className={`${styles.fRow} ${styles.fBodyRow}`}>
          <div className={`${styles.fCell} ${styles.fBodyCell}`}>
            <span
              id={content.id + 'email'}
              className={`${styles.fBodyCellContent}`}
              data-tooltip-content={content.email}
            >
              {content.email}
            </span>
            <ReactTooltip
              anchorId={content.id + 'email'}
              style={{ zIndex: 1 }}
            />
          </div>
          <div className={`${styles.fCell} ${styles.fBodyCell}`}>
            <span className={`${styles.fBodyCellContent}`}>{content.role}</span>
          </div>

          <div className={`${styles.fCell} ${styles.fBodyCell}`}>
            <span className={`${styles.fBodyCellContent} `}>
              {content.status}
            </span>
          </div>
          <div className={`${styles.fCell} ${styles.fBodyCell}`}>
            <ActionsPopover>
              <InvitedMembersActionsList memberId={content.id} />
            </ActionsPopover>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.fTable}>
        <div className={`${styles.fRow} ${styles.fHeaderRow}`}>
          {invitedMembersTableColumns.map((col) => (
            <div
              key={col.name}
              className={`${styles.fCell} ${styles.fHeaderCell}`}
            >
              {col.name}
            </div>
          ))}
        </div>
        <div className={styles.fTableBody}>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    itemCount={itemCount}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                    height={height}
                    overscanCount={1}
                    itemSize={70}
                    width={width}
                  >
                    {MembersTableItem}
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </div>
      </div>
    </>
  );
};

export default InvitedMembersTable;
