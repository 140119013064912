import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import Button from 'components/shared/Button';
import { IHolidayInfoUpdatingData } from 'features/holidayInfo/holidayInfoTypes';
import { IMemberShortInfo } from 'features/memberInfo/memberInfoTypes';
import styles from './EditHolidayMembersForm.module.scss';

interface EditHolidayMembersFormProps {
  organizationMembers: IMemberShortInfo[];
  holidayId: number;
  currentMembers: IMemberShortInfo[];
  onSubmit: (data: IHolidayInfoUpdatingData) => void;
}

const EditHolidayMembersForm: FC<EditHolidayMembersFormProps> = ({
  organizationMembers,
  holidayId,
  currentMembers,
  onSubmit,
}) => {
  const InitialValues = {
    members: currentMembers.map((m) => ({
      value: String(m.id),
      label: m.name,
    })),
  };

  const membersOptions = organizationMembers.map((m) => ({
    value: String(m.id),
    label: m.name,
  }));

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit({
            id: holidayId,
            membersIds: values.members.map((m) => Number(m.value)),
          });
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <div className={styles.membersWrapper}>
              <CustomFormikSelect
                label={'Holiday Members'}
                name="members"
                options={membersOptions}
                isMulti
              />
              <span
                onClick={() => {
                  formik.setFieldValue(
                    'members',
                    organizationMembers.map((member) => ({
                      label: member.name,
                      value: String(member.id),
                    }))
                  );
                }}
              >
                Select all
              </span>
            </div>

            <div className={styles.submitWrapper}>
              <Button
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting}
                preloader={formik.isSubmitting}
              >
                Save changes
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditHolidayMembersForm;
