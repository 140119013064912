import client from './axiosInstance';
import {
  IAddingProjectData,
  IFetchProjectsParams,
} from 'features/projectsList/projectsListTypes';

export const fetchOrganizationProjectsList = async (
  params: IFetchProjectsParams | void
) => {
  if (params) {
    const { searchValue, skip, take } = params;
    const skipQuery = `skip=${skip}`;
    const takeQuery = `&take=${take}`;
    const searchStringQuery =
      searchValue && searchValue.length > 0 ? `&search=${searchValue}` : '';
    const queryStr = `?${skipQuery}${takeQuery}${searchStringQuery}`;

    return await client.get(`/projects${queryStr}`);
  } else {
    return await client.get(`/projects`);
  }
};

export const addNewProject = async (data: IAddingProjectData) => {
  return await client.post(`/projects`, {
    ...data,
  });
};

export const deleteProject = async (projectId: number) => {
  return await client.delete(`projects/${projectId}`);
};
