import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useAppDispatch } from 'app/store';
import PageTabs from 'components/shared/PageTabs';
import BackTo from 'components/shared/BackTo';
import { getMembersFilterList } from 'features/membersFilterList/membersFilterListActions';
import { getClientsFilterList } from 'features/clientsFilterList/clientsFilterListActions';
import { getProjectInfo } from 'features/projectInfo/projectInfoActions';

const EditProject = () => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const tabsData = [
    {
      path: `/projects/${projectId}/edit`,
      name: 'General',
    },
    {
      path: `/projects/${projectId}/edit/members`,
      name: 'Members',
    },
  ];

  useEffect(() => {
    projectId && dispatch(getProjectInfo(Number(projectId)));
    dispatch(getMembersFilterList());
    dispatch(getClientsFilterList());
  }, []);

  return (
    <>
      <BackTo title="Projects" path={`/projects`} />
      <PageTabs tabsData={tabsData} />
      <Outlet />
    </>
  );
};

export default EditProject;
