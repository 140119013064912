import React, { FC, HTMLAttributes } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { FieldHookConfig, useField } from 'formik';
import styles from './PhoneNumberValidationInput.module.scss';

export interface CustomSelectProps
  extends HTMLAttributes<HTMLSelectElement | HTMLInputElement> {
  label?: string;
}

const PhoneNumberValidationInput: FC<
  CustomSelectProps & FieldHookConfig<string>
> = ({ label, ...props }) => {
  const [field, , helpers] = useField(props.name);

  return (
    <div>
      {label && (
        <label className="input-label" htmlFor={props.id || props.name}>
          {label}
        </label>
      )}
      <div>
        <PhoneInput
          {...field}
          international
          withCountryCallingCode
          countryCallingCodeEditable
          value={field.value}
          defaultCountry={'US'}
          onChange={(value) => {
            helpers.setValue(value);
          }}
          placeholder="Enter a phone number"
          className={styles.wrapper}
          maxLength={17}
          limitMaxLength
        />
      </div>
    </div>
  );
};

export default PhoneNumberValidationInput;
