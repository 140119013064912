import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/store';
import { fetchPoliciesFilterList } from 'api/policiesFilterList';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import { IClientShortInfo } from '../clientInfo/clientInfoTypes';

export const getPoliciesFilterList = createAsyncThunk<
  IClientShortInfo[],
  void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@policiesFilterList/getPoliciesFilterList', async (_, thunkApi) => {
  try {
    const res = await fetchPoliciesFilterList();
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
