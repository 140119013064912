import React, { FC } from 'react';
import { TextAreaProps } from '../TextArea/TextArea';
import { FieldHookConfig, useField } from 'formik';
import styles from '../TextArea.module.scss';

const FormikTextArea: FC<TextAreaProps & FieldHookConfig<string>> = ({
  label,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <div className={styles.root}>
      {label && (
        <label className={styles.label} id={field.name}>
          {label}
        </label>
      )}
      <textarea
        className={`form-control ${
          meta.touched && !!meta.error && 'errorInput'
        }`}
        {...field}
        {...props}
      />
      {meta.touched && !!meta.error ? (
        <div className={styles.errorMessage}>*{meta.error}</div>
      ) : null}
    </div>
  );
};

export default FormikTextArea;
