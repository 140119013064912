import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Preloader from 'components/shared/Preloader';
import {
  isLoadingUserSelector,
  selectInfoUserSelector,
} from 'features/user/userSlice';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
  const userInfo = useSelector(selectInfoUserSelector);
  const isLoading = useSelector(isLoadingUserSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo) {
      navigate('/');
    }
  }, [userInfo]);

  return <>{isLoading && <Preloader />}</>;
};

export default Auth;
