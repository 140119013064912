import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { IoChevronBack } from 'react-icons/io5';
import styles from './BackTo.module.scss';

interface BackToProps {
  title: string;
  path: string;
}

const BackTo: FC<BackToProps> = ({ title, path }) => {
  return (
    <Link to={path} className={styles.goBack}>
      <IoChevronBack size="16px" />
      {title}
    </Link>
  );
};

export default BackTo;
