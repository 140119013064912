import React, { FC, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import CustomFormikTimePickerInput from 'components/shared/forms/timePickers/CustomFormikTimePicker/CustomFormikTimePickerInput';
import { IRequestTimeOffFormValues } from './RequestTimeOffForm';
import { setTimeInDate } from 'helpers/setTimeInDate';
import { CustomFormikTimePickerProps } from 'components/shared/forms/timePickers/CustomFormikTimePicker/CustomFormikTimePicker';
import styles from 'components/shared/forms/timePickers/CustomFormikTimePicker/CustomFormikTimePickerInput.module.scss';

const DependentStartTimePicker: FC<
  CustomFormikTimePickerProps & FieldHookConfig<string>
> = ({ label, onCalendarClose, ...props }) => {
  const {
    values: { startDate, startTime, endDate },
    setFieldValue,
  } = useFormikContext<IRequestTimeOffFormValues>();
  const [field, meta, helpers] = useField(props);

  useEffect(() => {
    const startTimeHours = new Date(startTime).getHours();
    const startTimeMinutes = new Date(startTime).getMinutes();

    setFieldValue(
      'startTime',
      String(
        setTimeInDate(new Date(startDate), startTimeHours, startTimeMinutes)
      )
    );

    const startValue = new Date(startDate);
    const endValue = new Date(endDate);
    if (startValue.getTime() >= endValue.getTime()) {
      setFieldValue('endDate', startDate);
    }
  }, [startDate]);

  return (
    <>
      <div className={styles.wrapper} style={{ width: '200px' }}>
        {label && (
          <label className="input-label" htmlFor={field.name}>
            {label}
          </label>
        )}
        <DatePicker
          selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            helpers.setValue(String(val));
          }}
          customInput={
            <CustomFormikTimePickerInput error={!!meta.error && meta.touched} />
          }
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          dateFormat="h:mm aa"
          showPopperArrow={false}
          id={field.name}
          onCalendarClose={onCalendarClose}
        />
        {meta.touched && !!meta.error ? (
          <div className={styles.errorMessage}>*{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};

export default DependentStartTimePicker;
