import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { AmountsOwedReportsSliceInitialState } from './amountsOwedReportsTypes';
import { getAmountsOwedReports } from './amountsOwedReportsActions';
import { isRejectedAction } from '../sliceHelpers';

const initialState: AmountsOwedReportsSliceInitialState = {
  groupsTableHeaders: [],
  groups: [],
  summary: [],
  isLoading: false,
  isReportDownloading: false,
  errorMessage: '',
};

export const amountsOwedReportsSlice = createSlice({
  name: '@@amountsOwed',
  initialState,
  reducers: {
    setIsReportDownloadingState: (state, action: PayloadAction<boolean>) => {
      state.isReportDownloading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAmountsOwedReports.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAmountsOwedReports.fulfilled, (state, action) => {
      state.groupsTableHeaders = action.payload.groupsTableHeaders;
      state.groups = action.payload.groups;
      state.summary = action.payload.summary;
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const { setIsReportDownloadingState } = amountsOwedReportsSlice.actions;

export const reportsGroupsListAmountsOwedSelector = createSelector(
  [(state: RootState) => state.amountsOwedReports.groups],
  (groups) => groups
);

export const groupsTableHeadersAmountsOwedSelector = createSelector(
  [(state: RootState) => state.amountsOwedReports.groupsTableHeaders],
  (headers) => headers
);

export const summaryAmountsOwedSelector = createSelector(
  [(state: RootState) => state.amountsOwedReports.summary],
  (summary) => summary
);

export const isLoadingAmountsOwedSelector = createSelector(
  [(state: RootState) => state.amountsOwedReports.isLoading],
  (isLoading) => isLoading
);

export const isReportDownloadingAmountsOwedSelector = createSelector(
  [(state: RootState) => state.amountsOwedReports.isReportDownloading],
  (isReportDownloading) => isReportDownloading
);
