import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getMembers, getMembersInfiniteScroll } from './membersActions';
import {
  ChangeMemberActivityStatusParams,
  IFetchMembersParams,
  IMemberSliceInitialState,
  IMembersListItem,
} from './membersTypes';
import { isFulfilledAction, isRejectedAction } from '../sliceHelpers';

const initialState: IMemberSliceInitialState = {
  members: [] as IMembersListItem[],
  totalCount: 0,
  filter: {
    showDeleted: false,
    searchValue: '',
    skip: 0,
    take: 20,
  },
  isLoading: false,
  errorMessage: '',
};

export const membersSlice = createSlice({
  name: '@@membersList',
  initialState,
  reducers: {
    setMembersPageFilter: (
      state,
      action: PayloadAction<IFetchMembersParams>
    ) => {
      state.filter = action.payload;
    },
    changeMemberStatusReducer: (
      state,
      action: PayloadAction<ChangeMemberActivityStatusParams>
    ) => {
      const index = state.members.findIndex(
        (el) => el.id === action.payload.memberId
      );
      state.members[index].status = !action.payload.currentStatus;
    },
    removeMemberReducer: (state, action: PayloadAction<number>) => {
      state.members = state.members.filter((m) => m.id !== action.payload);
      state.totalCount -= 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMembers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMembers.fulfilled, (state, action) => {
      state.members = action.payload.list;
      state.totalCount = action.payload.count;
    });
    builder.addCase(getMembersInfiniteScroll.fulfilled, (state, action) => {
      state.members.push(...action.payload.list);
      state.totalCount = action.payload.count;
    });
    builder.addMatcher(isFulfilledAction, (state) => {
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const {
  setMembersPageFilter,
  changeMemberStatusReducer,
  removeMemberReducer,
} = membersSlice.actions;
export const isLoadingMembersSelector = createSelector(
  [(state: RootState) => state.members.isLoading],
  (isLoading) => isLoading
);
export const listMembersSelector = createSelector(
  [(state: RootState) => state.members.members],
  (list) => list
);
export const itemsTotalCountMembersSelector = createSelector(
  [(state: RootState) => state.members.totalCount],
  (totalCount) => totalCount
);
export const filterMembersSelector = createSelector(
  [(state: RootState) => state.members.filter],
  (filter) => filter
);
