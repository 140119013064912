//last day included
import { setTimeInDate } from './setTimeInDate';

export const daysCountInDateRange = (
  startDate: string | Date,
  endDate: string | Date
): number => {
  const startTime = new Date(setTimeInDate(endDate, 0, 0)).getTime();
  const endTime = new Date(setTimeInDate(startDate, 0, 0)).getTime();
  if (startTime === endTime) return 1;
  const difference = startTime - endTime;
  return Math.ceil(difference / (1000 * 3600 * 24) + 1);
};
