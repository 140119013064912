import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  IFetchProjectsParams,
  ProjectsListInitialState,
} from './projectsListTypes';
import {
  getProjectsList,
  getProjectsListInfiniteScroll,
} from './projectsListActions';
import { isFulfilledAction, isRejectedAction } from '../sliceHelpers';

const initialState: ProjectsListInitialState = {
  projects: [],
  totalCount: 0,
  filter: {
    searchValue: '',
    skip: 0,
    take: 20,
  },
  isLoading: false,
  errorMessage: '',
};

export const projectsListSlice = createSlice({
  name: '@@projects',
  initialState,
  reducers: {
    setProjectsPageFilter: (
      state,
      action: PayloadAction<IFetchProjectsParams>
    ) => {
      state.filter = action.payload;
    },
    removeProjectReducer: (state, action: PayloadAction<number>) => {
      state.projects = state.projects.filter((p) => p.id !== action.payload);
      state.totalCount -= 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectsList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProjectsList.fulfilled, (state, action) => {
      state.projects = action.payload.list;
      state.totalCount = action.payload.count;
    });
    builder.addCase(
      getProjectsListInfiniteScroll.fulfilled,
      (state, action) => {
        state.projects.push(...action.payload.list);
        state.totalCount = action.payload.count;
      }
    );
    builder.addMatcher(isFulfilledAction, (state) => {
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const { setProjectsPageFilter, removeProjectReducer } =
  projectsListSlice.actions;
export const isLoadingProjectsListSelector = createSelector(
  [(state: RootState) => state.projectsList.isLoading],
  (isLoading) => isLoading
);
export const projectsProjectsListSelector = createSelector(
  [(state: RootState) => state.projectsList.projects],
  (projects) => projects
);
export const itemsTotalCountProjectsListSelector = createSelector(
  [(state: RootState) => state.projectsList.totalCount],
  (totalCount) => totalCount
);
export const filterProjectsListSelector = createSelector(
  [(state: RootState) => state.projectsList.filter],
  (filter) => filter
);
