import React, { FC } from 'react';
import { numToCurrencyWithLocale } from 'helpers/numToCurrencyWithLocale';
import { IReportsSummary } from 'features/timeAndActivityReports/timeAndActivityReportsTypes';
import styles from './SummaryBanner.module.scss';

interface SummaryBannerProps {
  summary: IReportsSummary;
}

const SummaryBanner: FC<SummaryBannerProps> = ({ summary }) => {
  return (
    <div className={styles.wrapper}>
      {summary.map((i) => (
        <div key={i.label}>
          <div className={styles.label}>{i.label}</div>
          <div className={styles.value}>
            {i.label === 'Amount'
              ? numToCurrencyWithLocale(Number(i.value), 'USD', 'en-US')
              : i.value}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SummaryBanner;
