import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { useAppDispatch } from 'app/store';
import { getPoliciesListByMemberId } from 'features/policiesList/policiesListActions';
import { IRequestTimeOffFormValues } from './RequestTimeOffForm';

const FetchPoliciesOptionsComponent: FC = () => {
  const context = useFormikContext<IRequestTimeOffFormValues>();
  const dispatch = useAppDispatch();

  const idForPolicies = Number(context.values.memberForRequest.value);

  React.useEffect(() => {
    if (context.values.memberForRequest.value) {
      dispatch(getPoliciesListByMemberId(idForPolicies));
    }
  }, [context.values.memberForRequest.value]);
  return null;
};

export default FetchPoliciesOptionsComponent;
