import { ISelectOption } from 'components/shared/forms/DropdownSelect/DropdownSelect';
import { IMemberShortInfo } from '../memberInfo/memberInfoTypes';
import { IPolicyShortInfo } from '../policyInfo/policyInfoTypes';
import { MultiValue } from 'react-select';
import { ITimeZone } from '../user/userTypes';

export enum TimeOffRequestStatuses {
  Approved = 'Approved',
  Denied = 'Denied',
  Submitted = 'Submitted',
}

export interface ITimeOffRequestListItem {
  id: number;
  member: IMemberShortInfo;
  policy: IPolicyShortInfo;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  timeRequested: string;
  status: keyof typeof TimeOffRequestStatuses;
  requestedOn: string;
  timeZone: ITimeZone;
}

export interface IRequestsCount {
  approved: number;
  submitted: number;
  denied: number;
  all: number;
}

export interface TimeOffRequestsListInitialState {
  requests: ITimeOffRequestListItem[];
  filter: IFetchTimeOffRequestsParams;
  totalCount: number;
  requestsCount: IRequestsCount;
  isLoading: boolean;
  errorMessage: string | unknown;
}

export interface IRequestingTimeOffData {
  memberId: number;
  policyId: number;
  start: string;
  end: string;
  allDay: boolean;
  timeRequested: string;
  reason: string;
}

export interface IFetchTimeOffRequestsParams {
  path: string;
  members: MultiValue<ISelectOption> | null;
  skip: number;
  take: number;
}

export interface ITimeOffRequestsActionsParams {
  filter: IFetchTimeOffRequestsParams;
  requestId: number;
}
