import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  addNewHoliday,
  deleteHoliday,
  fetchHolidaysList,
} from 'api/holidaysList';
import { AppDispatch } from 'app/store';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import {
  IAddingHolidayData,
  IFetchHolidaysParams,
  IHolidaysListItem,
} from './holidaysListTypes';
import { removeHolidayReducer } from './holidaysListSlice';

export const getHolidaysList = createAsyncThunk<
  { list: IHolidaysListItem[]; count: number },
  IFetchHolidaysParams | void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@holidaysList/getHolidaysList', async (params, thunkApi) => {
  try {
    const res = await fetchHolidaysList(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const getHolidaysListInfiniteScroll = createAsyncThunk<
  { list: IHolidaysListItem[]; count: number },
  IFetchHolidaysParams | void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@holidaysList/getHolidaysListInfiniteScroll', async (params, thunkApi) => {
  try {
    const res = await fetchHolidaysList(params);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const addHoliday = createAsyncThunk<
  void,
  IAddingHolidayData,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@holidaysList/addHoliday', async (data, thunkApi) => {
  try {
    const res = await addNewHoliday(data);
    if (res.status === 200) {
      thunkApi.dispatch(getHolidaysList());
      toast.success('New Holiday Added!');
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const removeHoliday = createAsyncThunk<
  void,
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@holidaysList/removeHoliday', async (holidayId, thunkApi) => {
  try {
    const res = await deleteHoliday(holidayId);
    if (res.status === 204) {
      thunkApi.dispatch(removeHolidayReducer(holidayId));
      toast.success('Holiday Deleted!');
    }
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
