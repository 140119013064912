import React, { FC, ReactNode, useEffect, useState } from 'react';
import { CloseIcon } from 'assets/icons/CloseIcon';
import styles from './Modal.module.scss';
import ModalOverlay from './ModalOverlay';

interface ModalLayoutProps {
  open: boolean;
  title?: string;
  transparentOverlay?: boolean;
  onClose: () => void;
  children: ReactNode;
}

const ModalLayout: FC<ModalLayoutProps> = ({
  open,
  title,
  transparentOverlay,
  onClose,
  children,
}) => {
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (!open) {
      setClosing(true);
    }
  }, [open]);

  return (
    <>
      <ModalOverlay transparentOverlay={transparentOverlay} closing={closing} />
      <div
        className={`${styles.wrapper} ${
          closing ? styles.onClose : styles.onOpen
        }`}
      >
        <div className={styles.controls}>
          {title && <h2 className={styles.title}>{title}</h2>}
          <button
            className={styles.closeBtn}
            onClick={onClose}
            aria-label="Close modal window."
          >
            <CloseIcon />
          </button>
        </div>

        <div className={styles.content}>{children}</div>
      </div>
    </>
  );
};

export default ModalLayout;
