import { createSelector, createSlice } from '@reduxjs/toolkit';
import { UserManager } from 'oidc-client';
import { RootState } from 'app/store';
import { UserSliceInitialState } from './userTypes';
import { isRejectedAction } from '../sliceHelpers';
import {
  getTimeZones,
  getUserInfo,
  handleAuth,
  logout,
  signInRedirect,
} from './userActions';

const initialState: UserSliceInitialState = {
  OIDCUser: null,
  userInfo: null,
  timeZonesList: [],
  isLoading: false,
  isAuthenticated: false,
  errorMessage: '',
};

export const OIDCConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: 'WebAppTemp',
  redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI,
  response_type: 'token',
  scope: 'User',
};

export const userManager = new UserManager(OIDCConfig);

export const userSlice = createSlice({
  name: '@@user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signInRedirect.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signInRedirect.fulfilled, (state, action) => {
      if (action.payload) {
        state.OIDCUser = action.payload;
      }
      state.isLoading = false;
    });
    builder.addCase(handleAuth.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(handleAuth.fulfilled, (state, action) => {
      state.OIDCUser = action.payload;
      state.isAuthenticated = true;
      state.isLoading = false;
    });
    builder.addCase(logout.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.userInfo = null;
    });
    builder.addCase(getUserInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userInfo = action.payload;
    });
    builder.addCase(getTimeZones.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTimeZones.fulfilled, (state, action) => {
      state.isLoading = false;
      state.timeZonesList = action.payload;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const OIDCInfoUserSelector = createSelector(
  [(state: RootState) => state.user.OIDCUser],
  (info) => info
);

export const selectInfoUserSelector = createSelector(
  [(state: RootState) => state.user.userInfo],
  (info) => info
);

export const isAuthenticatedUserSelector = createSelector(
  [(state: RootState) => state.user.isAuthenticated],
  (isAuthenticated) => isAuthenticated
);

export const permissionsUserSelector = createSelector(
  [(state: RootState) => state.user.userInfo?.permissions],
  (permissions) => permissions
);

export const isLoadingUserSelector = createSelector(
  [(state: RootState) => state.user.isLoading],
  (isLoading) => isLoading
);

export const selectTimeZonesListUserSelector = createSelector(
  [(state: RootState) => state.user.timeZonesList],
  (list) => list
);
