import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { isRejectedAction } from '../sliceHelpers';
import { IPoliciesFilterListSliceInitialState } from './policiesFilterListTypes';
import { getPoliciesFilterList } from './policiesFilterListActions';

const initialState: IPoliciesFilterListSliceInitialState = {
  list: [],
  isLoading: false,
  errorMessage: '',
};

export const policiesFilterListSlice = createSlice({
  name: '@@policiesFilterList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPoliciesFilterList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPoliciesFilterList.fulfilled, (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const clientsFilterListSelector = createSelector(
  [(state: RootState) => state.policiesFilterList.list],
  (list) => list
);
