import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import PhoneNumberValidationInput from 'components/shared/forms/PhoneNumberValidationInput';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import Button from 'components/shared/Button';
import {
  MaxLength100Symbols,
  MaxLength50Symbols,
} from 'constants/formsConstants';
import { IAddClientData } from 'features/clientInfo/clientInfoTypes';
import styles from './AddClientForm.module.scss';

interface AddClientFormProps {
  onSubmit: (data: IAddClientData) => void;
  closeAddClientForm: () => void;
}

const AddClientForm: FC<AddClientFormProps> = ({
  onSubmit,
  closeAddClientForm,
}) => {
  const InitialValues = {
    name: '',
    email: '',
    phoneNumber: '',
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .max(
        MaxLength50Symbols,
        `Maximum length ${MaxLength50Symbols} characters`
      )
      .required('Required field'),
    email: Yup.string()
      .email('Invalid email address')
      .max(
        MaxLength100Symbols,
        `*Maximum length ${MaxLength100Symbols} characters`
      )
      .required('Required field'),
  });

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        validationSchema={validationSchema}
        onSubmit={(
          values: typeof InitialValues,
          { setSubmitting, resetForm }
        ) => {
          onSubmit({
            name: values.name,
            email: values.email,
            phoneNumber:
              values.phoneNumber && values.phoneNumber.trim().length > 0
                ? values.phoneNumber
                : null,
          });
          setSubmitting(false);
          resetForm();
          closeAddClientForm();
        }}
      >
        {(formik) => (
          <Form>
            <ValidationTextInput
              label="Client name"
              name="name"
              type="text"
              placeholder="Enter a client name"
            />
            <ValidationTextInput
              label="Email"
              name="email"
              type="text"
              placeholder="Enter a client email"
            />

            <div className={styles.phoneInputWrapper}>
              <PhoneNumberValidationInput
                label="Phone number"
                name="phoneNumber"
              />
            </div>

            <div className={styles.controlWrapper}>
              <Button
                onClick={() => {
                  formik.resetForm();
                  closeAddClientForm();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting}
                preloader={formik.isSubmitting}
              >
                Add
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddClientForm;
