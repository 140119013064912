import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import Button from 'components/shared/Button';
import { MaxLength50Symbols } from 'constants/formsConstants';
import styles from './EditAboutForm.module.scss';

interface EditAboutFormProps {
  memberId: number;
  currentJobTitle: string;
  onSubmit: (memberId: number, jobTitle: string) => void;
}

const EditAboutForm: FC<EditAboutFormProps> = ({
  memberId,
  currentJobTitle,
  onSubmit,
}) => {
  const InitialValues = {
    jobTitle: currentJobTitle ? currentJobTitle : '',
  };

  const validationSchema = Yup.object({
    jobTitle: Yup.string().max(
      50,
      `Maximum length ${MaxLength50Symbols} characters`
    ),
  });

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(memberId, values.jobTitle);
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <ValidationTextInput
              label="Job title"
              name="jobTitle"
              type="text"
              placeholder="Enter a job title"
            />

            <div className={styles.submitWrapper}>
              <Button
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting}
                preloader={formik.isSubmitting}
              >
                Save changes
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditAboutForm;
