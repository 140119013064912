import client from './axiosInstance';
import { IClientInfo } from 'features/clientInfo/clientInfoTypes';

export const fetchOrganizationClientInfo = async (clientId: string) => {
  return await client.get(`clients/${clientId}`);
};

export const updateClientInfo = async (data: IClientInfo) => {
  return await client.put(`clients/${data.id}`, { ...data });
};
