import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import styles from './Content.module.scss';

const Content: FC = () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Outlet />
      </div>
    </div>
  );
};

export default React.memo(Content);
