import React, { FC } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  IAmountsOwedReportsTableColumnHeading,
  IDetailedMemberReport,
} from 'features/amountsOwedReports/amountsOwedReportsTypes';
import { truncateString } from 'helpers/truncateString';

interface TableRowItemProps {
  item: IDetailedMemberReport;
  columns: IAmountsOwedReportsTableColumnHeading[];
}

export const MemberDetailedRowItem: FC<TableRowItemProps> = ({
  item,
  columns,
}) => {
  return (
    <>
      <tr style={{ borderBottom: '1px solid #E7E7E7' }}>
        {columns.map((col) => {
          if (col.value === 'notes') {
            return (
              <>
                <td
                  id="notes"
                  key={col.value}
                  data-tooltip-content={item[col.value]}
                >
                  {item[col.value].length > 0
                    ? truncateString(item[col.value], 20)
                    : ''}
                </td>
                <ReactTooltip
                  anchorId="notes"
                  place="top"
                  style={{ maxWidth: '300px' }}
                />
              </>
            );
          }
          return (
            <td key={col.value}>
              {col.value === 'activity' || col.value === 'totalHours' ? (
                item[col.value]
              ) : (
                <></>
              )}
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default MemberDetailedRowItem;
