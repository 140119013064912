import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/store';
import { fetchClientsFilterList } from 'api/clientsFilterList';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import { IClientShortInfo } from '../clientInfo/clientInfoTypes';

export const getClientsFilterList = createAsyncThunk<
  { list: IClientShortInfo[]; count: number },
  void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@clientsFilterList/getClientsFilterList', async (_, thunkApi) => {
  try {
    const res = await fetchClientsFilterList();
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
