import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { OrganizationSliceInitialState } from './organizationTypes';
import { getOrganizationInfo } from './organizationActions';
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from '../sliceHelpers';

const initialState: OrganizationSliceInitialState = {
  organizationInfo: {
    id: 1,
    name: '',
    timeZone: { id: '', name: '', greenwichMeanTime: '' },
  },
  isLoading: false,
};

export const organizationSlice = createSlice({
  name: '@@organization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganizationInfo.fulfilled, (state, action) => {
      state.organizationInfo = action.payload;
    });
    builder.addMatcher(isPendingAction, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(isFulfilledAction, (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state) => {
      state.isLoading = false;
    });
  },
});

export const organizationInfoSelector = createSelector(
  [(state: RootState) => state.organization.organizationInfo],
  (info) => info
);
