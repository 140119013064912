import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const SENTRY_DSN =
  'https://6b2fb4b9b24a4fb4993b8b3e412b91c5@o4505291295686656.ingest.sentry.io/4505312691093504';
Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  denyUrls: [String(process.env.REACT_APP_BACKEND_API)],
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  enabled: String(process.env.REACT_APP_SENTRY_ENABLED) === 'true',
});
