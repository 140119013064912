import React, { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './SidebarLink.module.scss';

interface SidebarLinkProps {
  path: string;
  title: string;
  icon: ReactNode;
}

const SidebarLink: FC<SidebarLinkProps> = ({ path, title, icon }) => {
  return (
    <li className={styles.navItem}>
      <NavLink
        to={path}
        end
        aria-label={`${title} page link`}
        className={({ isActive }) =>
          isActive
            ? `${styles.navLink} ${styles.activeClassName}`
            : styles.navLink
        }
      >
        {icon}
        <span>{title}</span>
      </NavLink>
    </li>
  );
};

export default SidebarLink;
