import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { User } from 'oidc-client';
import { fetchTimeZonesList, fetchUserData, updateUserData } from 'api/user';
import { AppDispatch } from 'app/store';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import { ITimeZone, IUpdateUserInfo, IUser } from './userTypes';
import { userManager } from './userSlice';
import { getOrganizationInfo } from '../organization/organizationActions';

export const signInRedirect = createAsyncThunk<
  void | User,
  void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@user/signInRedirect', async (_, thunkApi) => {
  try {
    const user = await userManager.getUser();
    if (!user || user.expired) {
      await userManager.signinRedirect();
    } else {
      return user as User;
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const handleAuth = createAsyncThunk<
  User,
  void,
  {
    rejectValue: string | unknown;
  }
>('@@user/handleAuth', async (_, thunkApi) => {
  try {
    const data = await userManager.signinRedirectCallback();
    return data as User;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const logout = createAsyncThunk<
  void,
  void,
  {
    rejectValue: string | unknown;
  }
>('@@user/logout', async (_, thunkApi) => {
  try {
    const clientId = 'WebAppTemp';
    const urlParams = new URLSearchParams(window.location.search);
    let completed = urlParams.get('completed');
    completed = completed ? `${completed},${clientId}` : clientId;
    await userManager.signoutRedirect({
      post_logout_redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI,
      extraQueryParams: {
        completed: completed,
      },
    });
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const getUserInfo = createAsyncThunk<
  IUser,
  void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@user/getUserInfo', async (_, thunkApi) => {
  try {
    const res = await fetchUserData();
    thunkApi.dispatch(getOrganizationInfo(res.data.organizationId));
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const updateUserInfo = createAsyncThunk<
  void,
  IUpdateUserInfo,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@user/updateUserInfo', async (data, thunkApi) => {
  try {
    const res = await updateUserData(data);
    if (res.status === 200) {
      toast.success('Account info successfully updated.');
      thunkApi.dispatch(getUserInfo());
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const getTimeZones = createAsyncThunk<
  ITimeZone[],
  void,
  {
    rejectValue: string | unknown;
  }
>('@@user/getTimeZones', async (_, thunkApi) => {
  try {
    const res = await fetchTimeZonesList();
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
