import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import Preloader from 'components/shared/Preloader';
import { getUserInfo, handleAuth } from 'features/user/userActions';
import {
  isLoadingUserSelector,
  OIDCInfoUserSelector,
  selectInfoUserSelector,
} from 'features/user/userSlice';

const OIDCSignInCallback = () => {
  const oidcUserInfo = useSelector(OIDCInfoUserSelector);
  const userInfo = useSelector(selectInfoUserSelector);
  const isLoading = useSelector(isLoadingUserSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(handleAuth());
  }, []);

  useEffect(() => {
    if (oidcUserInfo) {
      dispatch(getUserInfo());
    }
  }, [oidcUserInfo]);

  return (
    <>
      {isLoading && <Preloader />}
      {userInfo && <Navigate to={`/auth`} />}
    </>
  );
};

export default OIDCSignInCallback;
